import React, { Component } from 'react';
import { MoreHorizontal, Plus, Shield, Trash2, Upload, User, UserPlus } from 'react-feather';
import { Typeahead } from 'react-bootstrap-typeahead';
import { readString } from 'react-papaparse';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import $ from 'jquery';

import UserAPI from '../../../api/User';

import AccessController from '../../../component/AccessController';
import Loading from '../../../component/Loading';
import { RootContext } from '../../../component/Context';

import Template from '../../../asset/other/Import-Template.csv';

const DeepClone = require('rfdc')();
toast.configure();

class ApplicationUser extends Component {
    static contextType = RootContext;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isNewUser: false,
            isNext: false,
            isCheckAll: false,
            isDropzone: false,

            data: [],
            user: [],
            importUser: [], 

            /* Modal Data */
            modal: {
                id: '',
                userId: [],
                roles: '',
                class: []
            },
            modalUser: {
                name: "",
                email: "",
                username: "",
                phone: "",
                nip: ""
            }
        }

        this.step = this.step.bind(this);
        this.fetchNow = this.fetchNow.bind(this);
        
        this.handleUserChoice = this.handleUserChoice.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);

        this.modalAdd = this.modalAdd.bind(this);
        this.add = this.add.bind(this);

        this.modalUpdate = this.modalUpdate.bind(this);
        this.update = this.update.bind(this);

        this.modalSwitch = this.modalSwitch.bind(this);
        this.switchRole = this.switchRole.bind(this);

        this.remove = this.remove.bind(this);

        this.checkboxStatus = this.checkboxStatus.bind(this);
        this.handleCheckAll = this.handleCheckAll.bind(this);

        this.modalCSV = this.modalCSV.bind(this);
        this.parseUser = this.parseUser.bind(this);
        this.import = this.import.bind(this);
    }

    /* === Core Process ========================================================= */

    compare(a, b) {
        if(a.name < b.name){
            return -1;
        }else if(a.name > b.name){
            return 1;
        }else{
            return 0;
        }
    }

    step(event) {
        this.setState({
            isNext: event.currentTarget.getAttribute('data-value') === 'next' ? true : false
        })
    }

    fetchNow() {
        this.setState({
            isLoading: false
        }, () => {
            UserAPI.getByApps(this.props.data._id).then((result) => {
                UserAPI.get().then((result2) => {
                    /* Assign Variable */
                    let data = [], user = [];

                    if(result.status === 200 && result2.status === 200) {
                        /* Store Temporary */
                        let temp = DeepClone(result.data);
                        
                        /* Store User Application */
                        data = result.data;

                        /* Store All User */
                        loop: for(let value of result2.data) {
                            for(let i=0 ; i<temp.length ; i++) {
                                if(value._id === temp[i]._id) {
                                    temp.splice(i, 1);
                                    continue loop;
                                }
                            }

                            if(value.name) {
                                user.push(value);
                            }
                        }

                        /* Sort User */
                        user.sort(this.compare);
                    }

                    /* Remove Existing T\Datatable */
                    if($.fn.dataTable.isDataTable('#user-table')){
                        $('#user-table').DataTable().destroy();
                    }
    
                    /* Update */
                    this.setState({
                        isLoading: false,
                        data, user
                    }, () => {
                        /* Initialize New Datatable */
                        var table = $('#user-table').DataTable({
                            columnDefs: [ {
                                searchable: false,
                                orderable: false,
                                targets: 0
                            } ],
                            order: [[ 1, 'asc' ]],
                            language: {
                                lengthMenu: "Lihat _MENU_ data",
                                zeroRecords: "Tidak ada data yang tersedia",
                                info: "Data _START_ - _END_ dari total _TOTAL_ data",
                                infoEmpty: "Menampilkan 0 data",
                                search: "Cari",
                                paginate: {
                                    previous: "Sebelumnya",
                                    next: "Selanjutnya"
                                }
                            }
                        });
    
                        table.on('order.dt search.dt', function () {
                            table.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
                                cell.innerHTML = i+1;
                            });
                        }).draw();
                    })

                })
            })
        })
    }

    /* === User Input =========================================================== */

    handleUserChoice(selection) {
        let modal = this.state.modal;
        let user = this.state.modalUser;
        let isNewUser = false;

        if(selection.length > 0) {
            if(selection[0].customOption){
                user.name = selection[0].name;
                user.email = '';
                user.username = '';
                user.phone = '';
                user.nip = '';

                isNewUser = true;
            }else{
                user.name = '';
                user.email = '';
                user.username = '';
                user.phone = '';
                user.nip = '';
            }
        }

        modal.userId = selection;

        this.setState({
            modalUser: user,
            modal, isNewUser
        })
    }

    handleChange(event) {
        let target = event.target.getAttribute('data-type');
        let data = this.state[target];

        data[event.target.name] = event.target.value;

        this.setState({
            [target]: data
        })
    }

    handleCheckbox(event) {
        let index = event.currentTarget.getAttribute('index'), name = event.currentTarget.getAttribute('name');
        let modal = this.state.modal;

        if(modal.class.length > 0) {
            modal.class[index].menu[name] = modal.class[index].menu[name] === true ? false : true;
            this.checkboxStatus();          
        }

        this.setState({
            modal
        })
    }

    /* === User Input =========================================================== */

    modalAdd() {
        let temp = [];

        for(let value of DeepClone(this.props.data.class)) {
            temp.push({
                _id: value._id,
                name: value.name,
                menu: {
                    create: false,
                    read: false,
                    update: false,
                    delete: false
                }
            });
        }

        this.setState({
            modal: {
                id: '',
                userId: [],
                roles: '',
                class: temp
            },
            modalUser: {
                name: "",
                email: "",
                username: "",
                phone: "",
                nip: "",
                opdId: ""
            },
            isNewUser: false,
            isNext: false,
            isCheckAll: false
        }, () => {
            this.checkboxStatus();
            $('#modal-add').modal('show');
        })
    }

    add() {
        this.setState({
            isLoading: true
        }, () => {
            /* Assign Variable */
            let modal = this.state.modal, modalUser = this.state.modalUser;
            let featureClass = [];

            /* Add Feature that Checked */
            for(let value of modal.class) {
                if(value.menu.read === true || value.menu.create === true || value.menu.update === true || value.menu.delete === true) {
                    featureClass.push({
                        menu: value._id,
                        acl: value.menu
                    })
                }
            }

            if(this.state.isNewUser)
            {
                /* Store Request Body */
                let result = {
                    name: modalUser.name,
                    email: modalUser.email,
                    username: modalUser.username,
                    nip: modalUser.nip,
                    phone: modalUser.phone,
                    password: 'password',
                    // appdata: {
                    //     appId: this.props.data._id,
                    //     roles: [modal.roles],
                    //     class: featureClass
                    // }
                }

                /* Request Create User with Apps */
                UserAPI.create(result).then((data) => {
                    if(data.status === 200) {
                        // 
                        let results = {
                            userId: data.id,
                            appId: this.props.data._id,
                            roles: [modal.roles],
                            class: featureClass
                        }
                        /* Store Request Body After Request Create User with Apps */
                        UserAPI.addApps(results).then((data) => {
                            if(data.status === 200) {
                                /* Get Data */
                                setTimeout(() => {
                                    this.fetchNow()
                                    $('#modal-add').modal('hide');
                                    toast.success("Pengguna Baru Pada Aplikasi Berhasil ditambahkan", {
                                        position: toast.POSITION.TOP_CENTER,
                                    });
                                }, 2000);
                            }else{
                                /* Handle Error */
                                this.setState({
                                    isLoading: false
                                }, () => {
                                    toast.error("Penambahan Pengguna Gagal, Silahkan Coba Beberapa Saat Lagi", {
                                        position: toast.POSITION.TOP_CENTER
                                    });
        
                                    $('#modal-add').modal('hide');
                                })
                            }
                        })
                    }else{
                        /* Handle Error */
                        this.setState({
                            isLoading: false
                        }, () => {
                            toast.error("Penambahan Pengguna Gagal, Silahkan Coba Beberapa Saat Lagi (Hint: Username/Email/NIP sudah terpakai)", {
                                position: toast.POSITION.TOP_CENTER
                            });

                            $('#modal-add').modal('hide');
                        })
                    }
                })
            }
            else
            {
                /* Store Request Body */
                let result = {
                    userId: modal.userId[0]._id,
                    appId: this.props.data._id,
                    roles: [modal.roles],
                    class: featureClass
                }

                UserAPI.addApps(result).then((data) => {
                    if(data.status === 200) {
                        /* Get Data */
                        setTimeout(() => {
                            this.fetchNow()
                            $('#modal-add').modal('hide');
                            toast.success("Pengguna Baru Pada Aplikasi Berhasil ditambahkan", {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }, 2000);
                    }else{
                        /* Handle Error */
                        this.setState({
                            isLoading: false
                        }, () => {
                            toast.error("Penambahan Pengguna Gagal, Silahkan Coba Beberapa Saat Lagi", {
                                position: toast.POSITION.TOP_CENTER
                            });

                            $('#modal-add').modal('hide');
                        })
                    }
                })
            }
        })
    }

    /* === Update ACL =========================================================== */

    modalUpdate(data) {
        /* Store Variable and Data */
        let temp = DeepClone(data.class);
        let modal = {
            id: data.userAppId,
            userId: [],
            roles: data.roles[0]._id,
            class: []
        };

        /* Loop to Add Feature List */
        loop: for(let value of this.props.data.class) {
            for(let i=0 ; i<temp.length ; i++) {
                if(value._id === temp[i].menu._id) {
                    /* Add if User Have Access */
                    modal.class.push({
                        _id: value._id,
                        name: value.name,
                        menu: {
                            create: temp[i].acl.create,
                            read: temp[i].acl.read,
                            update: temp[i].acl.update,
                            delete: temp[i].acl.delete
                        }
                    });
                    
                    continue loop;
                }
            }

            /* Add if User Doesn't Have Access */
            modal.class.push({
                _id: value._id,
                name: value.name,
                menu: {
                    create: false,
                    read: false,
                    update: false,
                    delete: false
                }
            });
        }

        /* Update */
        this.setState({
            isCheckAll: false,
            modal
        }, () => { this.checkboxStatus(); $('#modal-update').modal('show'); })
    }

    update() {
        this.setState({
            isLoading: true
        }, () => {
            /* Store Request Body */
            let result = {
                class: []
            }

            /* Add Feature that Checked */
            for(let value of this.state.modal.class) {
                if(value.menu.read === true || value.menu.create === true || value.menu.update === true || value.menu.delete === true) {
                    result.class.push({
                        menu: value._id,
                        acl: value.menu
                    })
                }
            }

            /* Request to Update Class */
            UserAPI.updateClass(result, this.state.modal.id).then((data) => {
                if(data.status === 200) {
                    /* Update if Success */
                    this.fetchNow();
                    $('#modal-update').modal('hide');
                    toast.success("Update Pengguna Berhasil", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }else{
                    /* Handle Error */
                    this.setState({
                        isLoading: false
                    }, () => {
                        toast.error("Ubah Hak Akses Gagal, Silahkan Coba Beberapa Saat Lagi", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        $('#modal-update').modal('hide');
                    })
                }
            })
        })
    }

    /* === Switch Role ========================================================== */

    modalSwitch(data) {
        this.setState({
            modal: {
                id: data.userAppId,
                userId: [],
                roles: data.roles[0] ? data.roles[0]._id : '',
                class: []
            },
            modalUser: {
                name: data._id,
                email: "",
                username: "",
                phone: "",
                nip: "",
                opdId: ""
            }
        }, () => {
            $('#modal-switch').modal('show');
        })
    }

    switchRole() {
        this.setState({
            isLoading: true
        }, () => {
            /* Store Data */
            let roles = this.state.modal.roles;

            /* Store Request Body */
            let result = {
                roles: [
                    roles
                ]
            }

            /* Request to Update Role */
            UserAPI.updateRole(result, this.state.modal.id).then((data) => {
                if(data.status === 200) {
                    /* Update Data */
                    this.fetchNow()
                    $('#modal-switch').modal('hide');
                    toast.success("Update Pengguna Berhasil", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }else{
                    /* Handle Error */
                    this.setState({
                        isLoading: false
                    }, () => {
                        toast.error("Ubah Role Gagal, Silahkan Coba Beberapa Saat Lagi", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        $('#modal-switch').modal('hide');
                    })
                }
            })
        })
    }

    /* === Remove User from Apps ================================================ */
    
    remove(event) {
        let id = event.currentTarget.getAttribute('data-userapp');
        
        event.stopPropagation();
        if(window.confirm('Are sure want to delete?')) {
            this.setState({
                isLoading: true
            }, () => {
                /* Request to Remove Admin Access */
                UserAPI.removeApps(id).then((data) => {
                    if(data.status === 200) {
                        this.fetchNow();
                        toast.warn("Hapus Pengguna Berhasil", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }else{
                        this.setState({
                            isLoading: false
                        }, () => {
                            toast.error("Hapus Pengguna Gagal, Silahkan Coba Beberapa Saat Lagi", {
                                position: toast.POSITION.TOP_CENTER
                            });
                        })
                    }
                })
            })
        }
    }

    /* === Check All Class ====================================================== */

    checkboxStatus() {
        /* Assign Variable */
        let status = false, amount = 0;

        /* Loop to Get Status */
        for(let value of this.state.modal.class) {
            for(let value2 of Object.keys(value.menu)) {
                if(value.menu[value2] === true) {
                    status = true;
                    amount++;
                }
            }
        }

        /* Process Depend on Checkbox Status */
        if(status) {
            if(amount === (this.state.modal.class.length * 4)) {
                /* Handle Checked All */
                document.getElementById('check-all').indeterminate = false;
                this.setState({
                    isCheckAll: true
                })
            }else{
                /* Handle Indeterminate */
                document.getElementById('check-all').indeterminate = true;
                this.setState({
                    isCheckAll: false
                })
            }
        }else{
            /* Handle Not Checked */
            document.getElementById('check-all').indeterminate = false;
            this.setState({
                isCheckAll: false
            })
        }
    }

    handleCheckAll() {
        let modal = this.state.modal;

        let result = this.state.isCheckAll ? false : true;

        /* Loop to Get Status */
        for(let value of modal.class) {
            for(let menu of Object.keys(value.menu)) {
                value.menu[menu] = result;
            }
        }

        this.setState({
            isCheckAll: result,
            modal
        }, () => {
            this.checkboxStatus();
        })
    }

    /* === Import User from CSV ================================================= */

    modalCSV() {
        this.setState({
            importUser: [], 
            isDropzone: true
        }, () => { $('#modal-csv').modal('show') })
    }

    parseUser(file) {
        this.setState({
            isLoading: true
        }, () => {
            /* Assign File Reader */
            let reader = new FileReader();

            /* Read Uploaded File */
            reader.readAsText(file[0]);

            /* Parse File */
            reader.onload = () => {
                /* Assign Variable and Store Data */
                let data = (readString(reader.result, {header: true, skipEmptyLines: true})).data;
                let isError = false, importUser = []; 

                /* Process if Data Not Empty */
                if(data.length > 0) {
                    /* Process if CSV Format is Correct */
                    if(data[0].hasOwnProperty('username') && data[0].hasOwnProperty('roles')) {
                        /* Loop All Data */
                        for(let value of data) {
                            /* Add if Username is Not Empty */
                            if(value.username !== null && value.username !== '') {
                                /* Store Value */
                                let user = {
                                    nip: value.nip,
                                    name: value.name,
                                    username: value.username,
                                    email: value.email,
                                    phone: value.phone,
                                    password: 'password',
                                    appdata: {
                                        appId: this.props.data._id,
                                        roles: [],
                                        class: []
                                    }
                                } 

                                /* Get Role Data */
                                for(let role of this.props.data.roles) {
                                    if(value.roles === role.name) {
                                        user.appdata.roles.push(role._id);
                                        break;
                                    }
                                }

                                /* Add to Import List */
                                importUser.push(user); 
                            }
                        }
                    }else{
                        /* CSV Format Wrong */
                        isError = true; 
                    }
                }

                /* Update */
                this.setState({
                    isLoading: false,
                    importUser
                }, () => {
                    if(importUser.length <= 0) {
                        if(isError) {
                            /* Wrong Format Alert */
                            toast.error(`CSV yang Diunggah Tidak Sesuai Format`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000
                            });
                        }else{
                            /* Empty Data Alert */
                            toast.error(`Tidak Ada Data yang Valid dalam CSV`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000
                            });
                        }
                    }
                })
            }
        })
    }

    import() {
        this.setState({
            isLoading: true
        }, () => {
            for (let i = 0; i < this.state.importUser.length; i++) {
                let user = this.state.importUser[i];

                let data = {
                    nip: user.nip,
                    name: user.name,
                    email: user.email,
                    username: user.username,
                    phone: user.phone,
                    password: 'password'
                }

                UserAPI.create(data).then((result) => {
                    if(result.status === 200) { 
    
                        let results = {
                            userId: result.id,
                            appId: this.props.data._id,
                            roles: [user.appdata.roles],
                            class: []
                        }

                        /* Store Request Body After Request Create User with Apps */
                        UserAPI.addApps(results).then((data) => {
                            if(data.status === 200) {
                                /* Handle Success */
                                this.fetchNow();
    
                                $('#modal-csv').modal('hide');
    
                                if(this.state.importUser.length > 4) {
                                    toast.success("Import Pengguna sedang Dilakukan, Silahkan Tunggu Beberapa Saat dan Muat Ulang Halaman ini", {
                                        position: toast.POSITION.TOP_CENTER,
                                        theme: 'colored',
                                        autoClose: false
                                    });
                                }else{
                                    toast.success("Import Pengguna Berhasil", {
                                        position: toast.POSITION.TOP_CENTER,
                                        theme: 'colored'
                                    });
                                }
                            }else{
                                /* Handle Error */
                                this.setState({
                                    isLoading: false
                                }, () => {
                                    $('#modal-csv').modal('hide');
    
                                    toast.error("Import Pengguna Gagal, Silahkan Coba Beberapa Saat Lagi", {
                                        position: toast.POSITION.TOP_CENTER,
                                        theme: 'colored'
                                    });
    
                                })
                            }
                        })
                        
                    }else{
                        /* Handle Error */
                        this.setState({
                            isLoading: false
                        }, () => {
                            $('#modal-csv').modal('hide');
    
                            toast.error("Import Pengguna Gagal, Silahkan Coba Beberapa Saat Lagi", {
                                position: toast.POSITION.TOP_CENTER,
                                theme: 'colored'
                            });
    
                        })
                    }
                })

            }
            
        })
    }

    componentDidMount() {
        this.fetchNow();

        /* Handler When Modal Hidden */
        $('#modal-csv').on('hide.bs.modal', () => {
            this.setState({
                isDropzone: false
            })
        })
    }

    checkAll() {
        let disabled = false;

        if (this.state.modal.roles === "") {
            disabled = true;
        } 

        return disabled;
    }

    render() { 
        return (
            <React.Fragment>
                <Loading show={this.state.isLoading} />

                <div className="row m-0 mb-5">
                    <div className="col-12">
                        <div className="card card-header-actions">
                            <div className="card-header">
                                Daftar Pengguna
                                <AccessController name="Aplikasi" access="update">
                                    <div className="dropdown">
                                        <button className="btn btn-sm btn-success py-2 px-3 dropdown-toggle" data-toggle="dropdown"><UserPlus size={18} className="mr-2"/><span className="mb-n1 mr-1">Tambah</span></button>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <button onClick={this.modalAdd} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><Plus className="mt-n1"/></span>Tambah Baru</button>
                                            <button onClick={this.modalCSV} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><Upload /></span>Import CSV</button>
                                        </div>
                                    </div>
                                </AccessController>
                            </div>
                            <div className="card-body">
                                <div className="datatable">
                                    <table className="table table-striped table-hover" id="user-table">
                                        <thead>
                                            <tr>
                                                <th width="5%" align="center"></th>
                                                <th>Nama</th>
                                                <th>Username</th>
                                                <th>Role</th>
                                                <th>Akses</th>
                                                <AccessController name="Aplikasi" access="update">
                                                    <th>Aksi</th>
                                                </AccessController>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.data.map((value, index) => {
                                                    return (
                                                        <tr key={index} className="issp-table">
                                                            <th></th>
                                                            <td>{value.name}</td>
                                                            <td>{value.username}</td>
                                                            <td>{(value.roles.length > 0) ? value.roles[0].name : '-'}</td>
                                                            <td>{value.class.length + ' Hak Akses'}</td>
                                                            <AccessController name="Aplikasi" access="update">
                                                                <td className="d-flex">
                                                                    <div className="dropdown">
                                                                        <button className="btn btn-table rounded-circle" data-toggle="dropdown">
                                                                            <MoreHorizontal />
                                                                        </button>
                                                                        <div className="dropdown-menu dropdown-menu-right">
                                                                            <button onClick={this.modalUpdate.bind(this, value)} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><Shield className="mt-n1" /></span>Ubah Hak Akses</button>
                                                                            <button onClick={this.modalSwitch.bind(this, value)} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><User className="mt-n1"/></span>Ubah Role</button>
                                                                            <button onClick={this.remove} data-userapp={value.userAppId} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><Trash2 className="mt-n1" /></span>Hapus Pengguna</button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </AccessController>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="modal-add" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Tambah Pengguna</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {
                                    this.state.isNext === false ? (
                                        <React.Fragment>
                                            <div className="form-group">
                                                <label className="text-dark mb-1">Nama Pengguna</label>
                                                <Typeahead id="user-typeahead" newSelectionPrefix="Klik untuk menambahkan :" placeholder="Pilih pengguna atau tambah baru" options={this.state.user} selected={this.state.modal.userId} onChange={this.handleUserChoice} labelKey="name" allowNew />
                                            </div>
                                            {
                                                this.state.isNewUser && (
                                                    <React.Fragment>
                                                        <div className="form-group">
                                                            <label className="text-dark mb-1">Username</label>
                                                            <input onChange={this.handleChange} value={this.state.modalUser.username} name="username" data-type="modalUser" className="form-control py-3" type="text" placeholder="Masukkan username" />
                                                        </div>
                                                        {/* <div className="form-group">
                                                            <label className="text-dark mb-1">NIP</label>
                                                            <input onChange={this.handleChange} value={this.state.modalUser.nip} name="nip" data-type="modalUser" className="form-control py-3" type="number" placeholder="Masukkan NIP" />
                                                        </div> */}
                                                        <div className="form-group">
                                                            <label className="text-dark mb-1">Email</label>
                                                            <input onChange={this.handleChange} value={this.state.modalUser.email} name="email" data-type="modalUser" className="form-control py-3" type="email" placeholder="Masukkan email" />
                                                        </div>
                                                        {/* <div className="form-group">
                                                            <label className="text-dark mb-1">Telepon</label>
                                                            <input onChange={this.handleChange} value={this.state.modalUser.phone} name="phone" data-type="modalUser" className="form-control py-3" type="tel" placeholder="628xxxx" />
                                                        </div> */}
                                                    </React.Fragment>
                                                )
                                            }
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <div className="form-group">
                                                <label className="text-dark mb-1">Role</label>
                                                <select onChange={this.handleChange} value={this.state.modal.roles} name="roles" data-type="modal" className="form-control">
                                                    <option value="" disabled>-- Pilih Role --</option>
                                                    {
                                                        this.props.data.roles.map((value, index) => {
                                                            return (
                                                                <option value={value._id} key={index}>{value.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-4 pl-0 mb-3 text-dark font-weight-bold">
                                                    Fitur
                                                </div>
                                                <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Read</div>
                                                <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Create</div>
                                                <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Update</div>
                                                <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Delete</div>
                                                {
                                                    this.state.modal.class.map((value, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div className="col-4 mb-3 pl-0 d-flex align-items-center">
                                                                    {value.name}
                                                                </div>
                                                                <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                                    <input onChange={this.handleCheckbox} checked={value.menu.read} index={index} name="read" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                                </div>
                                                                <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                                    <input onChange={this.handleCheckbox} checked={value.menu.create} index={index} name="create" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                                </div>
                                                                <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                                    <input onChange={this.handleCheckbox} checked={value.menu.update} index={index} name="update" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                                </div>
                                                                <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                                    <input onChange={this.handleCheckbox} checked={value.menu.delete} index={index} name="delete" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                                <div className="col-12 bg-light py-2 d-flex align-items-center justify-content-center rounded">
                                                    <div className="d-flex align-items-center">
                                                        <input onChange={this.handleCheckAll} checked={this.state.isCheckAll} id="check-all" className="form-check-input form-check-child m-0 mr-2" type="checkbox"/>
                                                        <span>Pilih Semua Akses</span>
                                                    </div>
                                                </div> 
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                            </div>
                            {
                                this.state.isNext === false ? (
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-success" data-dismiss="modal">Batal</button>
                                        <button disabled={this.state.modal.userId.length <= 0} type="button" className="btn btn-success" onClick={this.step} data-value="next">Selanjutnya</button>
                                    </div>
                                ) : (
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-success" onClick={this.step} data-value="prev">Sebelumnya</button>
                                        <button type="button" className="btn btn-success" onClick={this.add} disabled={this.checkAll()}>Tambah</button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className="modal" id="modal-update" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Ubah Hak Akses</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row m-0">
                                    <div className="col-4 pl-0 mb-3 text-dark font-weight-bold">
                                        Fitur
                                    </div>
                                    <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Read</div>
                                    <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Create</div>
                                    <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Update</div>
                                    <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Delete</div>
                                    {
                                        this.state.modal.class.map((value, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="col-4 mb-3 pl-0 d-flex align-items-center">
                                                        {value.name}
                                                    </div>
                                                    <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                        <input onChange={this.handleCheckbox} checked={value.menu.read} index={index} name="read" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                    </div>
                                                    <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                        <input onChange={this.handleCheckbox} checked={value.menu.create} index={index} name="create" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                    </div>
                                                    <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                        <input onChange={this.handleCheckbox} checked={value.menu.update} index={index} name="update" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                    </div>
                                                    <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                        <input onChange={this.handleCheckbox} checked={value.menu.delete} index={index} name="delete" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                    <div className="col-12 bg-light py-2 d-flex align-items-center justify-content-center rounded">
                                        <div className="d-flex align-items-center">
                                            <input onChange={this.handleCheckAll} checked={this.state.isCheckAll} id="check-all" className="form-check-input form-check-child m-0 mr-2" type="checkbox"/>
                                            <span>Pilih Semua Akses</span>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-orange" data-dismiss="modal">Batal</button>
                                <button type="button" className="btn btn-orange" onClick={this.update}>Simpan</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="modal-switch" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Ubah Role</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label className="text-dark mb-1">Role</label>
                                    <select onChange={this.handleChange} value={this.state.modal.roles} name="roles" data-type="modal" className="form-control">
                                        <option value="" disabled>-- Pilih Role --</option>
                                        {
                                            this.props.data.roles.map((value, index) => {
                                                return (
                                                    <option value={value._id} key={index}>{value.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-orange" data-dismiss="modal">Batal</button>
                                <button type="button" className="btn btn-orange" onClick={this.switchRole}>Simpan</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="modal-csv" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Import Pengguna</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {
                                this.state.isDropzone ? (
                                    this.state.importUser.length > 0 ? (
                                        <div className="modal-body">
                                            Ditemukan <strong>{this.state.importUser.length} data valid</strong> dari CSV yang diunggah. Lakukan import pengguna?
                                        </div>
                                    ) : (
                                        <div className="modal-body">
                                            <div className="alert alert-warning" role="alert">
                                                Untuk dapat melakukan import pengguna, gunakan template CSV yang dapat Anda <a href={Template} download="Import-Template.csv" className="alert-link">unduh disini</a>. Mohon menjadi perhatian bahwa username wajib untuk diisi, serta role yang terisi harus sudah ditambahkan terlebih dahulu ke dalam daftar role.
                                            </div>
                                            <div className="form-group">
                                                <Dropzone onDropAccepted={this.parseUser} accept=".csv">
                                                    {({getRootProps, getInputProps}) => (
                                                        <section className="media-container">
                                                            <div {...getRootProps({className: 'media-dropzone'})}>
                                                                <input {...getInputProps()} />
                                                                <p className="text-center">Taruh atau Klik Disini untuk Mengunggah CSV</p>
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        </div>
                                    )
                                ) : null
                            }
                            {
                                this.state.importUser.length > 0 && (
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-success" data-dismiss="modal">Batal</button>
                                        <button onClick={this.import} type="button" className="btn btn-success">Import</button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default ApplicationUser;