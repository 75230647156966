import React, { Component } from 'react';
import { ArrowLeft, ArrowRight, MoreHorizontal, PlusSquare, Shield, Trash2, User } from 'react-feather';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import $ from 'jquery';

import ApplicationAPI from '../../../api/Application';
import UserAPI from '../../../api/User';

import AccessController from '../../../component/AccessController';
import Loading from '../../../component/Loading';
import NotFound from '../../../component/NotFound';

const DeepClone = require('rfdc')();
toast.configure();

class UserDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isShow: '',
            isCheckAll: false,

            /* Core Variable */
            apps: [],
            roles: [],
            data: {
                _id: '',
                username: '',
                name: '',
                email: '',
                apps: []
            },

            /* Modal Variable */
            modal: {
                name: '',
                email: '',
            },
            modalApps: {
                id: '',
                appId: '',
                userId: '',
                roles: '',
                class: []
            }
        }

        this.fetchNow = this.fetchNow.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeApps = this.handleChangeApps.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);

        this.modalEdit = this.modalEdit.bind(this);
        this.edit = this.edit.bind(this);

        this.modalAdd = this.modalAdd.bind(this);
        this.add = this.add.bind(this);

        this.modalUpdateClass = this.modalUpdateClass.bind(this);
        this.updateClass = this.updateClass.bind(this);

        this.modalUpdateRole = this.modalUpdateRole.bind(this);
        this.updateRole = this.updateRole.bind(this);

        this.remove = this.remove.bind(this);

        this.checkboxStatus = this.checkboxStatus.bind(this);
        this.handleCheckAll = this.handleCheckAll.bind(this);
    }

    /* === Core Process ========================================================= */

    compare(a, b) {
        if(a.name < b.name){
            return -1;
        }else if(a.name > b.name){
            return 1;
        }else{
            return 0;
        }
    }

    fetchNow() {
        this.setState({
            isLoading: true
        }, () => {
            ApplicationAPI.getMin().then((result) => {
                UserAPI.getById(this.props.id).then((result2) => {
                    /* Assign Variable */
                    let apps = [], data = this.state.data, isShow = 'hide';

                    /* Handle Success */
                    if(result.status === 200 && result2.status === 200) {
                        /* Show Component */
                        isShow = 'show';

                        /* Store Data */
                        data = {
                            _id: result2.data._id,
                            username: result2.data.username,
                            name: result2.data.name,
                            email: (result2.data.email) ? result2.data.email : '',
                            phone: (result2.data.phone) ? result2.data.phone : '',
                            nip: (result2.data.nip) ? result2.data.nip : '',
                            apps: result2.data.apps
                        }

                        /* Assign Temporary */
                        let temp = DeepClone(data.apps);

                        /* Store Apps That User doesn't have access */
                        loop: for(let value of result.data) {
                            for(let i=0 ; i<temp.length ; i++) {
                                if(value._id === temp[i].appId._id) {
                                    temp.splice(i, 1);
                                    continue loop;
                                }
                            }

                            if(value.isPublic === false) {
                                apps.push(value);
                            }
                        }

                        /* Sort */
                        apps.sort(this.compare);
                    }

                    /* Remove Existing T\Datatable */
                    if($.fn.dataTable.isDataTable('#application-table')){
                        $('#application-table').DataTable().destroy();
                    }

                    /* Update */
                    this.setState({
                        isLoading: false,
                        apps, data, isShow
                    }, () => {
                        /* Initialize New Datatable */
                        var table = $('#application-table').DataTable({
                            columnDefs: [ {
                                searchable: false,
                                orderable: false,
                                targets: 0
                            } ],
                            order: [[ 1, 'asc' ]],
                            language: {
                                lengthMenu: "Lihat _MENU_ data",
                                zeroRecords: "Tidak ada data yang tersedia",
                                info: "Data _START_ - _END_ dari total _TOTAL_ data",
                                infoEmpty: "Menampilkan 0 data",
                                search: "Cari",
                                paginate: {
                                    previous: "Sebelumnya",
                                    next: "Selanjutnya"
                                }
                            }
                        });
    
                        table.on('order.dt search.dt', function () {
                            table.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
                                cell.innerHTML = i+1;
                            });
                        }).draw();
                    })
                })
            })
        })
    }

    /* === Handle User Input ==================================================== */

    handleChange(event) {
        /* Assign and Store Data */
        let target = event.target.getAttribute('data-type');
        let data = this.state[target];;

        /* Change Value */
        data[event.target.name] = event.target.value;

        /* Update */
        this.setState({
            [target]: data
        })
    }

    handleChangeApps(event) {
        /* Store Variable */
        let data = this.state.modalApps;

        /* Change Value */
        data.appId = event.target.value;
        data.roles = '';
        data.class = [];

        /* If Application Choosed */
        if(data.appId !== '') {
            this.setState({
                isLoading: true
            }, () => {
                /* Request to get Application Detail */
                ApplicationAPI.getById(data.appId).then((result) => {
                    let roles = [];
    
                    /* Handle Success */
                    if(result.status === 200) {
                        roles = result.data.roles;

                        /* Loop to Add Feature List */
                        for(let value of result.data.class) {
                            data.class.push({
                                _id: value._id,
                                name: value.name,
                                menu: {
                                    create: false,
                                    read: false,
                                    update: false,
                                    delete: false
                                }
                            });
                        }
                    }
    
                    /* Update */
                    this.setState({
                        isLoading: false,
                        isCheckAll: false,
                        modalApps: data,
                        roles
                    }, () => { this.checkboxStatus() })
                })
            })
        }else{
            this.setState({
                modalApps: data
            })
        }
    }

    handleCheckbox(event) {
        let index = event.currentTarget.getAttribute('index'), name = event.currentTarget.getAttribute('name');
        let modal = this.state.modalApps;

        if(modal.class.length > 0) {
            modal.class[index].menu[name] = modal.class[index].menu[name] === true ? false : true;
            this.checkboxStatus();          
        }

        this.setState({
            modalApps: modal
        })
    }

    /* === Update User Detail =================================================== */

    modalEdit() {
        this.setState({
            modal: {
                name: this.state.data.name,
                email: this.state.data.email,
            }
        }, () => { $('#modal-edit').modal('show') })
    }

    edit(event) {
        event.preventDefault();
        
        this.setState({
            isLoading: true
        }, () => {
            UserAPI.update(this.state.modal, this.state.data._id).then((data) => {
                if(data.status === 200) {
                    /* Get Data */
                    this.fetchNow();
                    let u = data.data.username;
                    toast.success(`Detail Pengguna ${u} Berhasil Di Update`, {
                        position: toast.POSITION.TOP_CENTER,
                        theme: 'colored'
                    });

                    $('#modal-edit').modal('hide');
                }else{
                    /* Handle Error */
                    this.setState({
                        isLoading: false
                    }, () => {
                        toast.error("Ubah Detail Pengguna Gagal, Silahkan Coba Beberapa Saat Lagi (Hint: Email/NIP sudah terpakai)", {
                            position: toast.POSITION.TOP_CENTER
                        });

                        $('#modal-edit').modal('hide');
                    })
                }
            })
        })
    }

    /* === Add Application ====================================================== */

    modalAdd() {
        this.setState({
            modalApps: {
                id: '',
                appId: '',
                userId: '',
                roles: '',
                class: []
            },
            roles: [],
            isCheckAll: false
        }, () => { this.checkboxStatus(); $('#modal-add').modal('show') })
    }

    add() {
        this.setState({
            isLoading: true
        }, () => {
            let feature = [];

            /* Add Feature that Checked */
            for(let value of this.state.modalApps.class) {
                if(value.menu.read === true || value.menu.create === true || value.menu.update === true || value.menu.delete === true) {
                    feature.push({
                        menu: value._id,
                        acl: value.menu
                    })
                }
            }

            /* Store Request Body */
            let result = {
                userId: this.state.data._id,
                appId: this.state.modalApps.appId,
                roles: [this.state.modalApps.roles],
                class: feature
            }

            /* Request Add */
            UserAPI.addApps(result).then((data) => {
                if(data.status === 200) {
                    /* Get Data */
                    setTimeout(() => {
                        this.fetchNow()
                        $('#modal-add').modal('hide');
                    }, 2000);
                    
                    toast.success(`Berhasil Menambahkan Aplikasi`, {
                        position: toast.POSITION.TOP_CENTER,
                        theme: 'colored'
                    });
                }else{
                    /* Handle Error */
                    this.setState({
                        isLoading: false
                    }, () => {
                        toast.error("Penambahan Aplikasi Gagal, Silahkan Coba Beberapa Saat Lagi", {
                            position: toast.POSITION.TOP_CENTER
                        });

                        $('#modal-add').modal('hide');
                    })
                }
            })
        })
    }

    /* === Update Class/Feature ================================================= */

    modalUpdateClass(data) {
        this.setState({
            isLoading: true
        }, () => {
            /* Request to Get Apps Detail */
            ApplicationAPI.getById(data.appId._id).then((result) => {
                if(result.status === 200) {
                    /* Store Variable and Data */
                    let temp = DeepClone(data.class);
                    let modalApps = {
                        id: data._id,
                        appId: '',
                        userId: '',
                        roles: '',
                        class: []
                    };
    
                    /* Loop to Add Feature List */
                    loop: for(let value of result.data.class) {
                        for(let i=0 ; i<temp.length ; i++) {
                            if(value._id === temp[i].menu._id) {
                                /* Add if User Have Access */
                                modalApps.class.push({
                                    _id: value._id,
                                    name: value.name,
                                    menu: {
                                        create: temp[i].acl.create,
                                        read: temp[i].acl.read,
                                        update: temp[i].acl.update,
                                        delete: temp[i].acl.delete
                                    }
                                });
                                
                                continue loop;
                            }
                        }
            
                        /* Add if User Doesn't Have Access */
                        modalApps.class.push({
                            _id: value._id,
                            name: value.name,
                            menu: {
                                create: false,
                                read: false,
                                update: false,
                                delete: false
                            }
                        });
                    }
                    
                    /* Update */
                    this.setState({
                        isLoading: false,
                        isCheckAll: false,
                        modalApps
                    }, () => { this.checkboxStatus(); $('#modal-update').modal('show'); })
                }else{
                    /* Handle Error */
                    this.setState({
                        isLoading: false
                    }, () => {
                        toast.error("Terjadi Kesalahan, Silahkan Coba Beberapa Saat Lagi", {
                            position: toast.POSITION.TOP_CENTER
                        });
                    })
                }
            })
        })
    }

    updateClass() {
        this.setState({
            isLoading: true
        }, () => {
            /* Store Request Body */
            let result = {
                class: []
            }

            /* Add Feature that Checked */
            for(let value of this.state.modalApps.class) {
                if(value.menu.read === true || value.menu.create === true || value.menu.update === true || value.menu.delete === true) {
                    result.class.push({
                        menu: value._id,
                        acl: value.menu
                    })
                }
            }

            /* Request to Update Class */
            UserAPI.updateClass(result, this.state.modalApps.id).then((data) => {
                if(data.status === 200) {
                    /* Update if Success */
                    this.fetchNow();
                    $('#modal-update').modal('hide');
                    toast.success(`Berhasil Update Hak Akses Pengguna`, {
                        position: toast.POSITION.TOP_CENTER,
                        theme: 'colored'
                    });
                }else{
                    /* Handle Error */
                    this.setState({
                        isLoading: false
                    }, () => {
                        toast.error("Ubah Hak Akses Gagal, Silahkan Coba Beberapa Saat Lagi", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        $('#modal-update').modal('hide');
                    })
                }
            })
        })
    }

    /* === Update Role ========================================================== */
    
    modalUpdateRole(data) {
        this.setState({
            isLoading: true
        }, () => {
            ApplicationAPI.getById(data.appId._id).then((result) => {
                if(result.status === 200) {
                    let modalApps = {
                        id: data._id,
                        appId: '',
                        userId: '',
                        roles: data.roles[0]._id,
                        class: []
                    };
                    
                    /* Update */
                    this.setState({
                        isLoading: false,
                        roles: result.data.roles,
                        modalApps
                    }, () => { $('#modal-role').modal('show'); })
                }else{
                    /* Handle Error */
                    this.setState({
                        isLoading: false
                    }, () => {
                        toast.error("Terjadi Kesalahan, Silahkan Coba Beberapa Saat Lagi", {
                            position: toast.POSITION.TOP_CENTER
                        });
                    })
                }
            })
        })
    }

    updateRole() {
        this.setState({
            isLoading: true
        }, () => {
            /* Store Request Body */
            let result = {
                roles: [
                    this.state.modalApps.roles
                ]
            }

            /* Request to Update Role */
            UserAPI.updateRole(result, this.state.modalApps.id).then((data) => {
                if(data.status === 200) {
                    /* Update Data */
                    this.fetchNow()
                    $('#modal-role').modal('hide');
                    toast.success(`Berhasil Update Role Untuk Pengguna`, {
                        position: toast.POSITION.TOP_CENTER,
                        theme: 'colored'
                    });
                }else{
                    /* Handle Error */
                    this.setState({
                        isLoading: false
                    }, () => {
                        toast.error("Ubah Role Gagal, Silahkan Coba Beberapa Saat Lagi", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        $('#modal-role').modal('hide');
                    })
                }
            })
        })
    }

    /* === Remove Apps from User ================================================ */
    
    remove(event) {
        let id = event.currentTarget.getAttribute('data-userapp');
        
        event.stopPropagation();
        if(window.confirm('Are sure want to delete?')) {
            this.setState({
                isLoading: true
            }, () => {
                /* Request to Remove Admin Access */
                UserAPI.removeApps(id).then((data) => {
                    if(data.status === 200) {
                        this.fetchNow();
                        toast.warn(`Berhasil Menghapus Aplikasi Untuk Pengguna`, {
                            position: toast.POSITION.TOP_CENTER,
                            theme: 'colored'
                        });
                    }else{
                        this.setState({
                            isLoading: false
                        }, () => {
                            toast.error("Hapus Pengguna Gagal, Silahkan Coba Beberapa Saat Lagi", {
                                position: toast.POSITION.TOP_CENTER
                            });
                        })
                    }
                })
            })
        }
    }

    /* === Check All Class ====================================================== */

    checkboxStatus() {
        /* Assign Variable */
        let status = false, amount = 0;

        /* Loop to Get Status */
        for(let value of this.state.modalApps.class) {
            for(let value2 of Object.keys(value.menu)) {
                if(value.menu[value2] === true) {
                    status = true;
                    amount++;
                }
            }
        }

        /* Process Depend on Checkbox Status */
        if(status) {
            if(amount === (this.state.modalApps.class.length * 4)) {
                /* Handle Checked All */
                document.getElementById('check-all').indeterminate = false;
                this.setState({
                    isCheckAll: true
                })
            }else{
                /* Handle Indeterminate */
                document.getElementById('check-all').indeterminate = true;
                this.setState({
                    isCheckAll: false
                })
            }
        }else{
            /* Handle Not Checked */
            document.getElementById('check-all').indeterminate = false;
            this.setState({
                isCheckAll: false
            })
        }
    }

    handleCheckAll() {
        let modal = this.state.modalApps;

        let result = this.state.isCheckAll ? false : true;

        /* Loop to Get Status */
        for(let value of modal.class) {
            for(let menu of Object.keys(value.menu)) {
                value.menu[menu] = result;
            }
        }

        this.setState({
            isCheckAll: result,
            modalApps: modal
        }, () => {
            this.checkboxStatus();
        })
    }

    checkAll() {
        let disabled = false;

        for (let value of Object.keys(this.state.modal)) {
            console.log(value, this.state.modal[value]);
            if (this.state.modal[value] === "") {
            disabled = true;
            }
        }

        return disabled;
    }

    componentDidMount() {
        this.fetchNow();
    }

    render() { 
        /* Handle Normal */
        if(this.state.isShow === 'show')
        {
            return (
                <AccessController name="Pengguna" access="read" type="page">
                    <Loading show={this.state.isLoading} />
                    <Helmet title={this.state.data.name + " - Manajemen SSO"} />
    
                    <div className="row d-flex align-items-center m-0 mt-2 mb-4">
                        <Link to="/pengguna" className="btn btn-sm btn-icon btn-transparent mr-2">
                            <ArrowLeft />
                        </Link>
                        <h1 className="mb-0">{this.state.data.name}</h1>
                    </div>

                    <div className="row m-0 mb-4">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header text-dark">
                                    Detail Pengguna
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>Username</label>
                                            <input value={this.state.data.username} type="text" className="form-control" disabled />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Nama</label>
                                            <input value={this.state.data.name} type="text" className="form-control" disabled />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Email</label>
                                            <input value={this.state.data.email} type="text" className="form-control" disabled />
                                        </div>
                                        {/* <div className="form-group col-md-4">
                                            <label>Telepon</label>
                                            <input value={this.state.data.phone ? this.state.data.phone : '-'} type="text" className="form-control" disabled />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>NIP</label>
                                            <input value={this.state.data.nip ? this.state.data.nip : '-'} type="text" className="form-control" disabled />
                                        </div> */}
                                    </div>
                                </div>
                                <AccessController name="Pengguna" access="update">
                                    <div onClick={this.modalEdit} className="card-footer font-weight-bold bg-white text-orange d-flex align-items-center justify-content-between footer-warning">
                                        Ubah Detail Pengguna
                                        <ArrowRight size={20}/>
                                    </div>
                                </AccessController>
                            </div>
                        </div>
                    </div>

                    <div className="row m-0 mb-5">
                        <div className="col-12">
                            <div className="card card-header-actions">
                                <div className="card-header">
                                    Daftar Aplikasi
                                    <AccessController name="Pengguna" access="update">
                                        <button onClick={this.modalAdd} className="btn btn-sm btn-success py-2 px-3"><PlusSquare size={18} className="mr-2"/><span className="mb-n1 mr-1">Tambah Aplikasi</span></button>
                                    </AccessController>
                                </div>
                                <div className="card-body">
                                    <div className="datatable">
                                        <table className="table table-striped table-hover" id="application-table">
                                            <thead>
                                                <tr>
                                                    <th width="5%" align="center"></th>
                                                    <th>Nama</th>
                                                    <th>Domain</th>
                                                    <th>Role</th>
                                                    <th>Akses</th>
                                                    <AccessController name="Pengguna" access="update">
                                                        <th>Aksi</th>
                                                    </AccessController>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.data.apps.map((value, index) => {
                                                        return (
                                                            <tr key={index} className="issp-table">
                                                                <th></th>
                                                                <td>{value.appId.name}</td>
                                                                <td><a style={{color: '#687281'}} target="_blank" rel="noopener noreferrer" href={'http://' + value.appId.domain}>{value.appId.domain}</a></td>
                                                                <td>{(value.roles.length > 0) ? value.roles[0].name : '-'}</td>
                                                                <td>{value.class.length + ' Hak Akses'}</td>
                                                                <AccessController name="Pengguna" access="update">
                                                                    <td className="d-flex">
                                                                        <div className="dropdown">
                                                                            <button className="btn btn-table rounded-circle" data-toggle="dropdown">
                                                                                <MoreHorizontal />
                                                                            </button>
                                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                                <button onClick={this.modalUpdateClass.bind(this, value)} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><Shield className="mt-n1" /></span>Ubah Hak Akses</button>
                                                                                <button onClick={this.modalUpdateRole.bind(this, value)} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><User className="mt-n1"/></span>Ubah Role</button>
                                                                                <button onClick={this.remove} data-userapp={value._id} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><Trash2 className="mt-n1" /></span>Hapus</button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </AccessController>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal" id="modal-edit" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Ubah Detail Pengguna</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <form onSubmit={this.edit}>
                                    <div className="modal-body">
                                        <div className="form-group">
                                            <label className="text-dark mb-1">Nama</label>
                                            <input onChange={this.handleChange} data-type="modal" value={this.state.modal.name} name="name" className="form-control py-3" type="text" placeholder="Masukkan nama lengkap" />
                                        </div>
                                        <div className="form-group">
                                            <label className="text-dark mb-1">Email</label>
                                            <input onChange={this.handleChange} data-type="modal" value={this.state.modal.email} name="email" className="form-control py-3" type="email" placeholder="Masukkan email" />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-orange" data-dismiss="modal">Batal</button>
                                        <button type="submit" className="btn btn-orange" disabled={this.checkAll()}>Simpan</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="modal" id="modal-add" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Tambah Aplikasi</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="text-dark mb-1">Aplikasi</label>
                                        <select onChange={this.handleChangeApps} value={this.state.modalApps.appId} name="appId" data-type="modalApps" className="form-control">
                                            <option value="" disabled>-- Pilih Aplikasi --</option>
                                            {
                                                this.state.apps.map((value, index) => {
                                                    return (
                                                        <option value={value._id} key={index}>{value.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    {
                                        this.state.modalApps.appId !== '' && (
                                            <React.Fragment>
                                                <div className="form-group">
                                                    <label className="text-dark mb-1">Role</label>
                                                    <select onChange={this.handleChange} value={this.state.modalApps.roles} name="roles" data-type="modalApps" className="form-control">
                                                        <option value="" disabled>-- Pilih Role --</option>
                                                        {
                                                            this.state.roles.map((value, index) => {
                                                                return (
                                                                    <option value={value._id} key={index}>{value.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="row m-0">
                                                    <div className="col-4 pl-0 mb-3 text-dark font-weight-bold">
                                                        Fitur
                                                    </div>
                                                    <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Read</div>
                                                    <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Create</div>
                                                    <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Update</div>
                                                    <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Delete</div>
                                                    {
                                                        this.state.modalApps.class.map((value, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <div className="col-4 mb-3 pl-0 d-flex align-items-center">
                                                                        {value.name}
                                                                    </div>
                                                                    <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                                        <input onChange={this.handleCheckbox} checked={value.menu.read} index={index} name="read" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                                    </div>
                                                                    <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                                        <input onChange={this.handleCheckbox} checked={value.menu.create} index={index} name="create" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                                    </div>
                                                                    <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                                        <input onChange={this.handleCheckbox} checked={value.menu.update} index={index} name="update" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                                    </div>
                                                                    <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                                        <input onChange={this.handleCheckbox} checked={value.menu.delete} index={index} name="delete" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                                    </div>
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    }
                                                    <div className="col-12 bg-light py-2 d-flex align-items-center justify-content-center rounded">
                                                        <div className="d-flex align-items-center">
                                                            <input onChange={this.handleCheckAll} checked={this.state.isCheckAll} id="check-all" className="form-check-input form-check-child m-0 mr-2" type="checkbox"/>
                                                            <span>Pilih Semua Akses</span>
                                                        </div>
                                                    </div> 
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-success" data-dismiss="modal">Batal</button>
                                    <button type="button" className="btn btn-success" onClick={this.add}>Tambah</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal" id="modal-update" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Ubah Hak Akses</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row m-0">
                                        <div className="col-4 pl-0 mb-3 text-dark font-weight-bold">
                                            Fitur
                                        </div>
                                        <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Read</div>
                                        <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Create</div>
                                        <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Update</div>
                                        <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Delete</div>
                                        {
                                            this.state.modalApps.class.map((value, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="col-4 mb-3 pl-0 d-flex align-items-center">
                                                            {value.name}
                                                        </div>
                                                        <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                            <input onChange={this.handleCheckbox} checked={value.menu.read} index={index} name="read" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                        </div>
                                                        <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                            <input onChange={this.handleCheckbox} checked={value.menu.create} index={index} name="create" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                        </div>
                                                        <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                            <input onChange={this.handleCheckbox} checked={value.menu.update} index={index} name="update" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                        </div>
                                                        <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                            <input onChange={this.handleCheckbox} checked={value.menu.delete} index={index} name="delete" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                        <div className="col-12 bg-light py-2 d-flex align-items-center justify-content-center rounded">
                                            <div className="d-flex align-items-center">
                                                <input onChange={this.handleCheckAll} checked={this.state.isCheckAll} id="check-all" className="form-check-input form-check-child m-0 mr-2" type="checkbox"/>
                                                <span>Pilih Semua Akses</span>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-orange" data-dismiss="modal">Batal</button>
                                    <button type="button" className="btn btn-orange" onClick={this.updateClass}>Simpan</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal" id="modal-role" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Ubah Role</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="text-dark mb-1">Role</label>
                                        <select onChange={this.handleChange} value={this.state.modalApps.roles} name="roles" data-type="modalApps" className="form-control">
                                            <option value="" disabled>-- Pilih Role --</option>
                                            {
                                                this.state.roles.map((value, index) => {
                                                    return (
                                                        <option value={value._id} key={index}>{value.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-orange" data-dismiss="modal">Batal</button>
                                    <button type="button" className="btn btn-orange" onClick={this.updateRole}>Simpan</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </AccessController>
            );
        }
        
        /* Handle First */
        else if(this.state.isShow === '')
        {
            return <Loading show={this.state.isLoading} />
        }

        /* Handle if Id doesn't exist */
        else
        {
            return <NotFound />
        }
    }
}
 
export default UserDetail;