import React, {Component} from "react";
import {Briefcase, Grid, Key, Monitor, StopCircle, Users} from "react-feather";
import {NavLink} from "react-router-dom";

import {RootContext} from "./Context";

import AccessController from "./AccessController";

import Logo from "../asset/img/ppu-logo-min.png";

class Sidebar extends Component {
  static contextType = RootContext;

  render() {
    return (
      <div className="issp-side">
        <div className="issp-brand">
          <img className="issp-brand-image" src={Logo} alt="Logo Kota Semarang" />
          <div className="issp-brand-name">Manajemen SSO</div>
          <div className="issp-mobile-toggle" onClick={this.props.toggleExpand}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </div>
        </div>
        <div className="issp-list">
          <NavLink to="/" exact activeClassName="active" className="issp-nav-link">
            <div className="issp-icon">
              <Grid style={{marginTop: "-2px"}} />
            </div>
            <div className="issp-name">Dashboard</div>
          </NavLink>
          {this.context.get.role === "Super Admin" && (
            <AccessController name="OPD" access="read">
              <NavLink to="/opd" exact activeClassName="active" className="issp-nav-link">
                <div className="issp-icon">
                  <Briefcase style={{marginTop: "-2px"}} />
                </div>
                <div className="issp-name">Organisasi</div>
              </NavLink>
            </AccessController>
          )}
          {this.context.get.role === "Super Admin" && (
            <AccessController name="Klaster" access="read">
              <NavLink to="/klaster" exact activeClassName="active" className="issp-nav-link">
                <div className="issp-icon">
                  <StopCircle style={{marginTop: "-2px"}} />
                </div>
                <div className="issp-name">Klaster</div>
              </NavLink>
            </AccessController>
          )}
          <AccessController name="Aplikasi" access="read">
            <NavLink to="/aplikasi" exact activeClassName="active" className="issp-nav-link">
              <div className="issp-icon">
                <Monitor style={{marginTop: "-2px"}} />
              </div>
              <div className="issp-name">Aplikasi</div>
            </NavLink>
          </AccessController>
          {this.context.get.role === "Super Admin" && (
            <AccessController name="Admin" access="read">
              <NavLink to="/admin" exact activeClassName="active" className="issp-nav-link">
                <div className="issp-icon">
                  <Key style={{marginTop: "-2px"}} />
                </div>
                <div className="issp-name">Admin</div>
              </NavLink>
            </AccessController>
          )}
          <AccessController name="Pengguna" access="read">
            <NavLink to="/pengguna" exact activeClassName="active" className="issp-nav-link">
              <div className="issp-icon">
                <Users style={{marginTop: "-2px"}} />
              </div>
              <div className="issp-name">Pengguna</div>
            </NavLink>
          </AccessController>
          <NavLink to="/cascading" exact activeClassName="active" className="issp-nav-link">
            <div className="issp-icon">
              <Grid style={{marginTop: "-2px"}} />
            </div>
            <div className="issp-name">Cascading</div>
          </NavLink>
        </div>
      </div>
    );
  }
}

export default Sidebar;
