import fetchNow from './_Fetch.jsx';

const AuthAPI = {
    user: (body) => {
        return fetchNow('/auth', 'POST', body, 'JSON');
    },
    
    getToken: async(sso_code) => {
        let response = await fetch(process.env.REACT_APP_API + '/auth/sess/' + sso_code, {
            method: 'POST'
        });

        return await response.json();
    },

    logout: (body) => {
        return fetchNow('/auth/logout', 'POST', body);
    }
}
 
export default AuthAPI;