import React, { Component } from 'react';
import CountUp from 'react-countup';
import { Briefcase, ChevronDown, Monitor, Users } from 'react-feather';
import { Line, Pie } from 'react-chartjs-2';
import moment from 'moment';
import 'moment/locale/id';
import OpdAPI from '../../../api/Opd';
import Loading from '../../../component/Loading';
import StatisticAPI from '../../../api/Statistic';
import ClusterAPI from '../../../api/Cluster';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            choice: "apps",

            total: {
                opd: 0,
                application: 0,
                user: 0
            },
            opd: [],
            cluster: [],
            statistic: {
                user: [],
                app: [],
                opd: [],
                cluster: []
            },

            chart: {
                apps: {
                    labels: [],
                    datasets: []
                },
                user: {
                    labels: [],
                    datasets: []
                }
            },
            daily: {
                labels: [],
                datasets: []
            },

            dailyCluster: {
                labels: [],
                datasets: []
            },
            clusterStatistic: [],
            clusterChart: {
                apps: {
                    labels: [],
                    datasets: []
                },
                user: {
                    labels: [],
                    datasets: []
                }
            }
        }

        this.fetchNow = this.fetchNow.bind(this);
        this.fetchDaily = this.fetchDaily.bind(this);
        this.fetchStatistic = this.fetchStatistic.bind(this);

        this.handleChoice = this.handleChoice.bind(this);
        this.handleClusterStatistic = this.handleClusterStatistic.bind(this);
    }

    fetchNow() {
        this.setState({
            isLoading: true
        }, () => {
            StatisticAPI.get().then((data) => {
                OpdAPI.get().then((data2) => {
                    ClusterAPI.get().then(data3 => {
                        StatisticAPI.getByCluster(data3.data[0]._id).then(data4 => {
                            //Total Data
                            let total = {
                                opd: data.data.TotalOpd || 0,
                                application: data.data.TotalApp || 0,
                                user: data.data.TotalUser || 0
                            }

                            //Initialize Chart Data Object
                            let apps = {
                                labels: [],
                                datasets: [
                                    {
                                        label: 'Sebaran Aplikasi',
                                        data: [],
                                        backgroundColor: [
                                            '#8bc34a', '#ffeb3b', '#ffc107', '#ff5722', '#e91e63', '#259b24', '#cddc39', '#ff9800', '#e51c23',
                                            '#9c27b0', '#ffeb3b', '#00bcd4', '#9e9e9e', '#607d8b', '#673ab7', '#5677fc', '#009688', '#795548'
                                        ],
                                        borderWidth: 0
                                    }
                                ]
                            };

                            let clusterApps = {
                                labels: [],
                                datasets: [
                                    {
                                        label: 'Sebaran Aplikasi',
                                        data: [],
                                        backgroundColor: [
                                            '#8bc34a', '#ffeb3b', '#ffc107', '#ff5722', '#e91e63', '#259b24', '#cddc39', '#ff9800', '#e51c23',
                                            '#9c27b0', '#ffeb3b', '#00bcd4', '#9e9e9e', '#607d8b', '#673ab7', '#5677fc', '#009688', '#795548'
                                        ],
                                        borderWidth: 0
                                    }
                                ]
                            };

                            let user = {
                                labels: [],
                                datasets: [
                                    {
                                        label: 'Sebaran Pengguna',
                                        data: [],
                                        backgroundColor: [
                                            '#8bc34a', '#ffeb3b', '#ffc107', '#ff5722', '#e91e63', '#259b24', '#cddc39', '#ff9800', '#e51c23',
                                            '#9c27b0', '#ffeb3b', '#00bcd4', '#9e9e9e', '#607d8b', '#673ab7', '#5677fc', '#009688', '#795548'
                                        ],
                                        borderWidth: 0
                                    }
                                ]
                            };

                            //Temporary Array
                            let result = {
                                user: [],
                                apps: []
                            }
                            let clusterResult = {
                                apps: []
                            }

                            //Push to Temporary Array
                            for (let value of data2.data) {
                                result.apps.push({
                                    label: value.name,
                                    data: value.appCount
                                })
                                result.user.push({
                                    label: value.name,
                                    data: value.userCount
                                })
                            }

                            for (let value of data3.data) {
                                clusterResult.apps.push({
                                    label: value.name,
                                    data: value.appCount
                                })
                            }

                            //Sorting Large to Small App Count
                            result.apps.sort((a, b) => (a.data < b.data) ? 1 : ((b.data < a.data) ? -1 : 0));
                            result.user.sort((a, b) => (a.data < b.data) ? 1 : ((b.data < a.data) ? -1 : 0));

                            clusterResult.apps.sort((a, b) => (a.data < b.data) ? 1 : ((b.data < a.data) ? -1 : 0));

                            //Push to Chart Data Object
                            for (let i = 0; i < result.apps.length; i++) {
                                if (i < 18) {
                                    apps.labels.push(result.apps[i].label);
                                    apps.datasets[0].data.push(result.apps[i].data);

                                    user.labels.push(result.user[i].label);
                                    user.datasets[0].data.push(result.user[i].data);
                                } else {
                                    apps.datasets[0].data[17] += result.apps[i].data;
                                    user.datasets[0].data[17] += result.user[i].data;
                                }
                            }

                            if (apps.datasets[0].data.length === 18) {
                                apps.labels[apps.datasets[0].data.length - 1] = 'Lainnya';
                            }

                            if (user.datasets[0].data.length === 18) {
                                user.labels[user.datasets[0].data.length] = 'Lainnya';
                            }

                            for (let i = 0; i < clusterResult.apps.length; i++) {
                                if (i < 18) {
                                    clusterApps.labels.push(clusterResult.apps[i].label);
                                    clusterApps.datasets[0].data.push(clusterResult.apps[i].data);
                                } else {
                                    clusterApps.datasets[0].data[17] += clusterResult.apps[i].data;
                                }
                            }

                            if (clusterApps.datasets[0].data.length === 18) {
                                clusterApps.labels[clusterApps.datasets[0].data.length - 1] = 'Lainnya';
                            }

                            this.setState({
                                isLoading: false,
                                total: total,
                                opd: data2.data || [],
                                cluster: data3.data || [],
                                chart: {
                                    apps: apps,
                                    user: user
                                },
                                clusterStatistic: data4.data.MostAndLessApp,
                                clusterChart: {
                                    apps: clusterApps
                                }
                            })
                        })
                    })
                })
            })
        })
    }

    fetchDaily() {
        this.setState({
            isLoading: true
        }, () => {
            moment.locale('id');

            StatisticAPI.getDaily({ limit: 1 }).then((data) => {
                let chart = {
                    labels: [],
                    datasets: [
                        {
                            label: 'Total Login',
                            data: [],
                            fill: true,
                            backgroundColor: 'rgba(233, 30, 99, .15)',
                            borderColor: 'rgba(233, 30, 99, 1)',
                            tension: .25
                        }
                    ]
                };

                let baseConfig = {
                    borderColor: () => {
                        let letters = '0123456789ABCDEF';
                        let color = '#';
                        for (let i = 0; i < 6; i++) {
                            color += letters[Math.floor(Math.random() * 16)];
                        }
                        return color;
                    },
                    tension: .25
                }

                let chartCluster = {
                    labels: [],
                    datasets: []
                };

                for (let i = data.data.length - 1; i >= 0; i--) {
                    let value = data.data[i];

                    chart.labels.push(moment(value.timestamp).format('D MMM'));
                    chart.datasets[0].data.push(value.totalLogin);

                    chartCluster.labels.push(moment(value.timestamp).format('D MMM'));
                    for (let j = 0; j < value.loginPerCluster.length; j++) {
                        const el = value.loginPerCluster[j];
                        if (!chartCluster.datasets.some(query => query.label === el._id.name)) {
                            chartCluster.datasets.push({
                                label: el._id.name,
                                data: [],
                                ...baseConfig
                            })
                        }

                        let findDatasets = chartCluster.datasets.find(query => query.label === el._id.name)
                        findDatasets.data.push(el.used)
                    }
                }

                this.setState({
                    isLoading: false,
                    daily: chart,
                    dailyCluster: chartCluster
                })
            })
        })
    }

    fetchStatistic() {
        this.setState({
            isLoading: true
        }, () => {
            StatisticAPI.getStatistic().then((dataAPI) => {
                StatisticAPI.get().then((dataOpd) => {
                    let data = {
                        user: dataAPI.data.MostAndLessUser,
                        app: dataAPI.data.MostAndLessApp,
                        opd: dataOpd.data.MostAndLessOpd,
                        cluster: dataOpd.data.MostAndLessCluster,
                    }

                    data.user.sort((a, b) => (a.used < b.used) ? 1 : ((b.used < a.used) ? -1 : 0));
                    data.app.sort((a, b) => (a.used < b.used) ? 1 : ((b.used < a.used) ? -1 : 0));
                    data.opd.sort((a, b) => (a.used < b.used) ? 1 : ((b.used < a.used) ? -1 : 0));
                    data.cluster.sort((a, b) => (a.used < b.used) ? 1 : ((b.used < a.used) ? -1 : 0));

                    let result = {
                        user: [],
                        app: [],
                        opd: [],
                        cluster: [],
                    }

                    for (let i = 0; i < ((data.user.length > 3) ? 3 : data.user.length); i++) {
                        result.user.push(data.user[i]);
                    }

                    for (let i = 0; i < ((data.app.length > 3) ? 3 : data.app.length); i++) {
                        result.app.push(data.app[i]);
                    }

                    for (let i = 0; i < ((data.opd.length > 3) ? 3 : data.opd.length); i++) {
                        result.opd.push(data.opd[i]);
                    }

                    for (let i = 0; i < ((data.cluster.length > 3) ? 3 : data.cluster.length); i++) {
                        result.cluster.push(data.cluster[i]);
                    }

                    this.setState({
                        isLoading: false,
                        statistic: result
                    })
                })
            })
        })
    }

    handleChoice(event) {
        console.log(event);
        let state = this.state.choice;
        let target = event.currentTarget.getAttribute('data-name');

        if (state !== target) {
            this.setState({
                choice: target
            })
        }
    }

    handleClusterStatistic(event) {
        let _id = event.currentTarget.getAttribute('value');
        this.setState({
            isLoading: true
        }, () => {
            StatisticAPI.getByCluster(_id).then(res => {
                this.setState({
                    isLoading: false,
                    clusterStatistic: res.data.MostAndLessApp
                })
            })
        })
    }

    componentDidMount() {
        this.fetchNow();
        this.fetchDaily();
        this.fetchStatistic();
    }

    render() {
        return (
            <React.Fragment>
                <Loading show={this.state.isLoading} />

                <div className="row d-flex align-items-center m-0 mt-2 mb-4">
                    <div className="col-lg col-12 text-center text-lg-left">
                        <h1 className="mb-0">Dashboard</h1>
                    </div>
                </div>
                <div className="row m-0 mb-5">
                    <div className="col-md-4 mb-4">
                        <div className="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-primary h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <div className="small font-weight-bold text-primary mb-1">Total Organisasi</div>
                                        <div className="h1"><CountUp end={this.state.total.opd} duration={2} /></div>
                                    </div>
                                    <div className="ml-2 text-gray-300"><Briefcase size={36} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-primary h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <div className="small font-weight-bold text-primary mb-1">Total Aplikasi</div>
                                        <div className="h1"><CountUp end={this.state.total.application} duration={2} /></div>
                                    </div>
                                    <div className="ml-2 text-gray-300"><Monitor size={36} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-primary h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <div className="small font-weight-bold text-primary mb-1">Total Pengguna</div>
                                        <div className="h1"><CountUp end={this.state.total.user} duration={2} /></div>
                                    </div>
                                    <div className="ml-2 text-gray-300"><Users size={36} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="card h-100 card-header-actions">
                            <div className="dropdown">
                                <div style={{ cursor: 'pointer' }} data-toggle="dropdown" className="card-header">
                                    Jumlah {this.state.choice === 'apps' ? 'Aplikasi' : 'Pengguna'} Per Organisasi
                                    <div ><ChevronDown /></div>
                                </div>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item w-100" type="button" onClick={this.handleChoice} data-name="apps"><span className="dropdown-item-icon"></span>Jumlah Aplikasi</button>
                                    <button className="dropdown-item w-100" type="button" onClick={this.handleChoice} data-name="user"><span className="dropdown-item-icon"></span>Jumlah Pengguna</button>
                                </div>
                            </div>
                            <div className="card-body py-4 px-5 d-flex align-items-center justify-content-center">
                                <Pie data={this.state.chart[this.state.choice]} options={{ plugins: { legend: { display: false } } }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 mb-4">
                        <div className="card h-100">
                            <div className="card-header">
                                Jumlah Login Harian
                            </div>
                            <div className="card-body">
                                <Line data={this.state.daily} options={{ animation: false, interaction: { mode: 'nearest', intersect: false } }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="card h-100">
                            <div className="card-header">
                                Aktivitas Organisasi Tertinggi
                            </div>
                            <div className="card-body pb-1">
                                <div className="row">
                                    {
                                        this.state.statistic.opd.map((value, index) => {
                                            return (
                                                <div key={index} className="col-12 mb-4 d-flex align-items-center">
                                                    <div className="box-icon mr-3 d-flex justify-content-center align-items-center">
                                                        {index + 1}
                                                    </div>
                                                    <div className="box-value d-flex justify-content-between align-items-center">
                                                        <p className="title">{value._id.name}</p>
                                                        <p className="value ml-3">{value.used} Kali</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="card h-100">
                            <div className="card-header">
                                Penggunaan Aplikasi Tertinggi
                            </div>
                            <div className="card-body pb-1">
                                <div className="row">
                                    {
                                        this.state.statistic.app.map((value, index) => {
                                            return (
                                                <div key={index} className="col-12 mb-4 d-flex align-items-center">
                                                    <div className="box-icon mr-3 d-flex justify-content-center align-items-center">
                                                        {index + 1}
                                                    </div>
                                                    <div className="box-value d-flex justify-content-between align-items-center">
                                                        <p className="title">{value._id}</p>
                                                        <p className="value ml-3">{value.used} Kali</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="card h-100">
                            <div className="card-header">
                                Aktivitas Login Tertinggi
                            </div>
                            <div className="card-body pb-1">
                                <div className="row">
                                    {
                                        this.state.statistic.user.map((value, index) => {
                                            return (
                                                <div key={index} className="col-12 mb-4 d-flex align-items-center">
                                                    <div className="box-icon mr-3 d-flex justify-content-center align-items-center">
                                                        {index + 1}
                                                    </div>
                                                    <div className="box-value d-flex justify-content-between align-items-center">
                                                        <p className="title">{value._id}</p>
                                                        <p className="value ml-3">{value.used} Kali</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-4 mb-4">
                        <div className="card h-100 card-header-actions">
                            <div className="dropdown">
                                <div className="card-header">
                                    Jumlah apps Per Cluster
                                </div>
                            </div>
                            <div className="card-body py-4 px-5 d-flex align-items-center justify-content-center">
                                <Pie data={this.state.clusterChart['apps']} options={{ plugins: { legend: { display: false } } }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 mb-4">
                        <div className="card h-100">
                            <div className="card-header">
                                Login harian Tiap Cluster
                            </div>
                            <div className="card-body">
                                <Line data={this.state.dailyCluster} options={{ animation: false, interaction: { mode: 'nearest', intersect: false } }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4 mb-md-0">
                        <div className="card h-100">
                            <div className="dropdown">
                                <div style={{ cursor: 'pointer' }} data-toggle="dropdown" className="card-header d-flex justify-content-between">
                                    Penggunaan aplikasi tertinggi pada cluster {this.state.ClusterChoice}
                                    <span><ChevronDown /></span>
                                </div>
                                <div className="dropdown-menu dropdown-menu-right">
                                    {
                                        this.state.cluster.map((value, index) => {
                                            return (
                                                <button key={index} className="dropdown-item w-100" type="button" onClick={this.handleClusterStatistic} value={value._id} ><span className="dropdown-item-icon"></span>{value.name}</button>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="card-body pb-1">
                                <div className="row">
                                    {
                                        this.state.clusterStatistic.map((value, index) => {
                                            return (
                                                <div key={index} className="col-12 mb-4 d-flex align-items-center">
                                                    <div className="box-icon mr-3 d-flex justify-content-center align-items-center">
                                                        {index + 1}
                                                    </div>
                                                    <div className="box-value d-flex justify-content-between align-items-center">
                                                        <p className="title">{value._id.name}</p>
                                                        <p className="value ml-3">{value.used} Kali</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4 mb-md-0">
                        <div className="card h-100">
                            <div className="card-header">
                                Penggunaan Cluster Tertinggi
                            </div>
                            <div className="card-body pb-1">
                                <div className="row">
                                    {
                                        this.state.statistic.cluster.map((value, index) => {
                                            return (
                                                <div key={index} className="col-12 mb-4 d-flex align-items-center">
                                                    <div className="box-icon mr-3 d-flex justify-content-center align-items-center">
                                                        {index + 1}
                                                    </div>
                                                    <div className="box-value d-flex justify-content-between align-items-center">
                                                        <p className="title">{value._id.name}</p>
                                                        <p className="value ml-3">{value.used} Kali</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Dashboard;
