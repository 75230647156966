import React, { Component } from "react";
import { RootContext } from "../component/Context";

import Navbar from "../component/Navbar";
import Sidebar from "../component/Sidebar";
import NotFound from "../component/NotFound";

import Dashboard from "./super/dashboard/Dashboard";
import Opd from "./super/opd/Opd";
import Application from "./super/application/Application";
import ApplicationDetail from "./super/application/ApplicationDetail";
import Admin from "./super/admin/Admin";
import User from "./super/user/User";
import UserDetail from "./super/user/UserDetail";

import OPDDashboard from "./opd/dashboard/Dashboard";
import OPDApplication from "./opd/application/Application";
import OPDUserDetail from "./opd/user/UserDetail";
import Cluster from "./super/cluster/Cluster";
import Cascading from "./super/cascading/Cascading";

class Page extends Component {
  static contextType = RootContext;

  constructor(props) {
    super(props);

    this.state = {
      isExpanded: localStorage.getItem("isExpanded") !== null ? JSON.parse(localStorage.getItem("isExpanded")) : true,
    };

    this.toggleExpand = this.toggleExpand.bind(this);
  }

  switchSuper(target) {
    switch (target) {
      case "Dashboard":
        return <Dashboard />;

      case "OPD":
        return <Opd />;

      case "Cluster":
        return <Cluster />;

      case "Application":
        return <Application />;
      case "ApplicationDetail":
        return <ApplicationDetail id={this.props.match.params.id} />;

      case "Admin":
        return <Admin />;

      case "User":
        return <User />;
      case "UserDetail":
        return <UserDetail id={this.props.match.params.id} />;

      case "Cascading":
        return <Cascading />;

      default:
        return <NotFound />;
    }
  }

  switchOPD(target) {
    switch (target) {
      case "Dashboard":
        return <OPDDashboard />;

      case "Application":
        return <OPDApplication />;
      case "ApplicationDetail":
        return <ApplicationDetail id={this.props.match.params.id} />;

      case "User":
        return <User />;
      case "UserDetail":
        return <OPDUserDetail id={this.props.match.params.id} />;

      case "Cascading":
        return <Cascading />;

      default:
        return <NotFound />;
    }
  }

  toggleExpand() {
    this.setState(
      {
        isExpanded: this.state.isExpanded ? false : true,
      },
      () => {
        localStorage.setItem("isExpanded", this.state.isExpanded);
      }
    );
  }

  componentDidMount() {
    /* If isExpand variable doesn't exist */
    if (localStorage.getItem("isExpanded") !== null) {
      localStorage.setItem("isExpanded", this.state.isExpanded);
    }
  }

  render() {
    return (
      <div className={"issp-root" + (this.state.isExpanded ? "" : " hide")}>
        <Sidebar toggleExpand={this.toggleExpand} />
        <div className="issp-main">
          <Navbar toggleExpand={this.toggleExpand} />
          <div className="issp-content" style={{ maxWidth: "1440px", margin: "0 auto" }}>
            {this.context.get.role === "Super Admin" ? this.switchSuper(this.props.name) : this.switchOPD(this.props.name)}
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
