import React, { Component } from 'react';
import { RootContext } from './Context';
import NotFound from './NotFound';

class AccessController extends Component {
    static contextType = RootContext;

    render() { 
        /* Store Data and Variable */
        let data = this.context.get.user.class, status = false;

        /* Check if User Have Access (ACL) */
        for(let value of data) {
            if(this.props.name === value.menu.name) {
                if(value.acl[this.props.access]) {
                    status = true;
                    break;
                }
            }
        }

        if(status) {
            /* If Have Access, Return Children */
            return this.props.children;
        }else{
            /* Handle Doesn't Have Access */
            if(this.props.type === 'page') {
                return <NotFound />
            }else{
                return null;
            }
        }
    }
}
 
export default AccessController;