import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class NotFound extends Component {
    render() { 
        return (
            <div className="d-flex flex-column align-items-center justify-content-center mt-n4 text-center" style={{width: "100%", height: "calc(100vh - 68px)"}}>
                <Helmet title="Manajemen SSO" />
                <h1 style={{fontSize: "3.5rem", fontWeight: "700", marginBottom: ".1rem"}}>Halaman Tidak Ditemukan</h1>
                <p>Halaman yang anda kunjungi tidak tersedia atau sedang dalam pengembangan.</p>
            </div>
        );
    }
}
 
export default NotFound;