import React, { Component } from "react";
import { Briefcase, Eye, Monitor, MoreHorizontal, StopCircle, Trash2, Upload, Plus } from "react-feather";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import { readString } from "react-papaparse";
import Dropzone from "react-dropzone";
import { Typeahead } from 'react-bootstrap-typeahead';

import OpdAPI from "../../../api/Opd";
import ClusterAPI from "../../../api/Cluster";
import ApplicationAPI from "../../../api/Application";

import AccessController from "../../../component/AccessController";
import Loading from "../../../component/Loading";
import Template from "../../../asset/other/Import-Aplikasi-Template.csv";

const DeepClone = require("rfdc")();
toast.configure();

class Application extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isDropzone: false,

      importAplikasi: [],
      data: [],
      opd: [],
      cluster: [],
      show: [],

      modal: {
        id: "",
        name: "",
        domain: "",
        tagId: "",
        clusterId: [],
        cluster: [],
        isPublic: "not",
        description: "",
        otpSetting: "yes",
        file: [],
      },

      filter: {
        opd: "",
        cluster: "",
      },
    };

    this.fetchNow = this.fetchNow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handelClusterChange = this.handelClusterChange.bind(this);

    this.modalCreate = this.modalCreate.bind(this);
    this.handleCreate = this.handleCreate.bind(this);

    this.modalCSV = this.modalCSV.bind(this);
    this.parseAplikasi = this.parseAplikasi.bind(this);
    this.import = this.import.bind(this);

    this.handleDelete = this.handleDelete.bind(this);

    this.handleFilter = this.handleFilter.bind(this);
  }

  compare(a, b) {
    /* Sorting Compare */
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  }

  findById(id, data, type) {
    for (let value of data) {
      if (value[type] === id) {
        return value;
      }
    }
  }

  fetchNow() {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        OpdAPI.get().then((dataOpd) => {
          ClusterAPI.get().then((dataCluster) => {
            ApplicationAPI.get().then((dataApps) => {
              /* Assign Variable */
              let data = [],
                opd = [],
                cluster = [];

              /* Remove Existing Datatable */
              if ($.fn.dataTable.isDataTable("#application-table")) {
                $("#application-table").DataTable().destroy();
              }

              /* Handle Success */
              if (dataOpd.status === 200 && dataApps.status === 200 && dataCluster.status === 200) {
                /* Store OPD Data */
                opd = dataOpd.data;
                opd.sort(this.compare);

                /* Store Cluster Data */
                cluster = dataCluster.data;
                cluster.sort(this.compare);

                /* Store Application Data */
                for (let value of dataApps.data) {
                  value.opdId = this.findById(value.tagId, opd, "_id");
                  let temp = [];
                  for (const el of value.clusterId) {
                    let clusterDetail = this.findById(el, cluster, "_id");
                    temp.push(clusterDetail);
                  }

                  value.cluster = temp;
                  data.push(value);
                }
              } else {
                /* Handle Failed */
                toast.error("Terdapat Kesalahan, Silahkan Coba Beberapa Saat Lagi", {
                  position: toast.POSITION.TOP_CENTER,
                });
              }

              /* Update Data */
              this.setState(
                {
                  isLoading: false,
                  show: data,
                  filter: {
                    opd: "",
                    cluster: "",
                  },
                  data,
                  opd,
                  cluster,
                },
                () => {
                  /* Initialize New Datatable */
                  var table = $("#application-table").DataTable({
                    columnDefs: [
                      {
                        searchable: false,
                        orderable: false,
                        targets: 0,
                      },
                    ],
                    order: [[1, "asc"]],
                    language: {
                      lengthMenu: "Lihat _MENU_ data",
                      zeroRecords: "Tidak ada data yang tersedia",
                      info: "Data _START_ - _END_ dari total _TOTAL_ data",
                      infoEmpty: "Menampilkan 0 data",
                      search: "Cari",
                      paginate: {
                        previous: "Sebelumnya",
                        next: "Selanjutnya",
                      },
                    },
                  });

                  table
                    .on("order.dt search.dt", function () {
                      table
                        .column(0, { search: "applied", order: "applied" })
                        .nodes()
                        .each(function (cell, i) {
                          cell.innerHTML = i + 1;
                        });
                    })
                    .draw();
                }
              );
            });
          });
        });
      }
    );
  }

  handleChange(event) {
    /* Store Variable */
    let modal = this.state.modal;

    if (event.target.type === "file") {
      modal[event.target.name] = event.target.files;
    } else {
      /* Change Value */
      modal[event.target.name] = event.target.value;
    }

    /* Update Data */
    this.setState({
      modal,
    });
  }

  handelClusterChange(event) {
    let clusterId = []
    let cluster = []
    for (let i = 0; i < event.length; i++) {
        const el = event[i];
        clusterId.push(el._id)
        cluster.push(el)
    }
    
    this.setState({
        modal: {
            ...this.state.modal,
            clusterId: clusterId,
            cluster: cluster
        }
    })
  }

  modalCreate() {
    /* Reset Modal */
    this.setState(
      {
        modal: {
          id: "",
          name: "",
          domain: "",
          opdId: "",
          clusterId: [],
          cluster:[],
          isPublic: "not",
          description: "",
          otpSetting: "yes",
          file: [],
        },
      },
      () => {
        /* Show Modal */
        $("#modalCreate").modal("show");
      }
    );
  }

  handleCreate() {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        /* Store Result */
        let bodyData = {
          name: this.state.modal.name,
          domain: this.state.modal.domain,
          redirect_uri: this.state.modal.domain,
          isPublic: this.state.modal.isPublic === "public" ? true : false,
          tagId: this.state.modal.opdId,
          description: this.state.modal.description,
          clusterId: this.state.modal.clusterId,
          userCount: 0,
          otpSetting: this.state.modal.otpSetting === "yes" ? true : false,
        };

        /* Assign FormData */
        let body = new FormData();

        /* Add Data */
        body.append("data", JSON.stringify(bodyData));

        /* Add File if Exist */
        if (this.state.modal.file.length > 0) {
          body.append("file", this.state.modal.file[0]);
        }

        /* Request Create Apps */
        ApplicationAPI.create(body).then((data) => {
          /* Hide Modal */
          $("#modalCreate").modal("hide");

          if (data.status === 200) {
            /* Handle Success */
            this.fetchNow();

            toast.success("Penambahan Aplikasi Berhasil", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            /* Handle Failed */
            this.setState(
              {
                isLoading: false,
              },
              () => {
                toast.error("Penambahan Aplikasi Gagal, Silahkan Coba Beberapa Saat Lagi", {
                  position: toast.POSITION.TOP_CENTER,
                });
              }
            );
          }
        });
      }
    );
  }

  modalCSV() {
    this.setState(
      {
        importAplikasi: [],
        isDropzone: true,
      },
      () => {
        $("#modal-csv").modal("show");
      }
    );
  }

  parseAplikasi(file) {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        /* Assign File Reader */
        let reader = new FileReader();

        /* Read Uploaded File */
        reader.readAsText(file[0]);

        /* Parse File */
        reader.onload = () => {
          /* Assign Variable and Store Data */
          let data = readString(reader.result, { header: true, skipEmptyLines: true }).data;
          let isError = false,
            importAplikasi = [];

          /* Process if Data Not Empty */
          if (data.length > 0) {
            /* Process if CSV Format is Correct */
            if (data[0].hasOwnProperty("name") && data[0].hasOwnProperty("clusterName")) {
              /* Loop All Data */
              for (let value of data) {
                /* Add if Username is Not Empty */
                if (value.name !== null && value.name !== "") {
                  let tagList = this.state.opd;
                  let clusterList = this.state.cluster;

                  let appCluster = clusterList.find((cluster) => cluster.name === value.clusterName);
                  let appTag = tagList.find((tag) => tag.name === value.tagName);

                  /* Store Value */
                  let aplikasi = {
                    name: value.name,
                    domain: value.domain,
                    isPublic: value.isPublic === "yes" ? true : false,
                    description: value.description,
                    tagId: appTag._id,
                    clusterId: appCluster._id,
                    otpSetting: value.otpSetting === "yes" ? true : false,
                  };

                  /* Add to Import List */
                  importAplikasi.push(aplikasi);
                }
              }
            } else {
              /* CSV Format Wrong */
              isError = true;
            }
          }

          /* Update */
          this.setState(
            {
              isLoading: false,
              importAplikasi,
            },
            () => {
              if (importAplikasi.length <= 0) {
                if (isError) {
                  /* Wrong Format Alert */
                  toast.error(`CSV yang Diunggah Tidak Sesuai Format`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                  });
                } else {
                  /* Empty Data Alert */
                  toast.error(`Tidak Ada Data yang Valid dalam CSV`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                  });
                }
              }
            }
          );
        };
      }
    );
  }

  import() {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        for (let i = 0; i < this.state.importAplikasi.length; i++) {
          let aplikasi = this.state.importAplikasi[i];
          let bodyData = {
            name: aplikasi.name,
            domain: aplikasi.domain,
            redirect_uri: aplikasi.domain,
            description: aplikasi.description,
            isPublic: aplikasi.isPublic,
            tagId: aplikasi.tagId,
            clusterId: [aplikasi.clusterId],
            userCount: 0,
          };

          let body = new FormData();

          body.append("data", JSON.stringify(bodyData));

          ApplicationAPI.create(body).then((result) => {
            if (result.status === 200) {
              /* Handle Success */
              this.fetchNow();

              $("#modal-csv").modal("hide");
            } else {
              /* Handle Error */
              this.setState(
                {
                  isLoading: false,
                },
                () => {
                  $("#modal-csv").modal("hide");

                  toast.error("Import Aplikasi Gagal, Silahkan Coba Beberapa Saat Lagi", {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                  });
                }
              );
            }
          });
        }
        if (this.state.importAplikasi.length > 4) {
          toast.success("Import Aplikasi sedang Dilakukan, Silahkan Tunggu Beberapa Saat dan Muat Ulang Halaman ini", {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            autoClose: 5000,
          });
        } else {
          toast.success("Import Aplikasi Berhasil", {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
          });
        }
      }
    );
  }

  handleDelete(value) {
    let id = value._id;

    if(window.confirm('Are sure want to delete?')) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          ApplicationAPI.delete(id).then((data) => {
            if (data.status === 200) {
              /* Handle Success */
              this.fetchNow();

              toast.warn("Aplikasi Berhasil Dihapus", {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              /* Handle Failed */
              this.setState(
                {
                  isLoading: false,
                },
                () => {
                  toast.error("Aplikasi Gagal Dihapus, Silahkan Coba Beberapa Saat Lagi", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                }
              );
            }
          });
        }
      );
    }
  }

  handleFilter(event) {
    /* Store Variable */
    let filter = this.state.filter,
      show = [],
      result = [];

    /* Store New Value */
    filter[event.target.name] = event.target.value;

    /* Filter Apps by OPD */
    if (filter.opd === "") {
      show = DeepClone(this.state.data);
    } else {
      for (let value of this.state.data) {
        if (value.opdId._id === filter.opd) {
          show.push(value);
        }
      }
    }

    /* Filter Apps by Cluster */
    if (filter.cluster === "") {
      result = show;
    } else {
      for (let value of show) {
        if (value.clusterId) {
          if (value.clusterId.includes(filter.cluster)) {
            result.push(value);
          }
        }
      }
    }

    /* Remove Existing Datatable */
    if ($.fn.dataTable.isDataTable("#application-table")) {
      $("#application-table").DataTable().destroy();
    }

    this.setState(
      {
        show: result,
        filter,
      },
      () => {
        /* Initialize New Datatable */
        var table = $("#application-table").DataTable({
          columnDefs: [
            {
              searchable: false,
              orderable: false,
              targets: 0,
            },
          ],
          order: [[1, "asc"]],
          language: {
            lengthMenu: "Lihat _MENU_ data",
            zeroRecords: "Tidak ada data yang tersedia",
            info: "Data _START_ - _END_ dari total _TOTAL_ data",
            infoEmpty: "Menampilkan 0 data",
            search: "Cari",
            paginate: {
              previous: "Sebelumnya",
              next: "Selanjutnya",
            },
          },
        });

        table
          .on("order.dt search.dt", function () {
            table
              .column(0, { search: "applied", order: "applied" })
              .nodes()
              .each(function (cell, i) {
                cell.innerHTML = i + 1;
              });
          })
          .draw();

        /* Hide Modal */
        $("#modal-" + event.target.name).modal("hide");
      }
    );
  }

  componentDidMount() {
    /* Initialize Tooltip */
    $('[data-toggle="tooltip"]').tooltip();

    this.fetchNow();
  }

  render() {
    return (
      <AccessController name="Aplikasi" access="read" type="page">
        <Loading show={this.state.isLoading} />
        <Helmet title="Aplikasi - Manajemen SSO" />

        <div className="row d-flex align-items-center m-0 mt-2 mb-4">
          <div className="col-lg col-12 text-center text-lg-left">
            <h1 className="mb-0">Aplikasi</h1>
          </div>
          <div className="col-lg-auto col-12 d-flex flex-md-row flex-column align-items-center justify-content-center mt-2 mt-lg-0">
            <div className="mr-0 mr-md-2 mb-2 mb-md-0">
              <button
                className="btn btn-filter btn-issp"
                onClick={() => {
                  $("#modal-opd").modal("show");
                }}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Tampilkan Aplikasi Berdasarkan OPD"
              >
                <Briefcase size={18} className="icon-btn" />
                <span className="text-truncate" style={{ maxWidth: "10rem" }}>
                  {this.state.filter.opd === "" ? "Semua OPD" : this.findById(this.state.filter.opd, this.state.opd, "_id").name}
                </span>
              </button>
            </div>
            <div className="mr-0 mr-md-2 mb-2 mb-md-0">
              <button
                className="btn btn-filter btn-issp"
                onClick={() => {
                  $("#modal-cluster").modal("show");
                }}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Tampilkan Aplikasi Berdasarkan Klaster"
              >
                <StopCircle size={18} className="icon-btn" />
                <span className="text-truncate" style={{ maxWidth: "10rem" }}>
                  {this.state.filter.cluster === "" ? "Semua Klaster" : "Klaster " + this.findById(this.state.filter.cluster, this.state.cluster, "_id").name}
                </span>
              </button>
            </div>
            <AccessController name="Aplikasi" access="create">
              <div className="dropdown">
                <button className="btn btn-sm btn-primary py-2 px-3 dropdown-toggle" data-toggle="dropdown">
                  <Monitor size={18} className="icon-btn mr-2" />
                  <span className="mb-n1 mr-1">Tambah Aplikasi</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button onClick={this.modalCreate} className="dropdown-item w-100" type="button">
                    <span className="dropdown-item-icon">
                      <Plus className="mt-n1" />
                    </span>
                    Tambah Baru
                  </button>
                  <button onClick={this.modalCSV} className="dropdown-item w-100" type="button">
                    <span className="dropdown-item-icon">
                      <Upload />
                    </span>
                    Import CSV
                  </button>
                </div>
              </div>
            </AccessController>
          </div>
        </div>

        <div className="row m-0 mb-5">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="datatable">
                  <table className="table table-striped table-hover" id="application-table">
                    <thead>
                      <tr>
                        <th width="5%" align="center"></th>
                        <th>Nama</th>
                        <th>Domain</th>
                        <th>OPD</th>
                        <th>Deskripsi</th>
                        <th>Klaster</th>
                        <th>Status</th>
                        <th>Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.show.map((value, index) => {
                        return (
                          <tr key={index} className="issp-table">
                            <th></th>
                            <td>{value.name}</td>
                            <td>{value.domain}</td>
                            <td>{value.opdId.name}</td>
                            <td>{value.description}</td>
                            <td>
                              {value.cluster.map((el, idx) => {
                                return (
                                  <div key={idx} className={"m-1 text-nowrap badge-public public"}>
                                    {el.name}
                                  </div>
                                );
                              })}
                            </td>
                            <td>
                              <div className={"text-nowrap badge-public" + (value.isPublic ? " public" : "")}>{value.isPublic ? "Publik" : "Tidak Publik"}</div>
                            </td>
                            <td className="d-flex">
                              <div className="dropdown">
                                <button className="btn btn-table rounded-circle" data-toggle="dropdown">
                                  <MoreHorizontal />
                                </button>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link to={"/aplikasi/" + value._id} className="dropdown-item w-100" type="button">
                                    <span className="dropdown-item-icon">
                                      <Eye />
                                    </span>
                                    Lihat Detail
                                  </Link>
                                  <AccessController name="Aplikasi" access="delete">
                                    <button className="dropdown-item w-100" type="button" index={index} onClick={this.handleDelete.bind(this, value)}>
                                      <span className="dropdown-item-icon">
                                        <Trash2 />
                                      </span>
                                      Hapus Aplikasi
                                    </button>
                                  </AccessController>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal" id="modalCreate" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Tambah Aplikasi</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label className="text-dark mb-1">Nama</label>
                  <input value={this.state.modal.name} onChange={this.handleChange} name="name" className="form-control py-3" type="text" placeholder="Masukkan nama aplikasi" />
                  <div className="invalid-feedback"></div>
                </div>
                <div className="form-group">
                  <label className="text-dark mb-1">Domain</label>
                  <div className="input-group">
                    {/* <div className="input-group-prepend">
                      <div className="input-group-text">http://</div>
                    </div> */}
                    <input value={this.state.modal.domain} onChange={this.handleChange} name="domain" className="form-control py-3" type="text" placeholder="Masukkan url aplikasi" />
                  </div>
                </div>
                <div className="form-group">
                  <label className="text-dark mb-1">Deskripsi</label>
                  <input value={this.state.modal.description} onChange={this.handleChange} name="description" className="form-control py-3" type="text" placeholder="Masukkan deskripsi aplikasi" />
                  <div className="invalid-feedback"></div>
                </div>
                <div className="form-group">
                  <label className="text-dark mb-1">Organisasi</label>
                  <select value={this.state.modal.opdId} onChange={this.handleChange} name="opdId" className="form-control">
                    <option value="" disabled>
                      -- Pilih Organisasi --
                    </option>
                    {this.state.opd.map((value, index) => {
                      return (
                        <option value={value._id} key={index}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label className="text-dark mb-1">Klaster</label>
                  <Typeahead id="cluster-typeahead" placeholder="Pilih cluster" options={this.state.cluster} selected={this.state.modal.cluster} onChange={this.handelClusterChange} labelKey="name" multiple />
                </div>
                <div className="form-group">
                  <label className="text-dark mb-1">Tampilan Aplikasi (Screenshot)</label>
                  <div className="custom-file">
                    <input onChange={this.handleChange} type="file" name="file" className="custom-file-input" id="thumbnail" accept="image/*" />
                    <label className="custom-file-label" htmlFor="thumbnail" data-browse="Pilih File">
                      {this.state.modal.file.length > 0 ? this.state.modal.file[0].name : "Gambar Belum Dipilih"}
                    </label>
                  </div>
                </div>
                <div className="form-group mb-1">
                  <label className="text-dark mb-1">Status Aplikasi</label>
                </div>
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <input checked={this.state.modal.isPublic === "public"} onChange={this.handleChange} className="form-check-input" type="radio" name="isPublic" id="isPublic1" value="public" />
                    <label className="form-check-label" htmlFor="isPublic1">
                      Publik (Umum)
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input checked={this.state.modal.isPublic === "not"} onChange={this.handleChange} className="form-check-input" type="radio" name="isPublic" id="isPublic2" value="not" />
                    <label className="form-check-label" htmlFor="isPublic2">
                      Tidak Publik
                    </label>
                  </div>
                </div>
                <div className="form-group mb-1">
                    <label className="text-dark mb-1">Status OTP</label>
                </div>
                <div className="form-group mb-0">
                    <div className="form-check form-check-inline">
                        <input checked={this.state.modal.otpSetting === "yes"} onChange={this.handleChange} className="form-check-input" type="radio" name="otpSetting" id="otpSetting1" value="yes" />
                        <label className="form-check-label" htmlFor="otpSetting1">Aktif</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input checked={this.state.modal.otpSetting === "no"} onChange={this.handleChange} className="form-check-input" type="radio" name="otpSetting" id="otpSetting2" value="no" />
                        <label className="form-check-label" htmlFor="otpSetting2">Non Aktif</label>
                    </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline-success" data-dismiss="modal">
                  Batal
                </button>
                <button type="button" className="btn btn-success" onClick={this.handleCreate}>
                  Tambah
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal" id="modal-csv" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Import Aplikasi</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {this.state.isDropzone ? (
                this.state.importAplikasi.length > 0 ? (
                  <div className="modal-body">
                    Ditemukan <strong>{this.state.importAplikasi.length} data valid</strong> dari CSV yang diunggah. Lakukan import Aplikasi?
                  </div>
                ) : (
                  <div className="modal-body">
                    <div className="alert alert-warning" role="alert">
                      Untuk dapat melakukan import Aplikasi, gunakan template CSV yang dapat Anda{" "}
                      <a href={Template} download="Import-Aplikasi-Template.csv" className="alert-link">
                        unduh disini
                      </a>
                      . Mohon menjadi perhatian bahwa username wajib untuk diisi, serta role yang terisi harus sudah ditambahkan terlebih dahulu ke dalam daftar role.
                    </div>
                    <div className="form-group">
                      <Dropzone onDropAccepted={this.parseAplikasi} accept=".csv">
                        {({ getRootProps, getInputProps }) => (
                          <section className="media-container">
                            <div {...getRootProps({ className: "media-dropzone" })}>
                              <input {...getInputProps()} />
                              <p className="text-center">Taruh atau Klik Disini untuk Mengunggah CSV</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                )
              ) : null}
              {this.state.importAplikasi.length > 0 && (
                <div className="modal-footer">
                  <button type="button" className="btn btn-outline-success" data-dismiss="modal">
                    Batal
                  </button>
                  <button onClick={this.import} type="button" className="btn btn-success">
                    Import
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="modal" id="modal-opd" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Filter Aplikasi</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label className="text-dark mb-1">Pilih OPD</label>
                  <select value={this.state.filter.opd} onChange={this.handleFilter} name="opd" className="form-control">
                    <option value="">Semua OPD</option>
                    {this.state.opd.map((value, index) => {
                      return (
                        <option value={value._id} key={index}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal" id="modal-cluster" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Filter Aplikasi</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label className="text-dark mb-1">Pilih Klaster</label>
                  <select value={this.state.filter.cluster} onChange={this.handleFilter} name="cluster" className="form-control">
                    <option value="">Semua Klaster</option>
                    {this.state.cluster.map((value, index) => {
                      return (
                        <option value={value._id} key={index}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AccessController>
    );
  }
}

export default Application;
