import fetchNow from './_Fetch.jsx';

const ClassAPI = {
    create: (body) => {
        return fetchNow('/api/acl', 'POST', body);
    },
    update: (body, id) => {
        return fetchNow('/api/acl/' + id, 'PUT', body, 'JSON');
    },
    delete: (id) => {
        return fetchNow('/api/acl/' + id, 'DELETE');
    }
}
 
export default ClassAPI;