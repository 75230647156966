import fetchNow from './_Fetch.jsx';

const ApplicationAPI = {
    get: () => {
        return fetchNow('/api/applist/all', 'GET');
    },
    getById: (id) => {
        return fetchNow('/api/applist/all/' + id, 'GET');
    },
    getMin: () => {
        return fetchNow('/api/applist', 'GET');
    },

    create: (body) => {
        return fetchNow('/api/applist', 'POST', body, 'formurl');
    },

    update: (body, id) => {
        return fetchNow('/api/applist/' + id, 'PUT', body, 'formurl');
    },

    delete: (id) => {
        return fetchNow('/api/applist/' + id, 'DELETE');
    }
}
 
export default ApplicationAPI;