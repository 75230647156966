import React, { Component } from "react";
import { Briefcase, Edit, Monitor, MoreHorizontal, Trash2, Upload, Plus } from "react-feather";
import { Helmet } from "react-helmet";
import $ from "jquery";
import { toast } from "react-toastify";
import { readString } from "react-papaparse";
import Dropzone from "react-dropzone";

import OpdAPI from "../../../api/Opd";

import AccessController from "../../../component/AccessController";
import Loading from "../../../component/Loading";
import Template from "../../../asset/other/Import-Cluster-Template.csv";

toast.configure();

class Opd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isDropzone: false,

      importOrganisasi: [],
      data: [],
      modal: {
        id: "",
        name: "",
        detail: "",
      },
    };

    this.fetchNow = this.fetchNow.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.modalCreate = this.modalCreate.bind(this);
    this.handleCreate = this.handleCreate.bind(this);

    this.modalCSV = this.modalCSV.bind(this);
    this.parseOrganisasi = this.parseOrganisasi.bind(this);
    this.import = this.import.bind(this);

    this.modalUpdate = this.modalUpdate.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);

    this.handleDelete = this.handleDelete.bind(this);
  }

  fetchNow() {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        OpdAPI.get().then((data) => {
          /* Assign Variable */
          let opd = [];

          /* If Request Success */
          if (data.status === 200) {
            opd = data.data;
          }

          /* Remove Datatable Before */
          if ($.fn.dataTable.isDataTable("#opd-table")) {
            $("#opd-table").DataTable().destroy();
          }

          this.setState(
            {
              isLoading: false,
              data: opd,
            },
            () => {
              /* New Datatable Configuration */
              var table = $("#opd-table").DataTable({
                columnDefs: [
                  {
                    searchable: false,
                    orderable: false,
                    targets: 0,
                  },
                ],
                order: [[1, "asc"]],
                language: {
                  lengthMenu: "Lihat _MENU_ data",
                  zeroRecords: "Tidak ada data yang tersedia",
                  info: "Data _START_ - _END_ dari total _TOTAL_ data",
                  infoEmpty: "Menampilkan 0 data",
                  search: "Cari",
                  paginate: {
                    previous: "Sebelumnya",
                    next: "Selanjutnya",
                  },
                },
              });

              table
                .on("order.dt search.dt", function () {
                  table
                    .column(0, { search: "applied", order: "applied" })
                    .nodes()
                    .each(function (cell, i) {
                      cell.innerHTML = i + 1;
                    });
                })
                .draw();
            }
          );
        });
      }
    );
  }

  handleChange(event) {
    /* Store Variable */
    let modal = this.state.modal;

    /* Change Value */
    modal[event.target.name] = event.target.value;

    /* Update Data */
    this.setState({
      modal,
    });
  }

  modalCreate() {
    /* Reset Modal */
    this.setState(
      {
        modal: {
          id: "",
          name: "",
          detail: "",
        },
      },
      () => {
        /* Show Modal */
        $("#modalCreate").modal("show");
      }
    );
  }

  handleCreate(event) {
    event.preventDefault();

    this.setState(
      {
        isLoading: true,
      },
      () => {
        /* Store Result */
        const body = new URLSearchParams({
          name: this.state.modal.name,
          detail: this.state.modal.detail,
        });

        /* Request Create OPD */
        OpdAPI.create(body).then((data) => {
          /* Hide Modal */
          $("#modalCreate").modal("hide");

          if (data.status === 200) {
            /* Handle Success */
            this.fetchNow();

            toast.success("Organisasi Baru Berhasil Ditambahkan", {
              position: toast.POSITION.TOP_CENTER,
              theme: 'colored'
            });
          } else {
            /* Handle Failed */
            this.setState(
              {
                isLoading: false,
              },
              () => {
                toast.error("Penambahan OPD Gagal, Silahkan Coba Beberapa Saat Lagi", {
                  position: toast.POSITION.TOP_CENTER,
                });
              }
            );
          }
        });
      }
    );
  }

  modalCSV() {
    this.setState(
      {
        importOrganisasi: [],
        isDropzone: true,
      },
      () => {
        $("#modal-csv").modal("show");
      }
    );
  }

  parseOrganisasi(file) {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        /* Assign File Reader */
        let reader = new FileReader();

        /* Read Uploaded File */
        reader.readAsText(file[0]);

        /* Parse File */
        reader.onload = () => {
          /* Assign Variable and Store Data */
          let data = readString(reader.result, { header: true, skipEmptyLines: true }).data;
          let isError = false,
            importOrganisasi = [];

          /* Process if Data Not Empty */
          if (data.length > 0) {
            /* Process if CSV Format is Correct */
            if (data[0].hasOwnProperty("name")) {
              /* Loop All Data */
              for (let value of data) {
                /* Add if Username is Not Empty */
                if (value.name !== null && value.name !== "") {
                  /* Store Value */
                  let organisasi = {
                    name: value.name,
                    detail: value.detail ?? "",
                  };

                  /* Add to Import List */
                  importOrganisasi.push(organisasi);
                }
              }
            } else {
              /* CSV Format Wrong */
              isError = true;
            }
          }

          /* Update */
          this.setState(
            {
              isLoading: false,
              importOrganisasi,
            },
            () => {
              if (importOrganisasi.length <= 0) {
                if (isError) {
                  /* Wrong Format Alert */
                  toast.error(`CSV yang Diunggah Tidak Sesuai Format`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                  });
                } else {
                  /* Empty Data Alert */
                  toast.error(`Tidak Ada Data yang Valid dalam CSV`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                  });
                }
              }
            }
          );
        };
      }
    );
  }

  import() {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        for (let i = 0; i < this.state.importOrganisasi.length; i++) {
          let organisasi = this.state.importOrganisasi[i];
          const body = new URLSearchParams({
            name: organisasi.name,
            detail: organisasi.detail ?? "",
          });

          OpdAPI.create(body).then((result) => {
            if (result.status === 200) {
              /* Handle Success */
              this.fetchNow();

              $("#modal-csv").modal("hide");
            } else {
              /* Handle Error */
              this.setState(
                {
                  isLoading: false,
                },
                () => {
                  $("#modal-csv").modal("hide");

                  toast.error("Import Organisasi Gagal, Silahkan Coba Beberapa Saat Lagi", {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                  });
                }
              );
            }
          });
        }
        if (this.state.importOrganisasi.length > 4) {
          toast.success("Import Organisasi sedang Dilakukan, Silahkan Tunggu Beberapa Saat dan Muat Ulang Halaman ini", {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            autoClose: 5000,
          });
        } else {
          toast.success("Import Organisasi Berhasil", {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
          });
        }
      }
    );
  }

  modalUpdate(event) {
    /* Store Target Data */
    let data = this.state.data[event.currentTarget.getAttribute("index")];

    /* Update Modal */
    this.setState(
      {
        modal: {
          id: data._id,
          name: data.name,
          detail: data.detail,
        },
      },
      () => {
        /* Show Modal */
        $("#modalUpdate").modal("show");
      }
    );
  }

  handleUpdate(event) {
    event.preventDefault();

    this.setState(
      {
        isLoading: true,
      },
      () => {
        /* Store Result */
        const body = new URLSearchParams({
          name: this.state.modal.name,
          detail: this.state.modal.detail,
        });

        /* Request Update OPD */
        OpdAPI.update(body, this.state.modal.id).then((data) => {
          /* Hide Modal */
          $("#modalUpdate").modal("hide");

          if (data.status === 200) {
            /* Handle Success */
            this.fetchNow();

            toast.success("Berhasil Update Organisasi", {
              position: toast.POSITION.TOP_CENTER,
              theme: 'colored'
          });
          } else {
            /* Handle Failed */
            this.setState(
              {
                isLoading: false,
              },
              () => {
                toast.error("Perubahan OPD Gagal, Silahkan Coba Beberapa Saat Lagi", {
                  position: toast.POSITION.TOP_CENTER,
                });
              }
            );
          }
        });
      }
    );
  }

  handleDelete(event) {
    /* Store Target Data */
    let value = this.state.data[event.currentTarget.getAttribute("index")];

    event.stopPropagation();
    if(window.confirm('Are sure want to delete?')) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          /* Request Delete OPD */
          OpdAPI.delete(value._id).then((data) => {
            if (data.status === 200) {
              /* Handle Success */
              this.fetchNow();

              toast.warn("OPD Berhasil Dihapus", {
                position: toast.POSITION.TOP_CENTER,
                theme: 'colored'
              });
            } else {
              /* Handle Failed */
              this.setState(
                {
                  isLoading: false,
                },
                () => {
                  toast.error("Hapus OPD Gagal, Silahkan Coba Beberapa Saat Lagi", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                }
              );
            }
          });
        }
      );
    }
  }

  componentDidMount() {
    this.fetchNow();
  }

  checkAll() {
    let disabled = false;

    if (this.state.modal.name === "") {
        disabled = true;
    } 

    return disabled;
  }

  render() {
    return (
      <AccessController name="OPD" access="read" type="page">
        <Loading show={this.state.isLoading} />
        <Helmet title="OPD - Manajemen SSO" />
        <div className="row d-flex align-items-center m-0 mt-2 mb-4">
          <div className="col-lg col-12 text-center text-lg-left">
            <h1 className="mb-0">Daftar Organisasi</h1>
          </div>
          <AccessController name="OPD" access="create">
            <div className="dropdown">
              <button className="btn btn-sm btn-primary py-2 px-3 dropdown-toggle" data-toggle="dropdown">
                <Briefcase size={18} className="icon-btn mr-2" />
                <span className="mb-n1 mr-1">Tambah Organisasi</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button onClick={this.modalCreate} className="dropdown-item w-100" type="button">
                  <span className="dropdown-item-icon">
                    <Plus className="mt-n1" />
                  </span>
                  Tambah Baru
                </button>
                <button onClick={this.modalCSV} className="dropdown-item w-100" type="button">
                  <span className="dropdown-item-icon">
                    <Upload />
                  </span>
                  Import CSV
                </button>
              </div>
            </div>
          </AccessController>
        </div>
        <div className="row m-0 mb-5">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="datatable">
                  <table className="table table-striped table-hover" id="opd-table">
                    <thead>
                      <tr>
                        <th width="5%" align="center"></th>
                        <th>Nama</th>
                        <th>Deskripsi</th>
                        <th>Jumlah Aplikasi</th>
                        <th>Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((value, index) => {
                        return (
                          <tr key={index} className="issp-table">
                            <th></th>
                            <td>{value.name}</td>
                            <td>{value.detail}</td>
                            <td>
                              <Monitor size={16} className="mr-2" />
                              {value.apps.length + " Aplikasi"}
                            </td>
                            <td className="d-flex">
                              <div className="dropdown">
                                <button className="btn btn-table rounded-circle" data-toggle="dropdown" data-display="static">
                                  <MoreHorizontal />
                                </button>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <AccessController name="OPD" access="update">
                                    <button className="dropdown-item w-100" type="button" index={index} onClick={this.modalUpdate}>
                                      <span className="dropdown-item-icon">
                                        <Edit />
                                      </span>
                                      Ubah Data Organisasi
                                    </button>
                                  </AccessController>
                                  <AccessController name="OPD" access="delete">
                                    <button className="dropdown-item w-100" type="button" index={index} onClick={this.handleDelete}>
                                      <span className="dropdown-item-icon">
                                        <Trash2 />
                                      </span>
                                      Hapus Organisasi
                                    </button>
                                  </AccessController>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal" id="modalCreate" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Tambah Organisasi Baru</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.handleCreate}>
                <div className="modal-body">
                  <div className="form-group">
                    <label className="text-dark mb-1">Nama</label>
                    <input value={this.state.modal.name} onChange={this.handleChange} name="name" className="form-control py-3" type="text" placeholder="Masukkan akronim/singkatan dari nama Organisasi" />
                    <div className="invalid-feedback"></div>
                  </div>
                  <div className="form-group">
                    <label className="text-dark mb-1">Deskripsi</label>
                    <input value={this.state.modal.detail} onChange={this.handleChange} name="detail" className="form-control py-3" type="text" placeholder="Masukkan deskripsi Organisasi" />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-outline-primary" data-dismiss="modal">
                    Batal
                  </button>
                  <button type="submit" className="btn btn-primary" disabled={this.checkAll()}>
                    Tambah
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="modal" id="modal-csv" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Import Organisasi</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {this.state.isDropzone ? (
                this.state.importOrganisasi.length > 0 ? (
                  <div className="modal-body">
                    Ditemukan <strong>{this.state.importOrganisasi.length} data valid</strong> dari CSV yang diunggah. Lakukan import Organisasi?
                  </div>
                ) : (
                  <div className="modal-body">
                    <div className="alert alert-warning" role="alert">
                      Untuk dapat melakukan import Organisasi, gunakan template CSV yang dapat Anda{" "}
                      <a href={Template} download="Import-Organisasi-Template.csv" className="alert-link">
                        unduh disini
                      </a>
                      . Mohon menjadi perhatian bahwa username wajib untuk diisi, serta role yang terisi harus sudah ditambahkan terlebih dahulu ke dalam daftar role.
                    </div>
                    <div className="form-group">
                      <Dropzone onDropAccepted={this.parseOrganisasi} accept=".csv">
                        {({ getRootProps, getInputProps }) => (
                          <section className="media-container">
                            <div {...getRootProps({ className: "media-dropzone" })}>
                              <input {...getInputProps()} />
                              <p className="text-center">Taruh atau Klik Disini untuk Mengunggah CSV</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                )
              ) : null}
              {this.state.importOrganisasi.length > 0 && (
                <div className="modal-footer">
                  <button type="button" className="btn btn-outline-success" data-dismiss="modal">
                    Batal
                  </button>
                  <button onClick={this.import} type="button" className="btn btn-success">
                    Import
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="modal" id="modalUpdate" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Ubah Organisasi</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.handleUpdate}>
                <div className="modal-body">
                  <div className="form-group">
                    <label className="text-dark mb-1">Nama</label>
                    <input value={this.state.modal.name} onChange={this.handleChange} name="name" className="form-control py-3" type="text" placeholder="Masukkan nama OPD" />
                    <div className="invalid-feedback"></div>
                  </div>
                  <div className="form-group">
                    <label className="text-dark mb-1">Deskripsi</label>
                    <input value={this.state.modal.detail} onChange={this.handleChange} name="detail" className="form-control py-3" type="text" placeholder="Masukkan deskripsi OPD" />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-outline-primary" data-dismiss="modal">
                    Batal
                  </button>
                  <button type="submit" className="btn btn-primary" disabled={this.checkAll()}>
                    Simpan
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AccessController>
    );
  }
}

export default Opd;
