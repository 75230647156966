import React, { Component } from 'react';
import AuthAPI from '../api/Auth';
import { RootContext } from './Context';

class Login extends Component {
    static contextType = RootContext;

    constructor(props) {
        super(props);

        this.state = {
            code: new URLSearchParams(this.props.location.search).get('sso_code')
        }
    }
    
    componentDidMount() {
        /* Save Token */
        if(this.state.code) {
            AuthAPI.getToken(this.state.code).then((result) => {
                localStorage.setItem('token', result.access_token);
                localStorage.setItem('refresh_token', result.refresh_token);
                window.location.replace(this.context.get.baseUrl);
            })
        }
    }

    render() { 
        if(this.state.code) {
            return null;
        }else{
            return (
                <div className="login-box">
                    <div className="login-body text-center">
                        <h1 className="login-title text-danger">ISSP</h1>
                        <h3 className="login subtitle text-uppercase">SSO Login system</h3>
                        <p className="text-muted mt-1 mb-4">Integrated Smart System Platform</p>
                        <a href={`${process.env.REACT_APP_SSO}/login?app_id=${this.context.get.appId}&redirect_uri=${encodeURI(this.context.get.baseUrl)}/login`} className="btn btn-danger btn-rounded mb-5 text-white">Login</a>
                    </div>
                </div>
            );
        }
    }
}
 
export default Login;