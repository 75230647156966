import fetchNow from './_Fetch.jsx';

const StatisticAPI = {
    get: () => {
        return fetchNow('/stat', 'GET');
    },
    getDaily: () => {
        return fetchNow('/stat/daily', 'GET');
    },
    getStatistic: () => {
        return fetchNow('/stat/app', 'GET');
    },
    getByOPD: (id) => {
        return fetchNow('/stat/opd2/' + id, 'GET');
    },
    getByCluster: (id) => {
        return fetchNow('/stat/cluster/' + id, 'GET');
    }
}

export default StatisticAPI;