import React, { Component } from 'react';
import { Eye, Monitor, MoreHorizontal, Trash2, UserPlus } from 'react-feather';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import $ from 'jquery';

import UserAPI from '../../../api/User';

import AccessController from '../../../component/AccessController';
import Loading from '../../../component/Loading';

toast.configure();

class User extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,

            data: [],

            modal: {
                name: '',
                email: '',
                username: '',
                password: ''
            }
        }

        this.fetchNow = this.fetchNow.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.modalCreate = this.modalCreate.bind(this);
        this.create = this.create.bind(this);

        this.delete = this.delete.bind(this);
    }

    /* === Core Process ========================================================= */

    fetchNow() {
        this.setState({
            isLoading: true
        }, () => {
            UserAPI.get().then((result) => {
                /* Assign Variable */
                let data = [];

                /* Store Data if Success */
                if(result.status === 200) {
                    data = result.data;
                }

                /* Remove Existing Datatable */
                if($.fn.dataTable.isDataTable('#user-table')){
                    $('#user-table').DataTable().destroy();
                }

                /* Update */
                this.setState({
                    isLoading: false,
                    data
                }, () => {
                    /* Initialize New Datatable */
                    var table = $('#user-table').DataTable({
                        columnDefs: [ {
                            searchable: false,
                            orderable: false,
                            targets: 0
                        } ],
                        order: [[ 1, 'asc' ]],
                        language: {
                            lengthMenu: "Lihat _MENU_ data",
                            zeroRecords: "Tidak ada data yang tersedia",
                            info: "Data _START_ - _END_ dari total _TOTAL_ data",
                            infoEmpty: "Menampilkan 0 data",
                            search: "Cari",
                            paginate: {
                                previous: "Sebelumnya",
                                next: "Selanjutnya"
                            }
                        }
                    });

                    table.on('order.dt search.dt', function () {
                        table.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
                            cell.innerHTML = i+1;
                        });
                    }).draw();
                })
            })
        })
    }
    
    /* === Handle User Input ==================================================== */

    handleChange(event) {
        let modal = this.state.modal;

        modal[event.target.name] = event.target.value;

        this.setState({
            modal
        })
    }
    
    /* === Create User ========================================================== */

    modalCreate() {
        this.setState({
            modal: {
                name: '',
                email: '',
                username: '',
                password: 'password'
            }
        }, () => { $('#modal-create').modal('show') })
    }

    create(event) {
        event.preventDefault();

        this.setState({
            isLoading: true
        }, () => {
            /* Store Request Body */
            let result = {
                name: this.state.modal.name,
                email: this.state.modal.email,
                username: this.state.modal.username,
                password: this.state.modal.password,
            }

            /* Request Create */
            UserAPI.create(result).then((data) => {
                if(data.status === 200) {
                    /* Get Data */
                    setTimeout(() => {
                        this.fetchNow()

                        toast.success("Pengguna Baru Berhasil Ditambahkan", {
                            position: toast.POSITION.TOP_CENTER,
                            theme: 'colored'
                        });

                        $('#modal-create').modal('hide');
                    }, 3000);
                }else{
                    /* Handle Error */
                    this.setState({
                        isLoading: false
                    }, () => {
                        toast.error("Tambah Pengguna Gagal, Silahkan Coba Beberapa Saat Lagi (Hint: Username/Email sudah terdaftar)", {
                            position: toast.POSITION.TOP_CENTER
                        });

                        $('#modal-create').modal('hide');
                    })
                }
            })
        })
    }

    /* === Delete User ========================================================== */

    delete(event) {
        let id = event.currentTarget.getAttribute('data-id');
        
        event.stopPropagation();
        if(window.confirm('Are sure want to delete?')) {
            this.setState({
                isLoading: true
            }, () => {
                UserAPI.delete(id).then((data) => {
                    if(data.status === 200) {
                        /* Get Data */
                        this.fetchNow()
        
                        toast.warn("Pengguna Berhasil Dihapus", {
                            position: toast.POSITION.TOP_CENTER,
                            theme: 'colored'
                        });
                    }else{
                        /* Handle Error */
                        this.setState({
                            isLoading: false
                        }, () => {
                            toast.error("Hapus Pengguna Gagal, Pastikan Pengguna Sudah Tidak Memiliki Akses ke Aplikasi Apapun", {
                                position: toast.POSITION.TOP_CENTER
                            });
                        })
                    }
                })
            })
        }
        
    }

    componentDidMount() {
        this.fetchNow();
    }

    checkAll() {
        let disabled = false;

        for (let value of Object.keys(this.state.modal)) {
            if (this.state.modal[value] === "") {
            disabled = true;
            }
        }

        return disabled;
    }

    render() { 
        return (
            <AccessController name="Pengguna" access="read" type="page">
                <Helmet title="Pengguna - Manajemen SSO" />
                <Loading show={this.state.isLoading} />

                <div className="row d-flex align-items-center m-0 mt-2 mb-4">
                    <div className="col-lg col-12 text-center text-lg-left">
                        <h1 className="mb-0">Pengguna</h1>
                    </div>
                    <AccessController name="Pengguna" access="create">
                        <div className="col-lg-auto col-12 d-flex justify-content-center mt-2 mt-lg-0">
                            <button className="btn btn-primary btn-issp" onClick={this.modalCreate}>
                                <UserPlus size={18} className="icon-btn"/>
                                <span>Tambah Pengguna</span>
                            </button>
                        </div>
                    </AccessController>
                </div>

                <div className="row m-0 mb-5">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="datatable">
                                    <table className="table table-striped table-hover" id="user-table">
                                        <thead>
                                            <tr>
                                                <th width="5%" align="center"></th>
                                                <th>Nama</th>
                                                <th>Username</th>
                                                <th>Jumlah Aplikasi</th>
                                                <th>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.data.map((value, index) => {
                                                    return (
                                                        <tr key={index} className="issp-table">
                                                            <th></th>
                                                            <td>{value.name}</td>
                                                            <td>{value.username}</td>
                                                            <td><Monitor size={16} className="mr-1" />{value.apps.length} Aplikasi</td>
                                                            <td className="d-flex">
                                                                <div className="dropdown">
                                                                    <button className="btn btn-table rounded-circle" data-toggle="dropdown">
                                                                        <MoreHorizontal />
                                                                    </button>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <Link to={"/pengguna/" + value._id} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><Eye /></span>Lihat Detail</Link>
                                                                        <AccessController name="Pengguna" access="delete">
                                                                            <button onClick={this.delete} data-id={value._id} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><Trash2 /></span>Hapus Pengguna</button>
                                                                        </AccessController>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="modal-create" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Tambah Pengguna</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form onSubmit={this.create}>
                                <div className="modal-body">
                                    {/* <div className="form-group">
                                        <label className="text-dark mb-1">NIP</label>
                                        <input onChange={this.handleChange} value={this.state.modal.nip} name="nip" className="form-control py-3" type="tel" placeholder="Masukkan NIP" pattern="^[0-9]*$"/>
                                    </div> */}
                                    <div className="form-group">
                                        <label className="text-dark mb-1">Nama</label>
                                        <input onChange={this.handleChange} value={this.state.modal.name} name="name" className="form-control py-3" type="text" placeholder="Masukkan nama lengkap" />
                                    </div>
                                    <div className="form-group">
                                        <label className="text-dark mb-1">Username</label>
                                        <input onChange={this.handleChange} value={this.state.modal.username} name="username" className="form-control py-3" type="text" placeholder="Masukkan username" />
                                    </div>
                                    <div className="form-group">
                                        <label className="text-dark mb-1">Email</label>
                                        <input onChange={this.handleChange} value={this.state.modal.email} name="email" className="form-control py-3" type="email" placeholder="Masukkan email" />
                                    </div>
                                    {/* <div className="form-group">
                                        <label className="text-dark mb-1">Telepon</label>
                                        <input onChange={this.handleChange} value={this.state.modal.phone} name="phone" className="form-control py-3" type="tel" placeholder="Masukkan telepon (628xxxx)" pattern="^[0-9]*$"/>
                                    </div> */}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-success" data-dismiss="modal">Batal</button>
                                    <button type="submit" className="btn btn-success" disabled={this.checkAll()}>Tambah</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </AccessController>
        );
    }
}
 
export default User;