import React, { Component } from 'react';
import { Briefcase, Key, MoreHorizontal, Shield, Trash2, User } from 'react-feather';
import { Helmet } from 'react-helmet';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import $ from 'jquery';
import 'bootstrap';

import ApplicationAPI from '../../../api/Application';
import OpdAPI from '../../../api/Opd';
import UserAPI from '../../../api/User';

import AccessController from '../../../component/AccessController';
import { RootContext } from '../../../component/Context';
import Loading from '../../../component/Loading';

const DeepClone = require('rfdc')();
toast.configure();

class Admin extends Component {
    static contextType = RootContext;

    constructor(props) {
        super(props);

        this.state = {
            /* State */
            isLoading: false,
            isNewUser: false,
            isNext: false,
            isChoose: false,
            isCheckAll: false,

            /* Core Data */
            app: {
                id: '',
                uid: '',
                roles: [],
                class: []
            },
            opd: [],
            user: [],

            /* Admin List */
            superAdmin: [],
            opdAdmin: [],

            /* Modal Data */
            modal: {
                id: '',
                userId: [],
                roles: '',
                class: []
            },
            modalUser: {
                name: "",
                email: "",
                username: "",
                phone: "",
                nip: "",
                opdId: ""
            },

            /* Role Admin Id */
            admin: {
                super: '',
                opd: ''
            }
        }

        this.step = this.step.bind(this);
        this.fetchNow = this.fetchNow.bind(this);

        this.handleUserChoice = this.handleUserChoice.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);

        this.modalAdd = this.modalAdd.bind(this);
        this.add = this.add.bind(this);

        this.modalUpdate = this.modalUpdate.bind(this);
        this.update = this.update.bind(this);

        this.modalSwitch = this.modalSwitch.bind(this);
        this.switchRole = this.switchRole.bind(this);

        this.modalOPD = this.modalOPD.bind(this);
        this.changeOPD = this.changeOPD.bind(this);

        this.remove = this.remove.bind(this);

        this.handleTab = this.handleTab.bind(this);

        this.checkboxStatus = this.checkboxStatus.bind(this);
        this.handleCheckAll = this.handleCheckAll.bind(this);
    }

    /* === Core Process ========================================================= */

    findById(id, data) {
        for(let value of data) {
            if(value._id === id) {
                return value;
            }
        }
    }

    compare(a, b) {
        /* Sorting Compare */
        if(a.name < b.name){
            return -1;
        }else if(a.name > b.name){
            return 1;
        }else{
            return 0;
        }
    }

    step(event) {
        /* Next or Prev Step */
        this.setState({
            isNext: event.currentTarget.getAttribute('data-value') === 'next' ? true : false
        })
    }

    fetchNow() {
        this.setState({
            isLoading: true
        }, () => {
            ApplicationAPI.getById(this.context.get.appId).then((dataApps) => {
                UserAPI.getByApps(this.context.get.appId).then((dataAdmin) => {
                    UserAPI.get().then((dataUser) => {
                        OpdAPI.getAll().then((dataOpd) => {
                            let superAdmin = [], opdAdmin = [], opd = [], user = [];
                            let app = this.state.app, admin = this.state.admin;
    
                            if(dataApps.status === 200 && dataUser.status === 200 && dataAdmin.status === 200 && dataOpd.status === 200) {
                                /* Store All OPD Data */
                                opd = dataOpd.data;

                                /* Store Application Data */
                                app = {
                                    id: dataApps.data._id,
                                    uid: dataApps.data.uid,
                                    roles: dataApps.data.roles,
                                    class: dataApps.data.class
                                };

                                /* Store Admin Roles Id */
                                for(let value of dataApps.data.roles) {
                                    if(value.name === 'Super Admin') {
                                        admin.super = value._id;
                                    }else if(value.name === 'Admin OPD') {
                                        admin.opd = value._id;
                                    }
                                }

                                /* Store Temporary Admin List */
                                let temp = DeepClone(dataAdmin.data);

                                /* Store User that isn't Admin */
                                loop: for(let value of dataUser.data) {
                                    /* Check User that Admin */
                                    for(let i=0 ; i<temp.length ; i++) {
                                        if(value._id === temp[i]._id) {
                                            temp.splice(i, 1);
                                            continue loop;
                                        }
                                    }

                                    /* Store if Not Admin */
                                    if(value.name) {
                                        user.push(value);
                                    }
                                }

                                user.sort(this.compare);
    
                                /* Split Admin by Role */
                                for(let value of dataAdmin.data) {
                                    if(value.roles.length > 0) {
                                        if(value.roles[0]._id === admin.super) {
                                            superAdmin.push(value);
                                        }else{
                                            opdAdmin.push(value);
                                        }
                                    }
                                }
                            }

                            /* Remove Existing Datatable */
                            if($.fn.dataTable.isDataTable('#super-admin-table')){
                                $('#super-admin-table').DataTable().destroy();
                            }

                            if($.fn.dataTable.isDataTable('#opd-admin-table')){
                                $('#opd-admin-table').DataTable().destroy();
                            }

                            this.setState({
                                isLoading: false,
                                superAdmin, opdAdmin,
                                app, opd, user, admin
                            }, () => {
                                let config = {
                                    columnDefs: [ {
                                        searchable: false,
                                        orderable: false,
                                        targets: 0
                                    } ],
                                    order: [[ 1, 'asc' ]],
                                    language: {
                                        lengthMenu: "Lihat _MENU_ data",
                                        zeroRecords: "Tidak ada data yang tersedia",
                                        info: "Data _START_ - _END_ dari total _TOTAL_ data",
                                        infoEmpty: "Menampilkan 0 data",
                                        search: "Cari",
                                        paginate: {
                                            previous: "Sebelumnya",
                                            next: "Selanjutnya"
                                        }
                                    }
                                };

                                /* Initialize New Datatable */
                                var table = $('#super-admin-table').DataTable(config);
                                var table2 = $('#opd-admin-table').DataTable(config);
            
                                table.on('order.dt search.dt', function () {
                                    table.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
                                        cell.innerHTML = i+1;
                                    });
                                }).draw();

                                table2.on('order.dt search.dt', function () {
                                    table2.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
                                        cell.innerHTML = i+1;
                                    });
                                }).draw();
                            })
                        })
                    })
                })
            })
        })
    }

    /* === User Input =========================================================== */

    handleUserChoice(selection) {
        let modal = this.state.modal;
        let user = this.state.modalUser;
        let isNewUser = false;

        if(selection.length > 0) {
            if(selection[0].customOption){
                user.name = selection[0].name;
                user.email = '';
                user.username = '';
                user.phone = '';
                user.nip = '';

                isNewUser = true;
            }else{
                user.name = '';
                user.email = '';
                user.username = '';
                user.phone = '';
                user.nip = '';
            }
        }

        modal.userId = selection;

        this.setState({
            modalUser: user,
            modal, isNewUser
        })
    }

    handleChange(event) {
        let target = event.target.getAttribute('data-type');
        let data = this.state[target];

        data[event.target.name] = event.target.value;

        this.setState({
            [target]: data
        })
    }

    handleCheckbox(event) {
        let index = event.currentTarget.getAttribute('index'), name = event.currentTarget.getAttribute('name');
        let modal = this.state.modal;

        if(modal.class.length > 0) {
            modal.class[index].menu[name] = modal.class[index].menu[name] === true ? false : true;
            this.checkboxStatus();
        }

        this.setState({
            modal
        })
    }

    /* === Add Admin ============================================================ */

    modalAdd() {
        let temp = [];

        for(let value of DeepClone(this.state.app.class)) {
            temp.push({
                _id: value._id,
                name: value.name,
                menu: {
                    create: false,
                    read: false,
                    update: false,
                    delete: false
                }
            });
        }

        this.setState({
            modal: {
                id: '',
                userId: [],
                roles: '',
                class: temp
            },
            modalUser: {
                name: "",
                email: "",
                username: "",
                phone: "",
                nip: "",
                opdId: ""
            },
            isNewUser: false,
            isNext: false,
            isCheckAll: false
        }, () => {
            this.checkboxStatus();
            $('#modal-create').modal('show');
        })
    }

    add() {
        this.setState({
            isLoading: true
        }, () => {
            /* Assign Variable */
            let modal = this.state.modal, modalUser = this.state.modalUser;
            let featureClass = [];

            /* Add Feature that Checked */
            for(let value of modal.class) {
                if(value.menu.read === true || value.menu.create === true || value.menu.update === true || value.menu.delete === true) {
                    featureClass.push({
                        menu: value._id,
                        acl: value.menu
                    })
                }
            }
    
            /* If User is New */
            if(this.state.isNewUser)
            {
                /* Store Request Body */
                let result = {
                    name: modalUser.name,
                    email: modalUser.email,
                    username: modalUser.username,
                    phone: modalUser.phone,
                    appdata: {
                        appId: this.context.get.appId,
                        roles: [modal.roles],
                        class: featureClass
                    }
                }

                /* Store OPD if Admin OPD */
                if(modal.roles === this.state.admin.opd) {
                    result.tagId = modalUser.opdId;
                }

                /* Request Create User with Apps */
                UserAPI.create([result]).then((data) => {
                    if(data.status === 200) {
                        /* Get Data */
                        setTimeout(() => {
                            this.fetchNow()
                            $('#modal-create').modal('hide');
                        }, 3000);
                    }else{
                        /* Handle Error */
                        this.setState({
                            isLoading: false
                        }, () => {
                            toast.error("Penambahan Admin Gagal, Silahkan Coba Beberapa Saat Lagi", {
                                position: toast.POSITION.TOP_CENTER
                            });

                            $('#modal-create').modal('hide');
                        })
                    }
                })
            }
            
            /* If User is Exist */
            else
            {
                /* Store Request Body */
                let result = {
                    userId: modal.userId[0]._id,
                    appId: this.context.get.appId,
                    roles: [modal.roles],
                    class: featureClass
                }

                /* Request Add Apps to User */
                UserAPI.addApps(result).then((data) => {
                    if(data.status === 200) {
                        if(modal.roles === this.state.admin.opd) {
                            /* Request Update OPD in User if Admin OPD */
                            UserAPI.update({tagId: modalUser.opdId}, result.userId).then((data2) => {
                                if(data2.status === 200) {
                                    /* Get Data */
                                    this.fetchNow()
                                    $('#modal-create').modal('hide');
                                    
                                    toast.success("Admin OPD Baru Berhasil Ditambahkan", {
                                        position: toast.POSITION.TOP_CENTER,
                                        theme: 'colored'
                                    });
                                }else{
                                    /* Handle Error */
                                    this.setState({
                                        isLoading: false
                                    }, () => {
                                        toast.error("Penambahan Admin Gagal, Silahkan Coba Beberapa Saat Lagi", {
                                            position: toast.POSITION.TOP_CENTER
                                        });

                                        $('#modal-create').modal('hide');
                                    })
                                }
                            })
                        }else{
                            /* If Super Admin, Get Data */
                            this.fetchNow()
                            $('#modal-create').modal('hide');

                            toast.success("Super Admin Baru Berhasil Ditambahkan", {
                                position: toast.POSITION.TOP_CENTER,
                                theme: 'colored'
                            });
                        }
                    }else{
                        /* Handle Error */
                        this.setState({
                            isLoading: false
                        }, () => {
                            toast.error("Penambahan Admin Gagal, Silahkan Coba Beberapa Saat Lagi", {
                                position: toast.POSITION.TOP_CENTER
                            });

                            $('#modal-create').modal('hide');
                        })
                    }
                })
            }
        })
    }

    /* === Update ACL =========================================================== */

    modalUpdate(data) {
        /* Store Variable and Data */
        let temp = DeepClone(data.class);
        let modal = {
            id: data.userAppId,
            userId: [],
            roles: data.roles[0]._id,
            class: []
        };

        /* Loop to Add Feature List */
        loop: for(let value of this.state.app.class) {
            for(let i=0 ; i<temp.length ; i++) {
                if(value._id === temp[i].menu._id) {
                    /* Add if User Have Access */
                    modal.class.push({
                        _id: value._id,
                        name: value.name,
                        menu: {
                            create: temp[i].acl.create,
                            read: temp[i].acl.read,
                            update: temp[i].acl.update,
                            delete: temp[i].acl.delete
                        }
                    });
                    
                    continue loop;
                }
            }

            /* Add if User Doesn't Have Access */
            modal.class.push({
                _id: value._id,
                name: value.name,
                menu: {
                    create: false,
                    read: false,
                    update: false,
                    delete: false
                }
            });
        }

        /* Update */
        this.setState({
            isCheckAll: false,
            modal
        }, () => { this.checkboxStatus(); $('#modal-update').modal('show'); })
    }

    update() {
        this.setState({
            isLoading: true
        }, () => {
            /* Store Request Body */
            let result = {
                class: []
            }

            /* Add Feature that Checked */
            for(let value of this.state.modal.class) {
                if(value.menu.read === true || value.menu.create === true || value.menu.update === true || value.menu.delete === true) {
                    result.class.push({
                        menu: value._id,
                        acl: value.menu
                    })
                }
            }

            /* Request to Update Class */
            UserAPI.updateClass(result, this.state.modal.id).then((data) => {
                if(data.status === 200) {
                    /* Update if Success */
                    this.fetchNow();
                    $('#modal-update').modal('hide');

                    toast.success("Berhasil Update Hak Akses", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: 'colored'
                    });
                }else{
                    /* Handle Error */
                    this.setState({
                        isLoading: false
                    }, () => {
                        toast.error("Ubah Hak Akses Gagal, Silahkan Coba Beberapa Saat Lagi", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        $('#modal-update').modal('hide');
                    })
                }
            })
        })
    }

    /* === Switch Role ========================================================== */

    modalSwitch(data) {
        this.setState({
            modal: {
                id: data.userAppId,
                userId: [],
                roles: data.roles[0]._id,
                class: []
            },
            modalUser: {
                name: data._id,
                email: "",
                username: "",
                phone: "",
                nip: "",
                opdId: ""
            },
            isNext: false
        }, () => {
            $('#modal-switch').modal('show');
        })
    }

    switchRole() {
        this.setState({
            isLoading: true
        }, () => {
            /* Store Data */
            let roles = this.state.modal.roles;
            let tagId = this.state.modalUser.opdId;

            /* Store Request Body */
            let result = {
                roles: [
                    ((roles === this.state.admin.super) ? this.state.admin.opd : this.state.admin.super)
                ]
            }

            /* Request to Update Role */
            UserAPI.updateRole(result, this.state.modal.id).then((data) => {
                if(data.status === 200) {
                    /* If Success Check if Previous Role is Super Admin */
                    if(roles === this.state.admin.super){
                        /* Request to Update OPD */
                        UserAPI.update({tagId: tagId}, this.state.modalUser.name).then((data2) => {
                            if(data2.status === 200) {
                                /* Get Data */
                                this.fetchNow()
                                $('#modal-switch').modal('hide');

                                toast.success("Berhasil Update Role Admin", {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: 'colored'
                                });
                            }else{
                                /* Handle Error */
                                this.setState({
                                    isLoading: false
                                }, () => {
                                    toast.error("Ubah Role Admin Gagal, Silahkan Coba Beberapa Saat Lagi", {
                                        position: toast.POSITION.TOP_CENTER
                                    });

                                    $('#modal-switch').modal('hide');
                                })
                            }
                        })
                    }else{
                        /* Update Data */
                        this.fetchNow()
                        $('#modal-switch').modal('hide');

                        toast.success("Berhasil Update Role Admin", {
                            position: toast.POSITION.TOP_CENTER,
                            theme: 'colored'
                        });
                    }
                }else{
                    /* Handle Error */
                    this.setState({
                        isLoading: false
                    }, () => {
                        toast.error("Ubah Role Admin Gagal, Silahkan Coba Beberapa Saat Lagi", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        $('#modal-switch').modal('hide');
                    })
                }
            })
        })
    }

    /* === Change OPD =========================================================== */

    modalOPD(data) {
        this.setState({
            modalUser: {
                name: data._id,
                email: "",
                username: "",
                phone: "",
                nip: "",
                opdId: data.opdId
            }
        }, () => {
            $('#modal-opd').modal('show');
        })
    }

    changeOPD() {
        this.setState({
            isLoading: true
        }, () => {
            /* Request to Update OPD */
            UserAPI.update({tagId: this.state.modalUser.opdId}, this.state.modalUser.name).then((data) => {
                if(data.status === 200) {
                    /* Update if Success */
                    this.fetchNow();
                    $('#modal-opd').modal('hide');

                    toast.success("Berhasil Update OPD", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: 'colored'
                    });
                }else{
                    /* Handle Error */
                    this.setState({
                        isLoading: false
                    }, () => {
                        toast.error("Ubah OPD Gagal, Silahkan Coba Beberapa Saat Lagi", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        $('#modal-opd').modal('hide');
                    })
                }
            })
        })
    }

    /* === Remove Admin Access ================================================== */
    
    remove(event) {
        let id = event.currentTarget.getAttribute('data-userapp');
        
        event.stopPropagation();
        if(window.confirm('Are sure want to delete?')) {
            this.setState({
                isLoading: true
            }, () => {
                /* Request to Remove Admin Access */
                UserAPI.removeApps(id).then((data) => {
                    if(data.status === 200) {
                        this.fetchNow();

                        toast.warn("Hapus Admin Berhasil", {
                            position: toast.POSITION.TOP_CENTER,
                            theme: 'colored'
                        });
                    }else{
                        this.setState({
                            isLoading: false
                        }, () => {
                            toast.error("Hapus Admin Gagal, Silahkan Coba Beberapa Saat Lagi", {
                                position: toast.POSITION.TOP_CENTER
                            });
                        })
                    }
                })
            })
        }
    }

    /* === Toggle Tab =========================================================== */

    handleTab(event) {
        let value = event.currentTarget.getAttribute('data-value');

        this.setState({
            isChoose: (value === 'true') ? true : false
        })
    }

    /* === Check All Class ====================================================== */

    checkboxStatus() {
        /* Assign Variable */
        let status = false, amount = 0;

        /* Loop to Get Status */
        for(let value of this.state.modal.class) {
            for(let value2 of Object.keys(value.menu)) {
                if(value.menu[value2] === true) {
                    status = true;
                    amount++;
                }
            }
        }

        /* Process Depend on Checkbox Status */
        if(status) {
            if(amount === (this.state.modal.class.length * 4)) {
                /* Handle Checked All */
                document.getElementById('check-all').indeterminate = false;
                this.setState({
                    isCheckAll: true
                })
            }else{
                /* Handle Indeterminate */
                document.getElementById('check-all').indeterminate = true;
                this.setState({
                    isCheckAll: false
                })
            }
        }else{
            /* Handle Not Checked */
            document.getElementById('check-all').indeterminate = false;
            this.setState({
                isCheckAll: false
            })
        }
    }

    handleCheckAll() {
        let modal = this.state.modal;

        let result = this.state.isCheckAll ? false : true;

        /* Loop to Get Status */
        for(let value of modal.class) {
            for(let menu of Object.keys(value.menu)) {
                value.menu[menu] = result;
            }
        }

        this.setState({
            isCheckAll: result,
            modal
        }, () => {
            this.checkboxStatus();
        })
    }

    componentDidMount() {
        this.fetchNow()
    }

    render() { 
        return (
            <AccessController name="Admin" access="read" type="page">
                <Helmet title="Admin - Manajemen SSO" />
                <Loading show={this.state.isLoading} />

                <div className="row d-flex align-items-center m-0 mt-2 mb-4">
                    <div className="col-lg col-12 text-center text-lg-left">
                        <h1 className="mb-0">Admin</h1>
                    </div>
                    <AccessController name="Admin" access="create">
                        <div className="col-lg-auto col-12 d-flex justify-content-center mt-2 mt-lg-0">
                            <button onClick={this.modalAdd} className="btn btn-primary btn-issp">
                                <Key size={18} className="icon-btn"/>
                                <span>Tambah Admin</span>
                            </button>
                        </div>
                    </AccessController>
                </div>

                <div className="row m-0">
                    <div className="col-12 mb-4">
                        <div className="card">
                            <div className="card-header border-bottom">
                                <ul className="nav nav-tabs card-header-tabs" role="tablist">
                                    <li className="nav-item">
                                        <div onClick={this.handleTab} data-value="false" className={"issp nav-link" + (this.state.isChoose ? '' : ' active')}>Super Admin</div>
                                    </li>
                                    {/* <li className="nav-item">
                                        <div onClick={this.handleTab} data-value="true" className={"issp nav-link" + (this.state.isChoose ? ' active' : '')}>Admin OPD</div>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="card-body">
                                <div className="tab-content">
                                    <div className={"tab-pane fade" + (this.state.isChoose ? '' : ' show active')} id="issp-super-admin" role="tabpanel">
                                        <div className="datatable">
                                            <table className="table table-striped table-hover" id="super-admin-table">
                                                <thead>
                                                    <tr>
                                                        <th width="5%" align="center"></th>
                                                        <th>Nama</th>
                                                        <th>Username</th>
                                                        <th>Akses</th>
                                                        <th>Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.superAdmin.map((value, index) => {
                                                            return (
                                                                <tr key={index} className="issp-table">
                                                                    <th></th>
                                                                    <td>{value.name}</td>
                                                                    <td>{value.username}</td>
                                                                    <td>{value.class.length + ' Hak Akses'}</td>
                                                                        <td className="d-flex">
                                                                            <div className="dropdown">
                                                                                <button className="btn btn-table rounded-circle" data-toggle="dropdown">
                                                                                    <MoreHorizontal />
                                                                                </button>
                                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                                    <AccessController name="Admin" access="update">
                                                                                        <button onClick={this.modalUpdate.bind(this, value)} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><Shield className="mt-n1" /></span>Ubah Hak Akses</button>
                                                                                        <button onClick={this.modalSwitch.bind(this, value)} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><User className="mt-n1"/></span>Ubah Role Admin</button>
                                                                                    </AccessController>
                                                                                    <AccessController name="Admin" access="delete">
                                                                                        <button onClick={this.remove} data-userapp={value.userAppId} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><Trash2 className="mt-n1" /></span>Hapus Admin</button>
                                                                                    </AccessController>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <div className={"tab-pane fade" + (this.state.isChoose ? ' show active' : '')} id="issp-opd-admin" role="tabpanel">
                                        <div className="datatable">
                                            <table className="table table-striped table-hover" id="opd-admin-table">
                                                <thead>
                                                    <tr>
                                                        <th width="5%" align="center"></th>
                                                        <th>OPD</th>
                                                        <th>Nama</th>
                                                        <th>Username</th>
                                                        <th>Akses</th>
                                                        <th>Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.opdAdmin.map((value, index) => {
                                                            let opd = this.findById(value.tagId, this.state.opd);

                                                            return (
                                                                <tr key={index} className="issp-table">
                                                                    <th></th>
                                                                    <td className="font-weight-bold text-dark">{opd.name}</td>
                                                                    <td>{value.name}</td>
                                                                    <td>{value.username}</td>
                                                                    <td>{value.class.length + ' Hak Akses'}</td>
                                                                    <td className="d-flex">
                                                                        <div className="dropdown">
                                                                            <button className="btn btn-table rounded-circle" data-toggle="dropdown">
                                                                                <MoreHorizontal />
                                                                            </button>
                                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                                <AccessController name="Admin" access="update">
                                                                                    <button onClick={this.modalOPD.bind(this, value)} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><Briefcase className="mt-n1" /></span>Ubah OPD</button>
                                                                                    <button onClick={this.modalUpdate.bind(this, value)} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><Shield className="mt-n1" /></span>Ubah Hak Akses</button>
                                                                                    <button onClick={this.modalSwitch.bind(this, value)} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><User className="mt-n1"/></span>Ubah Role Admin</button>
                                                                                </AccessController>
                                                                                <AccessController name="Admin" access="delete">
                                                                                    <button onClick={this.remove} data-userapp={value.userAppId} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><Trash2 className="mt-n1" /></span>Hapus Admin</button>
                                                                                </AccessController>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="modal-create" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Tambah Admin</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {
                                    this.state.isNext === false ? (
                                        <React.Fragment>
                                            <div className="form-group">
                                                <label className="text-dark mb-1">Nama Pengguna</label>
                                                <Typeahead id="user-typeahead" placeholder="Pilih pengguna atau tambah baru" options={this.state.user} selected={this.state.modal.userId} onChange={this.handleUserChoice} labelKey="name" />
                                            </div>
                                            {/* {
                                                this.state.isNewUser && (
                                                    <React.Fragment>
                                                        <div className="form-group">
                                                            <label className="text-dark mb-1">Username</label>
                                                            <input onChange={this.handleChange} value={this.state.modalUser.username} name="username" data-type="modalUser" className="form-control py-3" type="text" placeholder="Masukkan username" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="text-dark mb-1">Email</label>
                                                            <input onChange={this.handleChange} value={this.state.modalUser.email} name="email" data-type="modalUser" className="form-control py-3" type="email" placeholder="Masukkan email" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="text-dark mb-1">Telepon</label>
                                                            <input onChange={this.handleChange} value={this.state.modalUser.phone} name="phone" data-type="modalUser" className="form-control py-3" type="tel" placeholder="628xxxx" />
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            } */}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <div className="form-group">
                                                <label className="text-dark mb-1">Role Admin</label>
                                                <select onChange={this.handleChange} value={this.state.modal.roles} name="roles" data-type="modal" className="form-control">
                                                    <option value="" disabled>-- Pilih Role --</option>
                                                    {
                                                        this.state.app.roles.map((value, index) => {
                                                            return (
                                                                <option value={value._id} key={index}>{value.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            {
                                                this.state.modal.roles === this.state.admin.opd && (
                                                    <div className="form-group">
                                                        <label className="text-dark mb-1">OPD</label>
                                                        <select onChange={this.handleChange} value={this.state.modalUser.opdId} name="opdId" data-type="modalUser" className="form-control">
                                                            <option value="" disabled>-- Pilih OPD --</option>
                                                            {
                                                                this.state.opd.map((value, index) => {
                                                                    return (
                                                                        <option value={value._id} key={index}>{value.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                )
                                            }
                                            <div className="row m-0">
                                                <div className="col-4 pl-0 mb-3 text-dark font-weight-bold">
                                                    Fitur
                                                </div>
                                                <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Read</div>
                                                <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Create</div>
                                                <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Update</div>
                                                <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Delete</div>
                                                {
                                                    this.state.modal.class.map((value, index) => {
                                                        if(this.state.modal.roles === this.state.admin.opd && value.name === 'Admin') {
                                                            return null;
                                                        }else{
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <div className="col-4 mb-3 pl-0 d-flex align-items-center">
                                                                        {value.name}
                                                                    </div>
                                                                    <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                                        <input onChange={this.handleCheckbox} checked={value.menu.read} index={index} name="read" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                                    </div>
                                                                    <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                                        <input onChange={this.handleCheckbox} checked={value.menu.create} index={index} name="create" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                                    </div>
                                                                    <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                                        <input onChange={this.handleCheckbox} checked={value.menu.update} index={index} name="update" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                                    </div>
                                                                    <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                                        <input onChange={this.handleCheckbox} checked={value.menu.delete} index={index} name="delete" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                                    </div>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    })
                                                }
                                                <div className="col-12 bg-light py-2 d-flex align-items-center justify-content-center rounded">
                                                    <div className="d-flex align-items-center">
                                                        <input onChange={this.handleCheckAll} checked={this.state.isCheckAll} id="check-all" className="form-check-input form-check-child m-0 mr-2" type="checkbox"/>
                                                        <span>Pilih Semua Akses</span>
                                                    </div>
                                                </div> 
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                            </div>
                            {
                                this.state.isNext === false ? (
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-success" data-dismiss="modal">Batal</button>
                                        <button disabled={this.state.modal.userId.length <= 0} type="button" className="btn btn-success" onClick={this.step} data-value="next">Selanjutnya</button>
                                    </div>
                                ) : (
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-success" onClick={this.step} data-value="prev">Sebelumnya</button>
                                        <button type="button" className="btn btn-success" onClick={this.add}>Tambah</button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className="modal" id="modal-update" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Ubah Hak Akses</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row m-0">
                                    <div className="col-4 pl-0 mb-3 text-dark font-weight-bold">
                                        Fitur
                                    </div>
                                    <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Read</div>
                                    <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Create</div>
                                    <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Update</div>
                                    <div className="col-2 px-1 mb-3 text-center text-dark font-weight-bold">Delete</div>
                                    {
                                        this.state.modal.class.map((value, index) => {
                                            if(this.state.modal.roles === this.state.admin.opd && value.name === 'Admin') {
                                                return null;
                                            }else{
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="col-4 mb-3 pl-0 d-flex align-items-center">
                                                            {value.name}
                                                        </div>
                                                        <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                            <input onChange={this.handleCheckbox} checked={value.menu.read} index={index} name="read" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                        </div>
                                                        <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                            <input onChange={this.handleCheckbox} checked={value.menu.create} index={index} name="create" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                        </div>
                                                        <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                            <input onChange={this.handleCheckbox} checked={value.menu.update} index={index} name="update" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                        </div>
                                                        <div className="col-2 mb-3 d-flex justify-content-center align-items-center">
                                                            <input onChange={this.handleCheckbox} checked={value.menu.delete} index={index} name="delete" className="form-check-input form-check-child m-0" type="checkbox"/>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            }
                                        })
                                    }
                                    <div className="col-12 bg-light py-2 d-flex align-items-center justify-content-center rounded">
                                        <div className="d-flex align-items-center">
                                            <input onChange={this.handleCheckAll} checked={this.state.isCheckAll} id="check-all" className="form-check-input form-check-child m-0 mr-2" type="checkbox"/>
                                            <span>Pilih Semua Akses</span>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-orange" data-dismiss="modal">Batal</button>
                                <button type="button" className="btn btn-orange" onClick={this.update}>Simpan</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="modal-switch" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Ubah Role Admin</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {
                                this.state.modal.roles === this.state.admin.opd ? (
                                    <div className="modal-body py-4">
                                        Apakah Anda akan mengubah role menjadi Super Admin?
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        {
                                            this.state.isNext === false ? (
                                                <div className="modal-body py-4">
                                                    Apakah Anda akan mengubah role menjadi Admin OPD?
                                                </div>
                                            ) : (
                                                <div className="modal-body">
                                                    <div className="form-group">
                                                        <label className="text-dark mb-1">OPD</label>
                                                        <select onChange={this.handleChange} value={this.state.modalUser.opdId} name="opdId" data-type="modalUser" className="form-control">
                                                            <option value="" disabled>-- Pilih OPD --</option>
                                                            {
                                                                this.state.opd.map((value, index) => {
                                                                    return (
                                                                        <option value={value._id} key={index}>{value.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            }
                            {
                                this.state.modal.roles === this.state.admin.opd ? (
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-orange" data-dismiss="modal">Batal</button>
                                        <button type="button" className="btn btn-orange" onClick={this.switchRole}>Ubah</button>
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        {
                                            this.state.isNext === false ? (
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-outline-orange" data-dismiss="modal">Batal</button>
                                                    <button type="button" className="btn btn-orange" onClick={this.step} data-value="next">Ya</button>
                                                </div>
                                            ) : (
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-outline-orange" data-dismiss="modal">Batal</button>
                                                    <button type="button" className="btn btn-orange" onClick={this.switchRole}>Ubah</button>
                                                </div>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className="modal" id="modal-opd" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Ubah OPD</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label className="text-dark mb-1">OPD</label>
                                    <select onChange={this.handleChange} value={this.state.modalUser.opdId} name="opdId" data-type="modalUser" className="form-control">
                                        <option value="" disabled>-- Pilih OPD --</option>
                                        {
                                            this.state.opd.map((value, index) => {
                                                return (
                                                    <option value={value._id} key={index}>{value.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-orange" data-dismiss="modal">Batal</button>
                                <button type="button" className="btn btn-orange" onClick={this.changeOPD}>Simpan</button>
                            </div>
                        </div>
                    </div>
                </div>
            </AccessController>
        );
    }
}
 
export default Admin;