import React, { Component } from 'react';
import { Eye, Monitor, MoreHorizontal, StopCircle, Trash2 } from 'react-feather';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Typeahead } from 'react-bootstrap-typeahead';

import OpdAPI from '../../../api/Opd';
import ClusterAPI from '../../../api/Cluster';
import ApplicationAPI from '../../../api/Application';

import AccessController from '../../../component/AccessController';
import { RootContext } from '../../../component/Context';
import Loading from '../../../component/Loading';

const DeepClone = require('rfdc')();
toast.configure();

class OPDApplication extends Component {
    static contextType = RootContext;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,

            data: [],
            cluster: [],
            uid: '',
            show: [],

            modal: {
                name: '',
                domain: '',
                clusterId: [],
                cluster:[],
                isPublic: 'not'
            },

            filter: ''
        }

        this.fetchNow = this.fetchNow.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handelClusterChange = this.handelClusterChange.bind(this);

        this.modalCreate = this.modalCreate.bind(this);
        this.create = this.create.bind(this);

        this.delete = this.delete.bind(this);

        this.handleFilter = this.handleFilter.bind(this);
    }

    /* === Core Process ========================================================= */

    compare(a, b) {
        /* Sorting Compare */
        if(a.name < b.name){
            return -1;
        }else if(a.name > b.name){
            return 1;
        }else{
            return 0;
        }
    }

    findById(id, data) {
        for(let value of data) {
            if(value._id === id) {
                return value;
            }
        }
    }

    fetchNow() {
        this.setState({
            isLoading: true
        }, () => {
            ClusterAPI.get().then((resultCluster) => {
                OpdAPI.getById(this.context.get.user.opdId).then((result) => {
                    /* Assign Variable */
                    let data = [], cluster = [], uid = this.state.uid;
                    
                    /* Store Cluster */
                    if(resultCluster.status === 200) {
                        cluster = resultCluster.data;
                        cluster.sort(this.compare);
                    }

                    /* Store Data */
                    if(result.status === 200) {
                        data = result.data.apps;
                        uid = result.data.uid;
                    }

                    /* Remove Existing Datatable */
                    if($.fn.dataTable.isDataTable('#application-table')){
                        $('#application-table').DataTable().destroy();
                    }
    
                    /* Update */
                    this.setState({
                        isLoading: false,
                        filter: '',
                        show: data,
                        data, cluster, uid
                    }, () => {
                        /* Initialize New Datatable */
                        var table = $('#application-table').DataTable({
                            columnDefs: [ {
                                searchable: false,
                                orderable: false,
                                targets: 0
                            } ],
                            order: [[ 1, 'asc' ]],
                            language: {
                                lengthMenu: "Lihat _MENU_ data",
                                zeroRecords: "Tidak ada data yang tersedia",
                                info: "Data _START_ - _END_ dari total _TOTAL_ data",
                                infoEmpty: "Menampilkan 0 data",
                                search: "Cari",
                                paginate: {
                                    previous: "Sebelumnya",
                                    next: "Selanjutnya"
                                }
                            }
                        });
    
                        table.on('order.dt search.dt', function () {
                            table.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
                                cell.innerHTML = i+1;
                            });
                        }).draw();
                    })
                })

            })
        })
    }

    handleChange(event) {
        /* Store Variable */
        let modal = this.state.modal;
        
        /* Change Value */
        modal[event.target.name] = event.target.value;

        /* Update Data */
        this.setState({
            modal
        })
    }

    handelClusterChange(event) {
        let clusterId = []
        let cluster = []
        for (let i = 0; i < event.length; i++) {
            const el = event[i];
            clusterId.push(el._id)
            cluster.push(el)
        }
        
        this.setState({
            modal: {
                ...this.state.modal,
                clusterId: clusterId,
                cluster: cluster
            }
        })
    }

    /* === Create Application =================================================== */

    modalCreate() {
        /* Reset Modal */
        this.setState({
            modal: {
                name: '',
                domain: '',
                clusterId: [],
                cluster: [],
                isPublic: 'not'
            }
        }, () => {
            /* Show Modal */
            $("#modal-create").modal('show');
        })
    }

    create() {
        this.setState({
            isLoading: true
        }, () => {
            /* Store Result */
            const body = {
                name: this.state.modal.name,
                domain: this.state.modal.domain,
                isPublic: this.state.modal.isPublic === 'public' ? true : false,
                clusterId: this.state.modal.clusterId,
                opdId: this.state.uid,
                userCount: 0
            }

            /* Request Create Apps */
            ApplicationAPI.create(body).then((data) => {
                if(data.status === 200) {
                    /* Handle Success */
                    this.fetchNow();

                    toast.success("Penambahan Aplikasi Berhasil", {
                        position: toast.POSITION.TOP_CENTER
                    });

                    $("#modal-create").modal('hide');
                }else{
                    /* Handle Failed */
                    this.setState({
                        isLoading: false
                    }, () => { 
                        toast.error("Penambahan Aplikasi Gagal, Silahkan Coba Beberapa Saat Lagi", {
                            position: toast.POSITION.TOP_CENTER
                        });

                        $("#modal-create").modal('hide');
                    })
                }
            })
        })
    }

    /* === Delete Application =================================================== */

    delete(value) {
        let id = value._id;

        this.setState({
            isLoading: true
        }, () => {
            ApplicationAPI.delete(id).then((data) => {
                if(data.status === 200) {
                    /* Handle Success */
                    this.fetchNow();

                    toast.success("Aplikasi Berhasil Dihapus", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }else{
                    /* Handle Failed */
                    this.setState({
                        isLoading: false
                    }, () => { 
                        toast.error("Aplikasi Gagal Dihapus, Silahkan Coba Beberapa Saat Lagi", {
                            position: toast.POSITION.TOP_CENTER
                        });
                    })
                }
            })
        })
    }

    /* === Filter Application =================================================== */

    handleFilter(event) {
        /* Store Variable */
        let filter = this.state.filter, show = [];

        /* Store New Value */
        filter = event.target.value;

        /* Filter Apps by Cluster */
        if(filter === '') {
            show = DeepClone(this.state.data);
        }else{
            for(let value of this.state.data) {
                if(value.clusterId) {
                    if(value.clusterId.includes(filter.cluster)) {
                        show.push(value);
                    }
                }
            }
        }

        /* Remove Existing Datatable */
        if($.fn.dataTable.isDataTable('#application-table')){
            $('#application-table').DataTable().destroy();
        }

        this.setState({
            show, filter
        }, () => {
            /* Initialize New Datatable */
            var table = $('#application-table').DataTable({
                columnDefs: [ {
                    searchable: false,
                    orderable: false,
                    targets: 0
                } ],
                order: [[ 1, 'asc' ]],
                language: {
                    lengthMenu: "Lihat _MENU_ data",
                    zeroRecords: "Tidak ada data yang tersedia",
                    info: "Data _START_ - _END_ dari total _TOTAL_ data",
                    infoEmpty: "Menampilkan 0 data",
                    search: "Cari",
                    paginate: {
                        previous: "Sebelumnya",
                        next: "Selanjutnya"
                    }
                }
            });

            table.on('order.dt search.dt', function () {
                table.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
                    cell.innerHTML = i+1;
                });
            }).draw();

            /* Hide Modal */
            $('#modal-cluster').modal('hide');
        })
    }

    componentDidMount() {
        /* Initialize Tooltip */
        $('[data-toggle="tooltip"]').tooltip();

        this.fetchNow();
    }

    render() { 
        return (
            <AccessController name="Aplikasi" access="read" type="page">
                <Loading show={this.state.isLoading} />
                <Helmet title="Aplikasi - Manajemen SSO" />

                <div className="row d-flex align-items-center m-0 mt-2 mb-4">
                    <div className="col-lg col-12 text-center text-lg-left">
                        <h1 className="mb-0">Aplikasi</h1>
                    </div>
                    <div className="col-lg-auto col-12 d-flex flex-md-row flex-column align-items-center justify-content-center mt-2 mt-lg-0">
                        <div className="mr-0 mr-md-2 mb-2 mb-md-0">
                            <button className="btn btn-filter btn-issp" onClick={() => {$('#modal-cluster').modal('show')}} data-toggle="tooltip" data-placement="bottom" title="Tampilkan Aplikasi Berdasarkan Klaster">
                                <StopCircle size={18} className="icon-btn"/>
                                <span className="text-truncate" style={{maxWidth: '10rem'}}>
                                    {this.state.filter === '' ? 'Semua Klaster' : 'Klaster ' + ((this.findById(this.state.filter, this.state.cluster)).name)}
                                </span>
                            </button>
                        </div>
                        <AccessController name="Aplikasi" access="create">
                            <div>
                                <button className="btn btn-primary btn-issp" onClick={this.modalCreate}>
                                    <Monitor size={18} className="icon-btn"/>
                                    <span>Tambah Aplikasi</span>
                                </button>
                            </div>
                        </AccessController>
                    </div>
                </div>

                <div className="row m-0 mb-5">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="datatable">
                                    <table className="table table-striped table-hover" id="application-table">
                                        <thead>
                                            <tr>
                                                <th width="5%" align="center"></th>
                                                <th>Nama</th>
                                                <th>Domain</th>
                                                <th>Status</th>
                                                <th>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.show.map((value, index) => {
                                                    return (
                                                        <tr key={index} className="issp-table">
                                                            <th></th>
                                                            <td>{value.name}</td>
                                                            <td>{value.domain}</td>
                                                            <td>
                                                                <div className={"text-nowrap badge-public" + (value.isPublic ? ' public' : '')}>
                                                                    {value.isPublic ? 'Publik' : 'Tidak Publik'}
                                                                </div>
                                                            </td>
                                                            <td className="d-flex">
                                                                <div className="dropdown">
                                                                    <button className="btn btn-table rounded-circle" data-toggle="dropdown">
                                                                        <MoreHorizontal />
                                                                    </button>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <Link to={"/aplikasi/" + value._id} className="dropdown-item w-100" type="button"><span className="dropdown-item-icon"><Eye /></span>Lihat Detail</Link>
                                                                        <AccessController name="Aplikasi" access="delete">
                                                                            <button className="dropdown-item w-100" type="button" index={index} onClick={this.delete.bind(this, value)}><span className="dropdown-item-icon"><Trash2 /></span>Hapus Aplikasi</button>
                                                                        </AccessController>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="modal-create" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Tambah Aplikasi</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label className="text-dark mb-1">Nama</label>
                                    <input value={this.state.modal.name} onChange={this.handleChange} name="name" className="form-control py-3" type="text" placeholder="Masukkan nama aplikasi" />
                                    <div className="invalid-feedback"></div>
                                </div>
                                <div className="form-group">
                                    <label className="text-dark mb-1" >Domain</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">http://</div>
                                        </div>
                                        <input value={this.state.modal.domain} onChange={this.handleChange} name="domain" className="form-control py-3" type="text" placeholder="Masukkan url aplikasi" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="text-dark mb-1">Klaster</label>
                                    <Typeahead id="cluster-typeahead" placeholder="Pilih cluster" options={this.state.cluster} selected={this.state.modal.cluster} onChange={this.handelClusterChange} labelKey="name" multiple />
                                </div>
                                <div className="form-group mb-1">
                                    <label className="text-dark mb-1">Status Aplikasi</label>
                                </div>
                                <div className="form-group">
                                    <div className="form-check form-check-inline">
                                        <input checked={this.state.modal.isPublic === 'public'} onChange={this.handleChange} className="form-check-input" type="radio" name="isPublic" id="isPublic1" value="public" />
                                        <label className="form-check-label" htmlFor="isPublic1">Publik (Umum)</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input checked={this.state.modal.isPublic === 'not'} onChange={this.handleChange} className="form-check-input" type="radio" name="isPublic" id="isPublic2" value="not" />
                                        <label className="form-check-label" htmlFor="isPublic2">Tidak Publik</label>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-success" data-dismiss="modal">Batal</button>
                                <button type="button" className="btn btn-success" onClick={this.create}>Tambah</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="modal-cluster" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Filter Aplikasi</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label className="text-dark mb-1">Pilih Klaster</label>
                                    <select value={this.state.filter} onChange={this.handleFilter} name="cluster" className="form-control">
                                        <option value="">Semua Klaster</option>
                                        {
                                            this.state.cluster.map((value, index) => {
                                                return (
                                                    <option value={value._id} key={index}>{value.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AccessController>
        );
    }
}
 
export default OPDApplication;