import React, { Component } from "react";
import OpdAPI from "../../../api/Opd";
import ClusterAPI from "../../../api/Cluster";
import ApplicationAPI from "../../../api/Application";
import Tree from "react-d3-tree";
import Loading from "../../../component/Loading";


const renderClusterNode = ({ nodeDatum, toggleNode }) => (
  <g>
    <circle fill="red" r="10" onClick={toggleNode} />
    <foreignObject x="20" y="-4" width="150px" height="100%">
      <p style={{fontSize: "14px",color: "Black", maxWidth: "150px", fontWeight:"bold",margin:"0px",lineHeight:"1em"}}>{nodeDatum.name}</p>
    </foreignObject>
    {nodeDatum.attributes?.Alamat && (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a xlinkHref={'http://' + nodeDatum.attributes?.Alamat} target="_blank">
      <text fill="darkred" strokeWidth="0" font-weigth="lighter" x="20" y="70" font-size="smaller"> {nodeDatum.attributes?.Alamat} </text>
      </a>
      )}
    <foreignObject x="20" y="80" width="150" height="100%">
    {nodeDatum.attributes?.OPD && (
      <p style={{fontSize: "12px",color: "gray", maxWidth: "150px", fontWeight:"bold",margin:"0px"}}>{nodeDatum.attributes?.OPD}</p>
    )}
      {nodeDatum.attributes?.Deskripsi && (
      <p style={{fontSize: "12px",color: "gray", maxWidth: "150px", textAlign:"justify"}}>Deskripsi: {nodeDatum.attributes?.Deskripsi}</p>
    )}
      </foreignObject>
  </g>
);

const renderOPDNode = ({ nodeDatum, toggleNode }) => (
  <g>
    <circle fill="red" r="10" onClick={toggleNode} />
    <foreignObject x="20" y="5" width="150" height="30">
      <p style={{fontSize: "14px",color: "Black", maxWidth: "150px", fontWeight:"bold",margin:"0px"}}>{nodeDatum.name}</p>
    </foreignObject>
    {/* <text fill="black" strokeWidth="1" x="20" y="5">
      {nodeDatum.name}
    </text> */}
    {nodeDatum.attributes?.Alamat && (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a xlinkHref={'http://' + nodeDatum.attributes?.Alamat} target="_blank">
      <text fill="darkred" strokeWidth="0" font-weigth="lighter" x="20" y="25" font-size="smaller"> {nodeDatum.attributes?.Alamat} </text>
      </a>
      )}
    <foreignObject x="20" y="35" width="150" height="200">
    {nodeDatum.attributes?.Cluster && (
      <p style={{fontSize: "12px",color: "gray", maxWidth: "150px", fontWeight:"bold",margin:"0px"}}>{nodeDatum.attributes?.Cluster}</p>
    )}
      {nodeDatum.attributes?.Deskripsi && (
      <p style={{fontSize: "12px",color: "gray", maxWidth: "150px", textAlign:"justify"}}>Deskripsi: {nodeDatum.attributes?.Deskripsi}</p>
    )}
    </foreignObject>
  </g>
);

class Cascading extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isCluster: true,
      opd: [],
      cluster: [],
      application: [],
      clusterChart: {
        name: "Cluster",
        children: [],
      },
      opdChart: {
        name: "OPD",
        children: [],
      },
    };

    this.fetchNow = this.fetchNow.bind(this);
  }

  fetchNow() {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        OpdAPI.get().then((opdData) => {
          ClusterAPI.get().then((clusterData) => {
            ApplicationAPI.get().then((applicationData) => {
              let opd = [];
              let cluster = [];
              let application = [];

              if (opdData.status === 200) {
                opd = opdData.data;
              }

              if (clusterData.status === 200) {
                cluster = clusterData.data;
              }

              if (applicationData.status === 200) {
                application = applicationData.data;
              }
              this.setState(
                {
                  opd: opd,
                  cluster: cluster,
                  application: application,
                },
                () => {
                  console.log(opd);
                  console.log(cluster);
                  console.log(application);

                  cluster.forEach((cluster) => {
                    let clusterChart = this.state.clusterChart;
                    let clusterData = {
                      name: cluster.name,
                      children: [],
                    };
                    clusterChart.children.push(clusterData);
                    this.setState({
                      clusterChart: clusterChart,
                    });
                  });

                  opd.forEach((opd) => {
                    let opdChart = this.state.opdChart;
                    let clusterData = {
                      name: opd.name,
                      children: [],
                    };
                    opdChart.children.push(clusterData);
                    this.setState({
                      opdChart: opdChart,
                    });
                  });

                  let clusterChart = this.state.clusterChart;
                  let clusterDummy = {
                    name: "dummy",
                    children: [
                      {
                        name: "dummy app 1",
                        attributes: {
                          "Alamat": "192.168.1.1",
                          "Deskripsi": "Placeholder",
                          "OPD": "Organisasi Pemerintahan Daerah",
                          "Cluster": "Clusterer"
                        },
                        children: [],
                      },
                      {
                        name: "dummy app 2",
                        children: [],
                      },
                    ],
                  };
                  clusterChart.children.push(clusterDummy);

                  this.setState({
                    clusterChart: clusterChart,
                  });

                  application.forEach((app) => {
                    let clusterChart = this.state.clusterChart;
                    let opdChart = this.state.opdChart;
                    let appData = {
                      name: app.name,
                      attributes: {
                        "Alamat": app.domain,
                        "Deskripsi": app.description,
                        "Cluster": [],
                        "OPD": ""
                      }
                    };
                    app.clusterId.forEach((id) => {
                      cluster.forEach((cluster) => {
                        if (cluster._id === id) {
                          clusterChart.children.forEach((clusters) => {
                            if (clusters.name === cluster.name) {
                              appData["attributes"]["Cluster"].push(cluster.name);
                              clusters.children.push(appData);
                            }
                          });
                        }
                      });
                    });
                    opd.forEach((opd) => {
                      if (opd._id === app.tagId) {
                        opdChart.children.forEach((opds) => {
                          if (opds.name === opd.name) {
                            appData["attributes"]["OPD"] = opd.name;
                            opds.children.push(appData);
                          }
                        });
                      }
                    });
                    this.setState({
                      isLoading: false,
                      clusterChart: clusterChart,
                      opdChart: opdChart,
                    });
                  });
                }
              );
            });
          });
        });
      }
    );
  }

  componentDidMount() {
    this.fetchNow();
  }


  render() {
    return (
      <React.Fragment>
        <Loading show={this.state.isLoading} />
        <div>
          <div className="row d-flex align-items-center m-0 mt-2 mb-4">
            <div className="col-lg col-12 text-center text-lg-left">
              <h1 className="mb-0">Cascading</h1>
            </div>
            <div className="col-lg-auto col-12 d-flex flex-md-row flex-column align-items-center justify-content-center mt-2 mt-lg-0">
              <div>
                <button
                  className="btn btn-primary btn-issp"
                  onClick={() => {
                    this.setState({ isCluster: !this.state.isCluster });
                  }}
                >
                  <span>Switch to {this.state.isCluster ? "OPD"  : "Cluster"}</span>
                </button>
              </div>
            </div>
          </div>
          <div id="treeWrapper" style={{ width: "75vw", height: "75vh", backgroundColor: "#e8e8e8", borderRadius: "8px" }}>
            {this.state.isLoading ? (
              <div>Loading</div>
            ) : this.state.isCluster ? (
              <Tree
                data={this.state.clusterChart}
                dimensions={{ height: 500, width: 1000 }}
                translate={{ x: 500, y: 100 }}
                pathFunc={"diagonal"}
                orientation={"vertical"}
                renderCustomNodeElement={renderClusterNode}
                separation={{ siblings: 2, nonSiblings: 2 }}
              />
            ) : (
              <Tree data={this.state.opdChart} dimensions={{ height: 500, width: 1000 }} renderCustomNodeElement={renderOPDNode} translate={{ x: 500, y: 100 }} pathFunc={"step"} orientation={"vertical"} separation={{ siblings: 2 }} />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Cascading;
