import fetchNow from './_Fetch.jsx';

const UserAPI = {
    get: () => {
        return fetchNow('/api/user', 'GET');
    },
    getById: (id) => {
        return fetchNow('/api/user/' + id, 'GET');
    },
    getByApps: (appId) => {
        return fetchNow('/api/user?app=' + appId, 'GET');
    },

    create: (body) => {
        return fetchNow('/auth/signup', 'POST', body, 'JSON', true);
    },

    update: (body, id) => {
        return fetchNow('/api/user/' + id, 'PUT', body, 'JSON');
    },

    delete: (id) => {
        return fetchNow('/api/user/' + id, 'DELETE');
    },

    /* User App */
    addApps: (body) => {
        return fetchNow('/api/userapp', 'POST', body, 'JSON');
    },
    updateRole: (body, id) => {
        return fetchNow('/api/userapp/role/' + id, 'PUT', body, 'JSON');
    },
    updateClass: (body, id) => {
        return fetchNow('/api/userapp/class/' + id, 'PUT', body, 'JSON');
    },
    removeApps: (id) => {
        return fetchNow('/api/userapp/' + id, 'DELETE');
    }
}
 
export default UserAPI;