import React, { Component } from "react";
import { toast } from "react-toastify";
import "bootstrap";
import "datatables.net";

import "./asset/style/index.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

import { RootContext } from "./component/Context";
import Loading from "./component/Loading";

import AuthAPI from "./api/Auth";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Page from "./page/Page";
import Login from "./component/Login";

const Provider = RootContext.Provider;
toast.configure();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isAuthenticated: false,
      isLoaded: false,

      appId: process.env.REACT_APP_APP_ID,
      baseUrl: window.location.origin,

      user: {
        _id: "",
        name: "",
        username: "",
        email: "",
        opdId: "",
        roles: {
          _id: "",
          name: "",
          appId: 0,
        },
        class: [],
      },
      role: "",
    };

    this.fetchNow = this.fetchNow.bind(this);
  }

  fetchNow() {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        /* Request User Info */
        AuthAPI.user({ appId: this.state.appId }).then((data) => {
         
          /* Store Initial Value */
          let user = this.state.user,
            role = this.state.role,
            isAuthenticated = this.state.isAuthenticated;

          if (data.status === 200) {
            /* Temporary Store */
            let temp = data.data[0];

            /* Store if Request Success */
            isAuthenticated = true;
            role = temp.roles[0].name;

            user = {
              _id: temp.userId._id,
              name: temp.userId.name,
              username: temp.userId.username,
              email: temp.userId.email,
              opdId: temp.appId.tagId,
              roles: temp.roles[0],
              class: temp.class,
            };
          } else if (data.status !== 500) {
            /* Show Error */
            toast.error(data.message ? data.message : "Terjadi kesalahan, silahkan coba beberapa saat lagi", {
              position: toast.POSITION.TOP_CENTER,
            });
          }

          /* Update Data */
          this.setState({
            isLoading: false,
            isLoaded: true,
            user,
            role,
            isAuthenticated,
          });
        });
      }
    );
  }

  componentDidMount() {
    /* Fetch Data if Token Exist */
    if (localStorage.getItem("token")) {
      this.fetchNow();
    } else {
      this.setState({
        isLoaded: true,
      });
    }
  }

  render() {
    /* Check if Fetch User Data Finish */
    if (this.state.isLoaded) {
      return (
        <Provider value={{ get: this.state }}>
          <Loading show={this.state.isLoading} />
          <BrowserRouter>
            <Switch>
              {/* Dashboard */}
              <Route exact path="/" render={(props) => (this.state.isAuthenticated ? <Page name="Dashboard" {...props} /> : <Redirect to="/login" />)} />

              {/* OPD */}
              <Route exact path="/opd" render={(props) => (this.state.isAuthenticated ? <Page name="OPD" {...props} /> : <Redirect to="/login" />)} />

              {/* Cluster */}
              <Route exact path="/klaster" render={(props) => (this.state.isAuthenticated ? <Page name="Cluster" {...props} /> : <Redirect to="/login" />)} />

              {/* Application */}
              <Route exact path="/aplikasi" render={(props) => (this.state.isAuthenticated ? <Page name="Application" {...props} /> : <Redirect to="/login" />)} />
              <Route path="/aplikasi/:id" render={(props) => (this.state.isAuthenticated ? <Page name="ApplicationDetail" {...props} /> : <Redirect to="/login" />)} />

              {/* Admin */}
              <Route exact path="/admin" render={(props) => (this.state.isAuthenticated ? <Page name="Admin" {...props} /> : <Redirect to="/login" />)} />

              {/* User */}
              <Route exact path="/pengguna" render={(props) => (this.state.isAuthenticated ? <Page name="User" {...props} /> : <Redirect to="/login" />)} />
              <Route path="/pengguna/:id" render={(props) => (this.state.isAuthenticated ? <Page name="UserDetail" {...props} /> : <Redirect to="/login" />)} />

              {/* Login */}
              <Route path="/login" render={(props) => (this.state.isAuthenticated ? <Redirect to="/" /> : <Login {...props} />)} />

              {/* Cluster */}
              <Route exact path="/cascading" render={(props) => (this.state.isAuthenticated ? <Page name="Cascading" {...props} /> : <Redirect to="/login" />)} />
              {/* <Redirect to="/login" /> */}

              {/* Default */}
              <Route path="/" render={(props) => (this.state.isAuthenticated ? <Page {...props} /> : <Redirect to="/login" />)} />
            </Switch>
          </BrowserRouter>
        </Provider>
      );
    } else {
      return null;
    }
  }
}

export default App;
