const fetchNow = async (sub, method, body, headers) => {
    let url = process.env.REACT_APP_API + sub;
    let response;
    
    if(body) {
        if(headers) {
            if(headers === 'formurl') {
                response = await fetch(url, {
                    method: method,
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                    body: body
                });
            }else{
                response = await fetch(url, {
                    method: method,
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify(body)
                });
            }
        }else {
            response = await fetch(url, {
                method: method,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-type': 'application/x-www-form-urlencoded'
                },
                body: body
            });
        }
    }else {
        response = await fetch(url, {
            method: method,
            headers: {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
        });
    }

    if(response.status === 500) {
        return {
            status: 500,
            message: 'Internal Server Error'
        }
    }else if(response.status === 403){
        localStorage.clear();
        window.location.reload();
    }else{
        return await response.json();
    }
}

export default fetchNow;
