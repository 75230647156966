import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ArrowLeft, ArrowRight, Copy, Edit, MoreHorizontal, Trash2 } from 'react-feather';
import { Typeahead } from 'react-bootstrap-typeahead';

import ApplicationAPI from '../../../api/Application';
import OpdAPI from '../../../api/Opd';
import ClusterAPI from '../../../api/Cluster';
import RoleAPI from '../../../api/Role';
import ClassAPI from '../../../api/Class';

import AccessController from '../../../component/AccessController';
import Loading from '../../../component/Loading';
import NotFound from '../../../component/NotFound';

import ApplicationUser from './ApplicationUser';

const DeepClone = require('rfdc')();
toast.configure();

class AplicationDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isCopy: false,
            isShow: '',

            cluster: [],
            opd: [],

            /* Base Data of Application */
            data: {
                _id: '',
                uid: 0,
                name: '',
                domain: '',
                userCount: '',
                description: '',
                opdId: {
                    name: ''
                },
                tagId: '',
                clusterId: [],
                isPublic: false,
                otpSetting: false,
                roles: [],
                class: [],
                thumbnail: ''
            },

            /* Application */
            application: {
                name: '',
                domain: '',
                isPublic: '',
                clusterId: [],
                description: '',
                tagId:'',
                otpSetting: '',
                file: []
            },

            /* Role and Class */
            modal: {
                id: '',
                name: '',
                detail: ''
            }
        }

        this.fetchNow = this.fetchNow.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handelClusterChange = this.handelClusterChange.bind(this);
        this.copyAppId = this.copyAppId.bind(this);

        this.modalDetailUpdate = this.modalDetailUpdate.bind(this);
        this.detailUpdate = this.detailUpdate.bind(this);

        this.modalCreate = this.modalCreate.bind(this);
        this.create = this.create.bind(this);

        this.modalUpdate = this.modalUpdate.bind(this);
        this.update = this.update.bind(this);

        this.delete = this.delete.bind(this);
    }

    /* === Core Process ========================================================= */

    fetchNow() {
        this.setState({
            isLoading: true
        }, () => {
            /* Request Application Detail */
            ApplicationAPI.getById(this.props.id).then( async (dataApps) => {
                /* Handle if Request Success */
                if(dataApps.status === 200) {
                    /* Store Data */
                    let data = dataApps.data;
                    data.thumbnail = dataApps.data.thumbnail ? dataApps.data.thumbnail : '';
                    
                    /* Request to Get OPD and Cluster Detail */
                    let temp = []
                    for (const el of dataApps.data.clusterId) {
                        let fetch = await ClusterAPI.getById(el)
                        let data = fetch.data
                        temp.push(data)
                    }
                    data.clusterId = temp

                    OpdAPI.get().then((dataOpds) => {
                        /* Handle Success */
                        if (dataOpds.status === 200) {
                            /* Store OPD Data */
                            this.state.opd = dataOpds.data;
                            this.state.opd.sort(this.compare);
                    }
                })
                    OpdAPI.getByUid(data.tagId).then((dataOpd) => {
                        /* Store if Request Success */
                        data.opdId = (dataOpd.status === 200) ? dataOpd.data : ({ name: '-' });
                        
                        /* Update */
                        this.setState({
                            isLoading: false,
                            isShow: 'show',
                            data
                        })
                        
                    })
                }else{
                    this.setState({
                        isLoading: false,
                        isShow: 'hide'
                    })
                }
            })
        })
    }

    handleChange(event) {
        /* Store Temporary */
        let target = event.target.getAttribute('data-choice');
        let data = this.state[target];

        /* Change Value */
        if(event.target.name === 'isPublic') {
            data.isPublic = (event.target.value === 'true') ? true : false;
        }else if (event.target.name === 'otpSetting') {
            data.otpSetting = (event.target.value === 'true') ? true : false;
        }else if (event.target.name === 'thumbnail') {
            data.file = event.target.files;
        }else{
            data[event.target.name] = event.target.value;
        }

        /* Update */
        this.setState({
            [target]: data
        })
    }

    handelClusterChange(event) {
        let clusterId = []
        for (let i = 0; i < event.length; i++) {
            const el = event[i];
            clusterId.push(el._id)
        }
        
        this.setState({
            application: {
                ...this.state.application,
                clusterId: clusterId
            }
        })
    }

    copyAppId() {
        this.setState({
            isCopy: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    isCopy: false
                })
            }, 1000);
        })
    }

    /* === Update Apps Detail =================================================== */

    modalDetailUpdate() {
        /* Get Data */
        let data = DeepClone(this.state.data);
        let clusterId = []
        for (const el of data.clusterId) {
            clusterId.push(el._id)
        }
        
        /* Update and Show Modal */
        this.setState({
            application: {
                name: data.name,
                domain: data.domain,
                description: data.description,
                opdId: data.tagId,
                isPublic: data.isPublic,
                clusterId: clusterId,
                otpSetting: data.otpSetting,
                file: []
            }
        }, () => { $('#modalDetail').modal('show') })
    }

    detailUpdate() {
        this.setState({
            isLoading: true
        }, () => {
            /* Store Result */
            let bodyData = {
                name: this.state.application.name,
                domain: this.state.application.domain,
                isPublic: this.state.application.isPublic, 
                description: this.state.application.description, 
                tagId: this.state.application.opdId, 
                clusterId: this.state.application.clusterId,
                otpSetting: this.state.application.otpSetting
            }

            /* Assign FormData */
            let body = new FormData();

            /* Add Data */
            body.append('data', JSON.stringify(bodyData));

            /* Add File if Exist */
            if(this.state.application.file.length > 0) {
                body.append('file', this.state.application.file[0]);
            }

            /* Request Update Data */
            ApplicationAPI.update(body, this.props.id).then((data) => {
                if(data.status === 200) {
                    this.fetchNow();
                    $('#modalDetail').modal('hide')
                    toast.success("Perubahan Berhasil Disimpan", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }else{
                    this.setState({
                        isLoading: false
                    }, () => {
                        toast.error("Perubahan Gagal, Silahkan Coba Beberapa Saat Lagi", {
                            position: toast.POSITION.TOP_CENTER
                        });

                        $('#modalDetail').modal('hide')
                    })
                }
            })
        })
    }

    /* === Create Role and Class ================================================ */

    modalCreate(event){
        let target = event.currentTarget.getAttribute('data-type');

        this.setState({
            modal: {
                id: '',
                name: '',
                detail: ''
            }
        }, () => {
            if(target === 'roles'){
                $('#modalRole').modal('show');
            }else{
                $('#modalClass').modal('show');
            }
        })
    }

    create(event) {
        let target = event.currentTarget.getAttribute('data-type');

        this.setState({
            isLoading: true
        }, () => {
            /* Store Result */
            const body = new URLSearchParams({
                name: this.state.modal.name,
                detail: this.state.modal.detail,
                appId: this.state.data._id
            });

            /* Check if Role or Class */
            if(target === 'roles') {
                RoleAPI.create(body).then((data) => {
                    if(data.status === 200) {
                        this.fetchNow();
                        $('#modalRole').modal('hide')
                        toast.success(`Role Berhasil Ditambahkan`, {
                            position: toast.POSITION.TOP_CENTER 
                        });
                    }else{
                        this.setState({
                            isLoading: false
                        }, () => {
                            toast.error("Tambah Role Gagal, Silahkan Coba Beberapa Saat Lagi", {
                                position: toast.POSITION.TOP_CENTER
                            });

                            $('#modalRole').modal('hide')
                        })
                    }
                })
            }else{
                ClassAPI.create(body).then((data) => {
                    if(data.status === 200) {
                        this.fetchNow();
                        $('#modalClass').modal('hide')
                        toast.success(`Fitur Berhasil Ditambahkan`, {
                            position: toast.POSITION.TOP_CENTER 
                        });
                    }else{
                        this.setState({
                            isLoading: false
                        }, () => {
                            toast.error("Tambah Fitur Gagal, Silahkan Coba Beberapa Saat Lagi", {
                                position: toast.POSITION.TOP_CENTER
                            });

                            $('#modalClass').modal('hide')
                        })
                    }
                })
            }
        })
    }

    /* === Update Role and Class ================================================ */

    modalUpdate(event) {
        let index = event.currentTarget.getAttribute('data-index');
        let target = event.currentTarget.getAttribute('data-type');

        let data = DeepClone(this.state.data[target][index]);

        this.setState({
            modal: {
                id: data._id,
                name: data.name,
                detail: data.detail
            }
        }, () => {
            if(target === 'roles'){
                $('#modalRole').modal('show');
            }else{
                $('#modalClass').modal('show');
            }
        })
    }

    update(event) {
        let target = event.currentTarget.getAttribute('data-type');

        this.setState({
            isLoading: true
        }, () => {
            /* Check if Role or Class */
            if(target === 'roles') {
                /* Store Result */
                const body = new URLSearchParams({
                    name: this.state.modal.name,
                    detail: this.state.modal.detail
                });

                /* Request Update */
                RoleAPI.update(body, this.state.modal.id).then((data) => {
                    if(data.status === 200) {
                        this.fetchNow();
                        $('#modalRole').modal('hide')
                        toast.success(`Update Daftar Role Berhasil`, {
                            position: toast.POSITION.TOP_CENTER 
                        });
                    }else{
                        this.setState({
                            isLoading: false
                        }, () => {
                            toast.error("Ubah Daftar Role Gagal, Silahkan Coba Beberapa Saat Lagi", {
                                position: toast.POSITION.TOP_CENTER
                            });

                            $('#modalRole').modal('hide')
                        })
                    }
                })
            }else{
                /* Store Result */
                const body = {
                    name: this.state.modal.name,
                    detail: this.state.modal.detail
                };

                /* Request Update */
                ClassAPI.update(body, this.state.modal.id).then((data) => {
                    if(data.status === 200) {
                        this.fetchNow();
                        $('#modalClass').modal('hide')
                        toast.success(`Update Daftar Fitur Berhasil`, {
                            position: toast.POSITION.TOP_CENTER 
                        });
                    }else{
                        this.setState({
                            isLoading: false
                        }, () => {
                            toast.error("Ubah Daftar Fitur Gagal, Silahkan Coba Beberapa Saat Lagi", {
                                position: toast.POSITION.TOP_CENTER
                            });

                            $('#modalClass').modal('hide')
                        })
                    }
                })
            }
        })
    }

    /* === Delete Role and Class ================================================ */

    delete(event) {
        let target = event.currentTarget.getAttribute('data-type');
        let id = event.currentTarget.getAttribute('data-id');

        event.stopPropagation();
        if(window.confirm('Are sure want to delete?')) {
            this.setState({
                isLoading: true
            }, () => {
                /* Check if Role or Class */
                if(target === 'roles') {
                    RoleAPI.delete(id).then((data) => {
                        if(data.status === 200) {
                            this.fetchNow();
                            $('#modalRole').modal('hide')
                            toast.warn(`Hapus Role Berhasil`, {
                                position: toast.POSITION.TOP_CENTER 
                            });
                        }else{
                            this.setState({
                                isLoading: false
                            }, () => {
                                toast.error("Hapus Role Gagal, Silahkan Coba Beberapa Saat Lagi", {
                                    position: toast.POSITION.TOP_CENTER
                                });

                                $('#modalRole').modal('hide')
                            })
                        }
                    })
                }else{
                    ClassAPI.delete(id).then((data) => {
                        if(data.status === 200) {
                            this.fetchNow();
                            $('#modalClass').modal('hide')
                            toast.warn(`Hapus Fitur Berhasil`, {
                                position: toast.POSITION.TOP_CENTER 
                            });
                        }else{
                            this.setState({
                                isLoading: false
                            }, () => {
                                toast.error("Hapus Fitur Gagal, Silahkan Coba Beberapa Saat Lagi", {
                                    position: toast.POSITION.TOP_CENTER
                                });

                                $('#modalClass').modal('hide')
                            })
                        }
                    })
                }
            })
        }
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        }, () => {
            ClusterAPI.get().then((result) => {
                this.setState({
                    cluster: (result.status === 200) ? result.data : []
                }, () => {
                    this.fetchNow();
                })
            })
        })
    }

    render() { 
        /* Handle Normal */
        if(this.state.isShow === 'show')
        {
            return (
                <AccessController name="Aplikasi" access="read" type="page">
                    <Loading show={this.state.isLoading} />
                    <Helmet title={this.state.data.name + ' - Manajemen SSO'} />

                    <div className="row d-flex align-items-center m-0 mt-2 mb-4">
                        <Link to="/aplikasi" className="btn btn-sm btn-icon btn-transparent mr-2">
                            <ArrowLeft />
                        </Link>
                        <h1 className="mb-0">{this.state.data.name}</h1>
                    </div>

                    <div className="row m-0">
                        <div className="col-lg-5 mb-4">
                            <div className="card card-scrollable h-100">
                                <div className="card-header text-dark">
                                    Detail Aplikasi
                                </div>
                                <div className="card-body" style={{height: 'auto'}}>
                                    <div className="row m-0 mb-4 mt-2">
                                        <div className="col-4 col-lg-3 pl-0">
                                            Nama
                                        </div>
                                        <div className="col-8 col-lg-9 font-weight-bold text-black">
                                            {this.state.data.name}
                                        </div>
                                    </div>
                                    <div className="row m-0 mb-4">
                                        <div className="col-4 col-lg-3 pl-0">
                                            Domain
                                        </div>
                                        <div className="col-8 col-lg-9 font-weight-bold">
                                            <a className="text-black" target="_blank" rel="noopener noreferrer" href={`${window.location.protocol}//${this.state.data.domain}`}>{this.state.data.domain}</a>
                                        </div>
                                    </div>
                                    <div className="row m-0 mb-4 mt-2">
                                        <div className="col-4 col-lg-3 pl-0">
                                            Deskripsi
                                        </div>
                                        <div className="col-8 col-lg-9 font-weight-bold text-black">
                                            {
                                                (this.state.data.description && this.state.data.description !== '-') ? (
                                                    this.state.data.description
                                                ) : '-'
                                            }
                                            {/* {this.state.data.description} */}
                                        </div>
                                    </div>
                                    <div className="row m-0 mb-4">
                                        <div className="col-4 col-lg-3 pl-0">
                                            OPD
                                        </div>
                                        <div className="col-8 col-lg-9 font-weight-bold text-black">
                                            {this.state.data.opdId.name}
                                        </div>
                                    </div>
                                    <div className="row m-0 mb-4">
                                        <div className="col-4 col-lg-3 pl-0">
                                            Klaster
                                        </div>
                                        <div className="col-8 col-lg-9 font-weight-bold text-black">
                                            {this.state.data.clusterId.map(el => `${el.name}, `)}
                                        </div>
                                    </div>
                                    <div className="row m-0 mb-4">
                                        <div className="col-4 col-lg-3 pl-0">
                                            Status
                                        </div>
                                        <div className="col-8 col-lg-9 font-weight-bold text-black">
                                            <div className={"badge-public" + (this.state.data.isPublic ? ' public' : '')}>
                                                {this.state.data.isPublic ? 'Publik' : 'Tidak Publik'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0 mb-4">
                                        <div className="col-4 col-lg-3 pl-0">
                                            OTP
                                        </div>
                                        <div className="col-8 col-lg-9 font-weight-bold text-black">
                                            <div className={"badge-public" + (this.state.data.otpSetting ? ' public' : '')}>
                                                {this.state.data.otpSetting ? 'Aktif' : 'Nonaktif'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0 mb-4">
                                        <div className="col-4 col-lg-3 pl-0">
                                            Gambar
                                        </div>
                                        <div className="col-8 col-lg-9 font-weight-bold text-black">
                                            {
                                                (this.state.data.thumbnail && this.state.data.thumbnail !== '-') ? (
                                                    <a target="_blank" rel="noopener noreferrer" className="text-dark" href={process.env.REACT_APP_API + '/images/' + this.state.data.thumbnail}>{this.state.data.thumbnail}</a>
                                                ) : '-'
                                            }
                                        </div>
                                    </div>
                                    <div className="row m-0 mb-2">
                                        <div className="col-4 col-lg-3 pl-0">
                                            App ID
                                        </div>
                                        <div className="col-8 col-lg-9 font-weight-bold text-black">
                                            <CopyToClipboard text={this.state.data._id} onCopy={this.copyAppId}> 
                                                <div className="app-id-text">
                                                    {this.state.data._id}
                                                    <Copy className="copy-icon ml-1" size={16}/>
                                                </div>
                                            </CopyToClipboard>
                                            {
                                                this.state.isCopy && <div className="badge badge-dark copy-status">App ID telah disalin</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <AccessController name="Aplikasi" access="update">
                                    <div onClick={this.modalDetailUpdate} className="card-footer font-weight-bold bg-white text-orange d-flex align-items-center justify-content-between footer-warning">
                                        Ubah Detail Aplikasi
                                        <ArrowRight size={20}/>
                                    </div>
                                </AccessController>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div className="card card-scrollable card-equal h-100">
                                <div className="card-header text-dark">
                                    Daftar Role
                                </div>
                                <div className="card-body">
                                    {
                                        this.state.data.roles.length <= 0 &&
                                        (
                                            <div className="d-flex flex-column align-items-center justify-content-center" style={{height: '100%'}}>
                                                <span className="text-disabled" style={{fontSize: '1.25rem', fontWeight: '600'}}>Data Kosong</span>
                                                <span style={{fontSize: '.9rem'}}>Role belum ditambahkan</span>
                                            </div>
                                        )
                                    }
                                    <ul className="list-group">
                                        {
                                            this.state.data.roles.map((value, index) => {
                                                return (
                                                    <li key={index} className="list-group-item list-group-item-action d-flex align-items-center justify-content-between">
                                                        {value.name}
                                                        <AccessController name="Aplikasi" access="update">
                                                            <div className="dropdown">
                                                                <button className="btn btn-table rounded-circle" data-toggle="dropdown">
                                                                    <MoreHorizontal />
                                                                </button>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <button onClick={this.modalUpdate} data-type="roles" data-index={index} className="dropdown-item w-100" type="button">
                                                                        <span className="dropdown-item-icon"><Edit /></span>Ubah Role
                                                                    </button>
                                                                    <button onClick={this.delete} data-type="roles" data-id={value._id} className="dropdown-item w-100" type="button">
                                                                        <span className="dropdown-item-icon"><Trash2 /></span>Hapus Role
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </AccessController>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <AccessController name="Aplikasi" access="update">
                                    <div onClick={this.modalCreate} data-type="roles" className="card-footer font-weight-bold bg-white text-success d-flex align-items-center justify-content-between footer-success">
                                        Tambah Role Baru
                                        <ArrowRight size={20}/>
                                    </div>
                                </AccessController>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card card-scrollable card-equal h-100">
                                <div className="card-header text-dark">
                                    Daftar Fitur
                                </div>
                                <div className="card-body">
                                    {
                                        this.state.data.class.length <= 0 &&
                                        (
                                            <div className="d-flex flex-column align-items-center justify-content-center" style={{height: '100%'}}>
                                                <span className="text-disabled" style={{fontSize: '1.25rem', fontWeight: '600'}}>Data Kosong</span>
                                                <span style={{fontSize: '.9rem'}}>Fitur belum ditambahkan</span>
                                            </div>
                                        )
                                    }
                                    <ul className="list-group">
                                        {
                                            this.state.data.class.map((value, index) => {
                                                return (
                                                    <li key={index} className="list-group-item list-group-item-action d-flex align-items-center justify-content-between">
                                                        {value.name}
                                                        <AccessController name="Aplikasi" access="update">
                                                            <div className="dropdown">
                                                                <button className="btn btn-table rounded-circle" data-toggle="dropdown">
                                                                    <MoreHorizontal />
                                                                </button>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <button onClick={this.modalUpdate} data-type="class" data-index={index} className="dropdown-item w-100" type="button">
                                                                        <span className="dropdown-item-icon"><Edit /></span>Ubah Fitur
                                                                    </button>
                                                                    <button onClick={this.delete} data-type="class" data-id={value._id} className="dropdown-item w-100" type="button">
                                                                        <span className="dropdown-item-icon"><Trash2 /></span>Hapus Fitur
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </AccessController>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <AccessController name="Aplikasi" access="update">
                                    <div onClick={this.modalCreate} data-type="class" className="card-footer font-weight-bold bg-white text-success d-flex align-items-center justify-content-between footer-success">
                                        Tambah Fitur Baru
                                        <ArrowRight size={20}/>
                                    </div>
                                </AccessController>
                            </div>
                        </div>
                    </div>

                    {
                        !this.state.data.isPublic && <ApplicationUser data={this.state.data} />
                    }

                    <div className="modal" id="modalDetail" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Ubah Detail Aplikasi</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="text-dark mb-1">Nama</label>
                                        <input value={this.state.application.name} onChange={this.handleChange} data-choice="application" name="name" className="form-control py-3" type="text" placeholder="Masukkan nama aplikasi" />
                                    </div>
                                    <div className="form-group">
                                        <label className="text-dark mb-1" >Domain</label>
                                        <div className="input-group">
                                            {/* <div className="input-group-prepend">
                                                <div className="input-group-text">http://</div>
                                            </div> */}
                                            <input value={this.state.application.domain} onChange={this.handleChange} data-choice="application" name="domain" className="form-control py-3" type="text" placeholder="Masukkan url aplikasi" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="text-dark mb-1">Deskripsi</label>
                                        <input value={this.state.application.description} onChange={this.handleChange} data-choice="application" name="description" className="form-control py-3" type="text" placeholder="Masukkan deskripsi aplikasi" />
                                    </div>
                                    <div className="form-group">
                                        <label className="text-dark mb-1">Organisasi</label>
                                        <select value={this.state.application.tagId} onChange={this.handleChange} data-choice="application" name="opdId" className="form-control">
                                            {/* <option value="" disabled>
                                            -- Pilih Organisasi --
                                            </option> */}
                                            {this.state.opd.map((value, index) => {
                                            return (
                                                <option value={value._id} key={index}>
                                                {value.name}
                                                </option>
                                            );
                                            })}
                                        </select>
                                        </div>
                                    <div className="form-group">
                                        <label className="text-dark mb-1">Klaster</label>
                                        <Typeahead id="cluster-typeahead" placeholder="Pilih cluster" options={this.state.cluster} defaultSelected={this.state.data.clusterId} onChange={this.handelClusterChange} labelKey="name" multiple allowNew />
                                    </div>
                                    <div className="form-group">
                                        <label className="text-dark mb-1">Tampilan Aplikasi (Screenshot)</label>
                                        <div className="custom-file">
                                            <input onChange={this.handleChange} data-choice="application" type="file" name="thumbnail" className="custom-file-input" id="thumbnail" accept="image/*" />
                                            <label className="custom-file-label" htmlFor="thumbnail" data-browse="Pilih File">{this.state.application.file.length > 0 ? this.state.application.file[0].name : 'Gambar Belum Dipilih'}</label>
                                        </div>
                                    </div>
                                    <div className="form-group mb-1">
                                        <label className="text-dark mb-1">Status Aplikasi</label>
                                    </div>
                                    <div className="form-group mb-1">
                                        <div className="form-check form-check-inline">
                                            <input checked={this.state.application.isPublic} onChange={this.handleChange} data-choice="application" className="form-check-input" type="radio" name="isPublic" id="isPublic1" value="true" />
                                            <label className="form-check-label" htmlFor="isPublic1">Publik (Umum)</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input checked={this.state.application.isPublic === false} onChange={this.handleChange} data-choice="application" className="form-check-input" type="radio" name="isPublic" id="isPublic2" value="false" />
                                            <label className="form-check-label" htmlFor="isPublic2">Tidak Publik</label>
                                        </div>
                                    </div>
                                    <div className="form-group mb-1">
                                        <label className="text-dark mb-1">Status OTP</label>
                                    </div>
                                    <div className="form-group mb-0">
                                        <div className="form-check form-check-inline">
                                            <input checked={this.state.application.otpSetting} onChange={this.handleChange} data-choice="application" className="form-check-input" type="radio" name="otpSetting" id="otpSetting1" value="true" />
                                            <label className="form-check-label" htmlFor="otpSetting1">Aktif</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input checked={this.state.application.otpSetting === false} onChange={this.handleChange} data-choice="application" className="form-check-input" type="radio" name="otpSetting" id="otpSetting2" value="false" />
                                            <label className="form-check-label" htmlFor="otpSetting2">Non Aktif</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-orange" data-dismiss="modal">Batal</button>
                                    <button type="button" className="btn btn-orange" onClick={this.detailUpdate}>Simpan</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal" id="modalRole" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Role</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="text-dark mb-1">Nama</label>
                                        <input value={this.state.modal.name} onChange={this.handleChange} data-choice="modal" name="name" className="form-control py-3" type="text" placeholder="Masukkan nama role" />
                                    </div>
                                    <div className="form-group">
                                        <label className="text-dark mb-1">Deskripsi</label>
                                        <input value={this.state.modal.detail} onChange={this.handleChange} data-choice="modal" name="detail" className="form-control py-3" type="text" placeholder="Masukkan deskripsi role" />
                                    </div>
                                </div>
                                {
                                    this.state.modal.id === '' ? (
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-outline-green" data-dismiss="modal">Batal</button>
                                            <button type="button" className="btn btn-green" data-type="roles" onClick={this.create}>Tambah</button>
                                        </div>
                                    )
                                    :   <div className="modal-footer">
                                            <button type="button" className="btn btn-outline-orange" data-dismiss="modal">Batal</button>
                                            <button type="button" className="btn btn-orange" data-type="roles" onClick={this.update}>Simpan</button>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="modal" id="modalClass" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Fitur</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="text-dark mb-1">Nama</label>
                                        <input value={this.state.modal.name} onChange={this.handleChange} data-choice="modal" name="name" className="form-control py-3" type="text" placeholder="Masukkan nama fitur" />
                                    </div>
                                    <div className="form-group">
                                        <label className="text-dark mb-1">Deskripsi</label>
                                        <input value={this.state.modal.detail} onChange={this.handleChange} data-choice="modal" name="detail" className="form-control py-3" type="text" placeholder="Masukkan deskripsi fitur" />
                                    </div>
                                </div>
                                {
                                    this.state.modal.id === '' ? (
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-outline-green" data-dismiss="modal">Batal</button>
                                            <button type="button" className="btn btn-green" data-type="class" onClick={this.create}>Tambah</button>
                                        </div>
                                    )
                                    :   <div className="modal-footer">
                                            <button type="button" className="btn btn-outline-orange" data-dismiss="modal">Batal</button>
                                            <button type="button" className="btn btn-orange" data-type="class" onClick={this.update}>Simpan</button>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </AccessController>
            );
        }

        /* Handle First */
        else if(this.state.isShow === '')
        {
            return <Loading show={this.state.isLoading} />
        }

        /* Handle if Id doesn't exist */
        else
        {
            return <NotFound />
        }
    }
}
 
export default AplicationDetail;