import fetchNow from './_Fetch.jsx';

const RoleAPI = {
    create: (body) => {
        return fetchNow('/api/role', 'POST', body);
    },
    update: (body, id) => {
        return fetchNow('/api/role/' + id, 'PUT', body);
    },
    delete: (id) => {
        return fetchNow('/api/role/' + id, 'DELETE');
    }
}
 
export default RoleAPI;