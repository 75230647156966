import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import CountUp from 'react-countup';
import { Briefcase, Monitor, Users } from 'react-feather';
import OpdAPI from '../../../api/Opd';
import StatisticAPI from '../../../api/Statistic';
import { RootContext } from '../../../component/Context';
import Loading from '../../../component/Loading';

class OPDDashboard extends Component {
    static contextType = RootContext;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,

            data: {
                _id: "",
                uid: 0,
                appCount: 0,
                userCount: 0,
                name: "",
                detail: "",
                apps: []
            },
            pie: {
                labels: [],
                datasets: []
            },
            statistic: {
                user: [],
                app: []
            },
        }

        this.fetchNow = this.fetchNow.bind(this);
    }

    fetchNow() {
        this.setState({
            isLoading: true
        }, () => {
            OpdAPI.getById(this.context.get.user.opdId).then((data) => {
                let pie = {
                    labels: [],
                    datasets: [
                        {
                            label: 'Sebaran Pengguna',
                            data: [],
                            backgroundColor: [
                                '#8bc34a', '#ffeb3b', '#ffc107', '#ff5722', '#e91e63', '#259b24', '#cddc39', '#ff9800', '#e51c23',
                                '#9c27b0', '#ffeb3b', '#00bcd4', '#9e9e9e', '#607d8b', '#673ab7', '#5677fc', '#009688', '#795548'
                            ],
                            borderWidth: 0
                        }
                    ]
                };

                let result = [];

                for(let value of data.data.apps) {
                    result.push({
                        label: value.name,
                        data: value.userCount
                    })
                }

                result.sort((a,b) => (a.data < b.data) ? 1 : ((b.data < a.data) ? -1 : 0));

                for(let i=0 ; i<result.length ; i++) {
                    if(i<18) {
                        pie.labels.push(result[i].label);
                        pie.datasets[0].data.push(result[i].data);
                    }else{
                        pie.datasets[0].data[17] += result[i].data;
                    }
                }

                if(pie.datasets[0].data.length === 18){
                    pie.labels[pie.datasets[0].data.length-1] = 'Lainnya';
                }

                /* Get Statistic per OPD */
                StatisticAPI.getByOPD(this.context.get.user.opdId).then((dataOPD) => {
                    let value = {
                        user: dataOPD.data.MostAndLessUser,
                        app: dataOPD.data.MostAndLessApp
                    }
    
                    value.user.sort((a,b) => (a.used < b.used) ? 1 : ((b.used < a.used) ? -1 : 0));
                    value.app.sort((a,b) => (a.used < b.used) ? 1 : ((b.used < a.used) ? -1 : 0));
                    
                    let result = {
                        user: [],
                        app: []
                    }
    
                    for(let i=0 ; i<((value.user.length > 5) ? 5 : value.user.length) ; i++){
                        result.user.push(value.user[i]);
                    }
    
                    for(let i=0 ; i<((value.app.length > 3) ? 3 : value.app.length) ; i++){
                        result.app.push(value.app[i]);
                    }


                    this.setState({
                        isLoading: false,
                        data: data.data || this.state.data,
                        pie: pie,
                        statistic: result
                    })
                })

            })
        })
    }

    componentDidMount() {
        this.fetchNow();
    }

    render() { 
        return (
            <React.Fragment>
                <Loading show={this.state.isLoading} />

                <div className="row d-flex align-items-center m-0 mt-2 mb-4">
                    <div className="col-lg col-12 text-center text-lg-left">
                        <h1 className="mb-0">Dashboard</h1>
                    </div>
                </div>
                <div className="row m-0 mb-5">
                    <div className="col-md-6 mb-4">
                        <div className="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-primary h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <div className="small font-weight-bold text-primary mb-1">Nama Organisasi</div>
                                        <div className="h1">{this.state.data.name}</div>
                                    </div>
                                    <div className="ml-2 text-gray-300"><Briefcase size={36}/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <div className="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-primary h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <div className="small font-weight-bold text-primary mb-1">Total Aplikasi</div>
                                        <div className="h1"><CountUp end={this.state.data.appCount} duration={2}/></div>
                                    </div>
                                    <div className="ml-2 text-gray-300"><Monitor size={36}/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <div className="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-primary h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <div className="small font-weight-bold text-primary mb-1">Total Pengguna</div>
                                        <div className="h1"><CountUp end={this.state.data.userCount} duration={2}/></div>
                                    </div>
                                    <div className="ml-2 text-gray-300"><Users size={36}/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4 mb-md-0">
                        <div className="card h-100 card-header-actions">
                            <div className="card-header">
                                Sebaran Pengguna Aplikasi
                            </div>
                            <div className="card-body py-4 px-5 d-flex align-items-center justify-content-center">
                                <Pie data={this.state.pie} options={{plugins: {legend: {display: false}}}} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4 mb-md-0">
                        <div className="card h-100">
                            <div className="card-header">
                                Penggunaan Aplikasi Tertinggi
                            </div>
                            <div className="card-body pb-1">
                                <div className="row">
                                    {
                                        this.state.statistic.app.map((value, index) => {
                                            return (
                                                <div key={index} className="col-12 mb-4 d-flex align-items-center">
                                                    <div className="box-icon mr-3 d-flex justify-content-center align-items-center">
                                                        {index + 1}
                                                    </div>
                                                    <div className="box-value d-flex justify-content-between align-items-center">
                                                        <p className="title">{value._id.name}</p>
                                                        <p className="value ml-3">{value.used} Kali</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-0">
                        <div className="card h-100">
                            <div className="card-header">
                                Aktivitas Login Tertinggi
                            </div>
                            <div className="card-body pb-1">
                                <div className="row">
                                    {
                                        this.state.statistic.user.map((value, index) => {
                                            return (
                                                <div key={index} className="col-12 mb-4 d-flex align-items-center">
                                                    <div className="box-icon mr-3 d-flex justify-content-center align-items-center">
                                                        {index + 1}
                                                    </div>
                                                    <div className="box-value d-flex justify-content-between align-items-center">
                                                        <p className="title">{value._id}</p>
                                                        <p className="value ml-3">{value.used} Kali</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default OPDDashboard;
