import fetchNow from './_Fetch.jsx';

const ClusterAPI = {
    get: () => {
        return fetchNow('/api/cluster', 'GET');
    },
    getById: (id) => {
       return fetchNow('/api/cluster/all/' + id, 'GET');
    },

    create: (body) => {
        return fetchNow('/api/cluster', 'POST', body);
    },

    update: (body, id) => {
        return fetchNow('/api/cluster/' + id, 'PUT', body);
    },

    delete: (id) => {
        return fetchNow('/api/cluster/' + id, 'DELETE');
    },
}
 
export default ClusterAPI;